import React from "react";
import AcxChip from "./AcxChip";
import useStyles from "Styles/Styles";
import { Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { maxWidth } from "@mui/system";

interface OwnProps {
    values?: string[];
    size: "small" | "medium";
    color: "green" | "red" | "blue" | "gray";
    height?: string;
    toolTip?: React.ReactNode;
    maxWidth?: string;
    multiToolTip?: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
        chipListWrapper: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            maxWidth: maxWidth,
        },
        chipContainer: {
            flexShrink: 1,
            overflow: "hidden",
            marginRight: "5px",
        },
    });

const AcxChipList = (props: OwnProps) => {
    const getStyle = () => {
        if (props.height) {
            return { width: "100%", height: props.height, overflow: "hidden" };
        }
        if (props.maxWidth) {
            return {
                width: "100%",
                maxWidth: props.maxWidth,
                overflow: "hidden",
            };
        }
        return { width: "100%", overflow: "hidden" };
    };

    const classes = useStyles(styles);

    return (
        <Tooltip title={props.toolTip ?? ""}>
            <div className={classes.chipListWrapper} style={getStyle()}>
                {props.values?.map((value, index) => {
                    return (
                        <div
                            key={`${value}_${index}`}
                            className={classes.chipContainer}
                        >
                            <AcxChip
                                key={value}
                                size={props.size}
                                color={props.color}
                                label={value}
                                multiToolTip={props.multiToolTip}
                            />
                        </div>
                    );
                })}
            </div>
        </Tooltip>
    );
};

export default AcxChipList;
