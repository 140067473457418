import * as audioBufferToWav from "audiobuffer-to-wav";
import { fetch_retry } from "utils/FetchRetry";

export type IdAudioBuffer = {
    id: string;
    index: number;
    audioBuffer: AudioBuffer;
};

class AudioContextProvider {
    audioContext: AudioContext;

    constructor() {
        this.audioContext = new AudioContext();
    }

    async fetchAudio(urls: string[]): Promise<AudioBuffer[]> {
        const decoded = urls.map(async (path) => {
            const buffer = await fetch_retry(path, {}, 2).then((response) => {
                return response.arrayBuffer();
            });
            return await this.audioContext.decodeAudioData(buffer);
        });
        return await Promise.all(decoded);
    }

    stereoToMono(
        buffer: AudioBuffer,
        start?: number,
        end?: number,
    ): Float32Array {
        if (buffer.numberOfChannels > 1) {
            const element1 = buffer.getChannelData(0);
            const element2 = buffer.getChannelData(1);

            const data1: Float32Array = element1.slice(start, end);
            const data2: Float32Array = element2.slice(start, end);

            const data: Float32Array = new Float32Array(data1.length);
            for (let i = 0; i < data1.length; i++) {
                data[i] = (1 / 2) * (data1[i] + data2[i]);
            }

            return data;
        }

        const element = buffer.getChannelData(0);
        return element.slice(start, end);
    }

    sliceBuffer(buffer: AudioBuffer, start: number, end: number): AudioBuffer {
        start = start == null ? 0 : this.nidx(start, buffer.length);
        end = end == null ? buffer.length : this.nidx(end, buffer.length);

        // const element = buffer.getChannelData(0);
        //
        // const data: Float32Array = element.slice(start, end);
        const data = this.stereoToMono(buffer, start, end);

        const buf = this.audioContext.createBuffer(
            1,
            data.length,
            buffer.sampleRate,
        );

        buf.copyToChannel(data, 0);
        return buf;
    }

    concatBuffers(buffers: AudioBuffer[]): AudioBuffer {
        const totalLength = this.totalLength(buffers);
        const buf = this.audioContext.createBuffer(
            1,
            totalLength,
            buffers[0].sampleRate,
        );
        let offset = 0;
        for (let i = 0; i < buffers.length; i++) {
            const element = buffers[i];
            const data = this.stereoToMono(element);
            buf.getChannelData(0).set(data, offset);
            offset += element.length;
        }

        return buf;
    }

    export(buffer: AudioBuffer) {
        const dataview = audioBufferToWav(buffer);

        return {
            audioBuffer: buffer,
            blob: new Blob([dataview], { type: "audio/wav" }),
        };
    }

    exportMP3(
        buffer: AudioBuffer,
        onMp3Ready: (error: Error | null, blob?: Blob) => Promise<void>,
    ) {
        const dataview = audioBufferToWav(buffer);

        const webworker = new Worker("./WebWorkers/Mp3ConversionWorker.js");

        webworker.postMessage(
            { sampleRate: buffer.sampleRate, buffer: dataview },
            [dataview],
        );

        webworker.onmessage = (ev) => {
            const datablob: Blob = ev.data.mp3Data;
            onMp3Ready(null, datablob);
        };
        webworker.onerror = (ev) => {
            onMp3Ready(new Error(`Mp3 conversion failed: ${ev.message}`));
        };
        webworker.onmessageerror = (ev) => {
            onMp3Ready(new Error(`Mp3 conversion failed`));
        };
    }

    download(blob: Blob, filename: string, includeBlobType?: boolean) {
        const name = filename || "AcxAudioClip";

        let bType = blob.type.split("/")[1];

        if (includeBlobType === false) {
            bType = "";
        }

        const a = document.createElement("a");
        a.setAttribute("style", "display:none");
        a.id = "AcxAudioClipElement";
        a.style.display = "none";
        a.href = URL.createObjectURL(blob);

        if (bType) {
            a.download = `${name}.${bType}`;
        } else {
            a.download = `${name}`;
        }

        a.click();
        return a;
    }

    close() {
        if (this.audioContext.state !== "closed") {
            this.audioContext?.close();
        }
    }

    private maxDuration(buffers) {
        return Math.max.apply(
            Math,
            buffers.map((buffer) => buffer.duration),
        );
    }

    private isNeg(num: number) {
        return num === 0 && 1 / num === -Infinity;
    }

    private nidx(idx: number, length: number) {
        return idx == null
            ? 0
            : this.isNeg(idx)
            ? length
            : idx <= -length
            ? 0
            : idx < 0
            ? length + (idx % length)
            : Math.min(length, idx);
    }

    totalLength(buffers: AudioBuffer[]) {
        // debugger;
        if (buffers.length > 0) {
            const bufferLength = buffers
                .map((buffer) => buffer.length)
                .reduce((a, b) => a + b, 0);
            return bufferLength;
        }
        return 0;
    }
}
export default AudioContextProvider;
