import { Edit } from "@mui/icons-material";
import { Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { interactionDateHelper } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import _ from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    ObservableMap,
    reaction,
    runInAction,
} from "mobx";
import {
    AgentCoaching,
    AgentCoachingStatus,
} from "models/AgentModels/AgentCoaching";
import type { Moment } from "moment";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import type {
    AgentCoachingEvaluationScore,
    AgentEvaluations,
} from "services/AgentCoachingService";
import {
    AgentCoachingData,
    AgentCoachingService,
} from "services/AgentCoachingService";
import { AuthStore } from "stores/AuthStore";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import { isNotNull } from "utils/helpers";
import { BaseStore } from "../../../../../stores/BaseStore";
import { TableComponentStore } from "../../../../../stores/ComponentStores/TableComponentStore";
import RootStore from "../../../../../stores/RootStore";
import { serializeToUtc } from "../../../../../utils/DateTimeUtils";
import { isNullableType } from "../../../../../utils/TypeGuards";
import AcxChipList from "../../../../UI/AcxChipList";
import { TabTableData } from "../../../../UI/AcxTable/TabTable/AcxTabTable";
import { EvalReviewStore } from "../../../EvalReview/Stores/EvalReviewStore";

const matchScoreToEval = (
    currentEval: AgentCoachingData,
    scoresResponse: any[],
) => {
    const matchingModuleScores = scoresResponse.filter(
        (val2) => currentEval.id === val2.evaluationId,
    );
    if (matchingModuleScores.length) {
        if (currentEval.scores) {
            return {
                ...currentEval,
                scores: [...currentEval.scores, ...matchingModuleScores],
            } as AgentCoachingData;
        } else {
            return {
                ...currentEval,
                scores: [...matchingModuleScores],
            } as AgentCoachingData;
        }
    }
    return currentEval as AgentCoachingData;
};

function evalSearchFilter(coachingData: AgentCoachingData, search?: string) {
    if (!search || !coachingData.evaluationQbId) return true;

    if (isNaN(Number(search))) return false;

    return coachingData.evaluationQbId.toString().startsWith(search);
}

export class EvalCoachingTableViewModel extends BaseStore {
    private readonly agentCoachingService: AgentCoachingService =
        new AgentCoachingService();

    readonly allEvaluationsTab = "All Evaluations";
    readonly coachedEvaluationsTab = "Completed Reviews";
    readonly notCoachedEvaluationsTab = "Ready to Review";
    readonly myDraftsTab = "My Drafts";

    private fieldFormatters: Record<string, (arg: any, obj: any) => any>;

    @observable tabTableData?: TabTableData<AgentCoachingData>[];

    @observable.ref
    private agentEvaluations?: AgentEvaluations;
    // If the current user has coaching permissions, this variable holds their drafts
    // for the current agent.
    @observable private agentDrafts?: AgentCoaching[];
    @observable orgId: string;
    @observable private agentId: string;
    @observable private startDate?: Moment;
    @observable private endDate?: Moment;
    @observable private dateReference?: DateReferenceOption;
    @observable private hierarchyIds: string[] | undefined;
    @observable showMachineEvals?: boolean = false;

    @observable reviewAllAction?: string;

    @observable.ref evalTableStores: Map<
        string,
        TableComponentStore<AgentCoachingData>
    > = new ObservableMap<string, TableComponentStore<AgentCoachingData>>([
        [
            this.allEvaluationsTab,
            new TableComponentStore<AgentCoachingData>(evalSearchFilter),
        ],
        [
            this.coachedEvaluationsTab,
            new TableComponentStore<AgentCoachingData>(evalSearchFilter),
        ],
    ]);

    private authStore: AuthStore;

    constructor(
        orgId: string,
        agentId: string,
        startDate: Moment,
        endDate: Moment,
        dateReference: DateReferenceOption,
        hierarchyIds: string[] | undefined,
    ) {
        super("Evaluation Coaching Table");

        makeObservable(this);

        this.agentId = agentId;
        this.orgId = orgId;
        this.authStore = RootStore().getStore(AuthStore);

        this.initialize(
            orgId,
            agentId,
            startDate,
            endDate,
            dateReference,
            hierarchyIds,
        );

        reaction(
            () => this.authStore.permStore.appPermissions,
            (arg) => {
                if (this.authStore.canUserEdit("Agent Coaching")) {
                    const evalStores = new ObservableMap<
                        string,
                        TableComponentStore<AgentCoachingData>
                    >();
                    this.evalTableStores.forEach((val, key) => {
                        evalStores.set(key, val);
                    });

                    if (
                        !this.authStore.isLoggedInUserAgent() ||
                        this.authStore.isUserUltra()
                    ) {
                        evalStores.set(
                            this.notCoachedEvaluationsTab,
                            new TableComponentStore<AgentCoachingData>(
                                evalSearchFilter,
                            ),
                        );
                        evalStores.set(
                            this.myDraftsTab,
                            new TableComponentStore<AgentCoachingData>(),
                        );
                    }
                    this.evalTableStores = evalStores;
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => ({
                orgId: this.orgId,
                agentId: this.agentId,
                start: this.startDate,
                end: this.endDate,
                dateReference: this.dateReference,
                hierarchyIds: this.hierarchyIds,
                showMachineEvals: this.showMachineEvals,
            }),
            (arg) => {
                this.loadEvaluationsAndDrafts();
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            (r) =>
                this.evalTableStores.get(this.notCoachedEvaluationsTab)
                    ?.selectedItemIds,
            (arg) => {
                if (arg && arg.length > 0) {
                    this.reviewAllAction = "Review Selected";
                } else {
                    this.reviewAllAction = undefined;
                }
            },
        );

        reaction(
            (r) => ({
                arg1: this.agentEvaluations,
                arg2: this.evalTableStores,
            }),
            (arg) => {
                if (!this.agentEvaluations) {
                    return;
                }

                this.evalTableStores
                    .get(this.allEvaluationsTab)
                    ?.reset()
                    ?.setItems(arg?.arg1?.all ?? []);

                this.evalTableStores
                    .get(this.coachedEvaluationsTab)
                    ?.reset()
                    ?.setItems(arg?.arg1?.coached ?? []);

                const notCoachedTable = this.evalTableStores.get(
                    this.notCoachedEvaluationsTab,
                );
                const notCoachedEvaluations = arg.arg1?.notCoached;
                notCoachedTable?.reset()?.setItems(notCoachedEvaluations ?? []);

                if (!notCoachedEvaluations) return;
                notCoachedTable?.setDisabledItems(
                    notCoachedEvaluations.filter(
                        (evaluation) =>
                            evaluation.coachingStatus ===
                            AgentCoachingStatus.InProgress,
                    ),
                );
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            () => this.agentDrafts,
            (drafts) => {
                if (!drafts) return;

                this.evalTableStores
                    .get(this.myDraftsTab)
                    ?.reset()
                    .setItems(drafts);
            },
            { fireImmediately: true },
        );
    }

    private async loadEvaluationsAndDrafts() {
        this.setupAsyncTask("Load Evaluations", async () => {
            if (
                !this.agentId ||
                !this.startDate ||
                !this.endDate ||
                !this.orgId
            ) {
                return;
            }
            await this.loadAgentEvaluations(
                this.orgId,
                this.agentId,
                this.startDate,
                this.endDate,
                this.dateReference,
                this.hierarchyIds,
                this.showMachineEvals,
            );
            await this.loadMyDrafts(this.agentId);
        });
    }

    @computed get hasSelectedDrafts() {
        const table = this.evalTableStores.get(this.myDraftsTab);
        if (!table) return false;
        return table.totalSelectedRows > 0;
    }

    tagFormatter = (tags: string) => {
        const parsed = JSON.parse(tags);
        const res = (
            <AcxChipList
                size={"small"}
                color={"blue"}
                values={parsed}
                maxWidth={"210px"}
                multiToolTip
            />
        );
        return res;
    };

    @action
    updateOrgAndAgent(orgId: string, agentId: string) {
        this.orgId = orgId;
        this.agentId = agentId;
    }

    @action
    reviewDraft(agentCoaching: AgentCoaching) {
        let evaluationIds: string[] = [];
        if (agentCoaching.targetedEvaluationIds)
            evaluationIds = JSON.parse(
                agentCoaching.targetedEvaluationIds,
            ) as string[];

        const evaluations = this.agentEvaluations?.all.filter((evaluation) =>
            evaluationIds.includes(evaluation.id),
        );

        if (!evaluations || evaluations.length === 0) {
            console.error("Unable to start review process");
            return;
        }

        const evalStore = RootStore().getStore(EvalReviewStore);
        evalStore.initializeStore(
            agentCoaching.agentId,
            evaluations,
            false,
            agentCoaching,
        );
    }

    @action
    reviewManyEvaluations() {
        const toBeReviewed = this.evalTableStores.get(
            this.notCoachedEvaluationsTab,
        )?.selectedItems;
        if (!toBeReviewed || !toBeReviewed?.[0]?.agentId) {
            console.error("Unable to start review process");
            return;
        }

        RootStore()
            .getStore(EvalReviewStore)
            .initializeStore(toBeReviewed?.[0]?.agentId, toBeReviewed, false);
    }

    @action
    reviewSingleEvaluation(evalId: string, isCoached?: boolean) {
        let relevantRecs: AgentCoachingData[] | undefined;
        let toBeReviewed: AgentCoachingData | undefined;
        if (isCoached) {
            relevantRecs = this.evalTableStores.get(
                this.coachedEvaluationsTab,
            )?.items;
        } else {
            relevantRecs = this.evalTableStores.get(
                this.notCoachedEvaluationsTab,
            )?.items;
        }
        toBeReviewed = relevantRecs?.find((value) => value.id === evalId);
        if (!toBeReviewed || !toBeReviewed?.agentId) {
            console.error("Unable to start review process");
            return;
        }

        RootStore()
            .getStore(EvalReviewStore)
            .initializeStore(
                toBeReviewed?.agentId,
                [toBeReviewed],
                isCoached || false,
            );
    }

    @action
    buildTableTabs(formatters: Record<string, (arg: any, obj: any) => any>) {
        this.fieldFormatters = formatters;
        const tabs: TabTableData<AgentCoachingData>[] = [
            {
                label: this.allEvaluationsTab,
                tableComponentStore: this.evalTableStores.get(
                    this.allEvaluationsTab,
                )!,
                enableCheck: false,
                columns: [
                    {
                        headerLabel: "Evaluation Number",
                        dataKey: "evaluationQbId",
                        formatter: formatters["evalNumberFormatter"],
                    },
                    {
                        headerLabel: "Interaction Date",
                        dataKey: "interactionDate",
                        formatter: formatters["interactionDateTime"],
                        valueGetter(dataValue?: string) {
                            if (isNullableType(dataValue)) return undefined;

                            return moment(
                                interactionDateHelper(dataValue),
                            ).format("YYYY-MM-DD h:mm A");
                        },
                    },
                    {
                        headerLabel: "Completion Date",
                        dataKey: "submittedDate",
                        formatter: formatters["dateTime"],
                    },
                    {
                        headerLabel: "Coached",
                        dataKey: "agentCoachingId",
                        formatter: formatters["coachedBoolean"],
                    },
                    {
                        headerLabel: "Coaching Acknowledged",
                        dataKey: "acknowledged",
                        formatter: formatters["coachedAckBoolean"],
                    },
                    this.authStore.canUserView("Agent Acknowledge")
                        ? {
                              headerLabel: "Evaluation Acknowledged",
                              dataKey: "evalAcknowledged",
                              formatter: formatters["agentAckBoolean"],
                          }
                        : null,
                    {
                        headerLabel: "Scores",
                        dataKey: "scores",
                        formatter: formatters["scoresFormatter"],
                        valueGetter(
                            dataValue?: AgentCoachingEvaluationScore[],
                        ) {
                            if (
                                isNullableType(dataValue) ||
                                dataValue.length === 0
                            )
                                return undefined;

                            return (
                                dataValue.reduce(
                                    (avg, current) => avg + current.avgScore,
                                    0,
                                ) / dataValue.length
                            );
                        },
                    },
                ].filter(isNotNull),
            },
        ];
        const coachedCols = [
            {
                headerLabel: "Evaluation Number",
                dataKey: "evaluationQbId",
                formatter: formatters["evalNumberFormatter"],
            },
            {
                headerLabel: "Completion Date",
                dataKey: "submittedDate",
                formatter: formatters["dateTime"],
            },
            { headerLabel: "Coach", dataKey: "coach" },
            {
                headerLabel: "Coached On",
                dataKey: "coachedOn",
                formatter: formatters["dateTime"],
            },
            {
                headerLabel: "Improvement Notes",
                dataKey: "improvementNotes",
                formatter: formatters["notes"],
            },
            {
                headerLabel: "Does Well",
                dataKey: "agentDoWellNotes",
                formatter: formatters["notes"],
            },
            {
                headerLabel: "Focus Areas",
                dataKey: "focusAreas",
                formatter: this.tagFormatter,
            },
            {
                headerLabel: "Response Notes",
                dataKey: "responseNotes",
                formatter: formatters["notes"],
            },
        ];
        if (this.authStore.isLoggedInUserAgent()) {
            coachedCols.push({
                headerLabel: "",
                dataKey: "evaluationQbId",
                formatter: formatters["acknowledgeNavButton"],
            });
        }
        if (
            this.authStore.canUserView("Agent Coaching") ||
            this.authStore.isLoggedInUserAgent()
        ) {
            tabs.push({
                label: this.coachedEvaluationsTab,
                tableComponentStore: this.evalTableStores.get(
                    this.coachedEvaluationsTab,
                )!,
                enableCheck: false,
                columns: coachedCols,
            });

            if (
                this.evalTableStores.has(this.notCoachedEvaluationsTab) &&
                this.evalTableStores.has(this.myDraftsTab) &&
                this.authStore.canUserEdit("Agent Coaching")
            ) {
                tabs.push({
                    label: this.notCoachedEvaluationsTab,
                    tableComponentStore: this.evalTableStores.get(
                        this.notCoachedEvaluationsTab,
                    )!,
                    enableCheck: true,
                    columns: [
                        {
                            headerLabel: "Evaluation Number",
                            dataKey: "evaluationQbId",
                            formatter: (arg: any, row: AgentCoachingData) => {
                                if (
                                    row.coachingStatus ===
                                    AgentCoachingStatus.InProgress
                                )
                                    return (
                                        <span>
                                            {formatters["evalNumberDisplay"](
                                                arg,
                                                row,
                                            )}
                                        </span>
                                    );

                                return formatters["evalNumberFormatter"](
                                    arg,
                                    row,
                                );
                            },
                        },
                        {
                            headerLabel: "Interaction Date",
                            dataKey: "interactionDate",
                            formatter: formatters["interactionDateTime"],
                            valueGetter(dataValue?: string) {
                                if (isNullableType(dataValue)) return undefined;

                                return moment(
                                    interactionDateHelper(dataValue),
                                ).format("YYYY-MM-DD h:mm A");
                            },
                        },
                        {
                            headerLabel: "Completion Date",
                            dataKey: "submittedDate",
                            formatter: formatters["dateTime"],
                        },
                        {
                            headerLabel: "",
                            dataKey: "evaluationQbId",
                            formatter: (arg, row: AgentCoachingData) => {
                                if (
                                    row.coachingStatus ===
                                    AgentCoachingStatus.InProgress
                                )
                                    return null;

                                return formatters["evalNavButton"](arg, row);
                            },
                        },
                        {
                            headerLabel: "Scores",
                            dataKey: "scores",
                            formatter: formatters["scoresFormatter"],
                        },
                    ],
                });

                tabs.push({
                    label: this.myDraftsTab,
                    tableComponentStore: this.evalTableStores.get(
                        this.myDraftsTab,
                    )!,
                    enableCheck: true,
                    columns: [
                        {
                            headerLabel: "Evaluation Numbers",
                            dataKey: "targetedEvaluationIds",
                            formatter: (arg: string, row: AgentCoaching) => {
                                const evaluationIds = JSON.parse(
                                    arg,
                                ) as string[];

                                const evaluations: AgentCoachingData[] = [];
                                for (const evaluationId of evaluationIds) {
                                    const evaluation =
                                        this.agentEvaluations?.all.find(
                                            (evaluation) =>
                                                evaluation.id === evaluationId,
                                        );
                                    if (!evaluation) continue;
                                    evaluations.push(evaluation);
                                }

                                const hasAiEvals = this.hasMachineEvals(row);

                                return (
                                    <Grid
                                        container
                                        columnSpacing={1 / 4}
                                        direction="row"
                                        justifyContent="start"
                                    >
                                        {hasAiEvals &&
                                            "Show AI Scored Evaluations to View Draft"}
                                        {!hasAiEvals &&
                                            evaluations.map(
                                                (evaluation) =>
                                                    evaluation && (
                                                        <Grid
                                                            item
                                                            key={evaluation.id}
                                                        >
                                                            {formatters[
                                                                "evalNumberFormatter"
                                                            ](
                                                                undefined,
                                                                evaluation,
                                                            )}
                                                        </Grid>
                                                    ),
                                            )}
                                    </Grid>
                                );
                            },
                        },
                        {
                            headerLabel: "Last Modified",
                            dataKey: "modifiedOn",
                            formatter: formatters["dateTime"],
                        },
                        {
                            headerLabel: "",
                            dataKey: "id",
                            formatter: (id: string, row: AgentCoaching) => {
                                const hasAiEvals = this.hasMachineEvals(row);
                                const Container = hasAiEvals
                                    ? React.Fragment
                                    : Link;
                                return (
                                    <Container
                                        to="/app/agentcoaching/evaluationreview"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <AcxButton
                                            fullWidth
                                            color="primary"
                                            startIcon={<Edit />}
                                            onClick={() =>
                                                this.reviewDraft(row)
                                            }
                                            disabled={hasAiEvals}
                                        >
                                            Edit
                                        </AcxButton>
                                    </Container>
                                );
                            },
                        },
                    ],
                });
            }
        }
        this.tabTableData = tabs;
    }
    @action
    toggleShowMachineEvals = () => {
        this.showMachineEvals = !this.showMachineEvals;
    };

    private hasMachineEvals(agentCoaching: AgentCoaching) {
        if (!agentCoaching.targetedEvaluationIds) return false;
        const evaluationIds = JSON.parse(
            agentCoaching.targetedEvaluationIds,
        ) as string[];

        for (const evaluationId of evaluationIds) {
            const evaluation = this.agentEvaluations?.all.find(
                (evaluation) => evaluation.id === evaluationId,
            );
            if (!evaluation) return true;
        }

        return false;
    }

    @action
    private async loadAgentEvaluations(
        orgId: string,
        agentId: string,
        startDate: Moment,
        endDate: Moment,
        dateReference?: DateReferenceOption,
        hierarchyIds?: string[],
        showMachineEvals?: boolean,
    ) {
        const res = await this.agentCoachingService.getAgentEvaluations(
            agentId,
            serializeToUtc(startDate)!,
            serializeToUtc(endDate)!,
            dateReference,
            this.authStore.isLoggedInUserAgent(),
            hierarchyIds,
            showMachineEvals,
        );

        runInAction(() => {
            res.all.sort((a, b) => {
                if (
                    (_.get(b, "interaction.interactionDate") ?? 0) <
                        (_.get(a, "interaction.interactionDate") ?? 0) ||
                    isNullableType(_.get(b, "interaction.interactionDate"))
                ) {
                    return -1;
                }
                if (
                    (_.get(b, "interaction.interactionDate") ?? 0) >
                        (_.get(a, "interaction.interactionDate") ?? 0) ||
                    isNullableType(_.get(a, "interaction.interactionDate"))
                ) {
                    return 1;
                }
                return 0;
            });
            this.agentEvaluations = res;
            this.loadEvaluationModuleScores(orgId, this.agentEvaluations);
        });
    }

    @action
    private async loadEvaluationModuleScores(
        orgId: string,
        evals: AgentEvaluations,
    ) {
        const res =
            await this.agentCoachingService.getModuleScoreForEvaluations(
                evals.all.map((val) => val.id),
            );

        runInAction(() => {
            // map module scores on to evals
            evals.all = evals.all.map((currentEval) => {
                return matchScoreToEval(currentEval, res);
            });
            evals.notCoached = evals.notCoached.map((currentEval) => {
                return matchScoreToEval(currentEval, res);
            });
            this.agentEvaluations = { ...evals };
        });
    }

    @action
    private async loadMyDrafts(agentId: string) {
        this.agentDrafts = await this.agentCoachingService.getMyDrafts(agentId);
    }

    @action
    public async deleteSelectedDrafts() {
        const myDraftsTable = this.evalTableStores.get(this.myDraftsTab);
        if (!myDraftsTable) return;

        const ids = myDraftsTable.selectedItemIds;
        if (!ids || ids.length === 0) return;

        return this.setupAsyncTask("Delete Drafts", async () => {
            try {
                await this.agentCoachingService.deleteDrafts(ids);
                myDraftsTable.setItems(
                    myDraftsTable.items.filter(
                        (item) => !ids.includes(item.id),
                    ),
                );
                myDraftsTable.clearSelectedItems();
                this.loadEvaluationsAndDrafts();
            } catch (error: any) {}
        });
    }

    @action
    refresh(
        startDate: Moment,
        endDate: Moment,
        dateRef: DateReferenceOption,
        hierarchyIds: string[] | undefined,
    ) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.dateReference = dateRef;
        this.hierarchyIds = hierarchyIds;
    }

    @action
    initialize(
        orgId: string,
        agentId: string,
        startDate?: Moment,
        endDate?: Moment,
        dateReference?: DateReferenceOption,
        hierarchyIds?: string[] | undefined,
    ) {
        this.agentId = agentId;
        this.orgId = orgId;
        this.startDate = startDate;
        this.endDate = endDate;
        this.dateReference = dateReference;
        this.hierarchyIds = hierarchyIds;
    }
}
