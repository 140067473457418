import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { ModuleUIModel } from "components/Evaluation/Models/ModuleUIModel";
import type {
    AISpeakerHighlight,
    ClipTag,
    EvalLicensedModule,
    EvalQuestion,
    StructuredHighlights,
} from "components/Evaluation/Stores/EvalStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { PartialSegment } from "components/SoundClipEditor/SoundClipEditor";
import SoundClipEditorStore from "components/SoundClipEditor/Stores/SoundClipEditorStore";
import { ChatPersona, IChatRecord } from "components/UI/Chat/AcxChatViewer";
import _ from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { computedFn } from "mobx-utils";
import Agentv2 from "models/Agentv2";
import { Answer } from "models/Answer";
import AudioMetadataModel from "models/AudioMetadataModel";
import { DataProcessingOptions } from "models/DataProcesses";
import { EddyEffectP2Results } from "models/EddyEffectP2Results";
import EvaluationModule from "models/EvaluationModule";
import type { HighlightsForTagResults } from "models/HighlightsForTagResults";
import { InteractionType } from "models/InteractionType";
import LicensedModule, { LMType, RenderPlacement } from "models/LicensedModule";
import Question from "models/Question";
import SoundClip, { ClipUIModel } from "models/SoundClip";
import { SoundClipAnswer } from "models/SoundClipAnswer";
import { StorageAccountUseOptions } from "models/StorageAccount";
import { Tag } from "models/Tag";
import { ITranscriptionEntity } from "models/TranscriptionEntity";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import { User } from "oidc-client";
import { AudioFilesService } from "services/AudioFilesService";
import { ChatMediaService } from "services/ChatMediaService";
import ClassifierService from "services/ClassifierService";
import { EvaluationService } from "services/EvaluationService";
import { MetadataService } from "services/MetadataService";
import { ModuleService } from "services/ModuleService";
import { SoundClipService } from "services/SoundClipService";
import type { ITranscriptionPayload } from "services/TranscriptionService";
import { TranscriptionService } from "services/TranscriptionService";
import { WorkflowService } from "services/WorkflowService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { Speaker, getSpeakerColorByChannelOrPersona } from "Theme/utils";
import { isNotNull } from "utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { WFRInstanceLevelState } from "../Components/WFRInstanceLevel/WFRInstanceLevel";
import { shouldRender } from "../Components/WFRQuestionAnswerList/WFRQuestionAnswerList";
import type {
    WorkflowInfoAssignedWorkflowInstance,
    WorkflowReviewerInfo,
} from "../types";
import { WorkflowStore } from "./WorkflowStore";

const ClassifierValidationModuleTypeName = "ClassifierValidation";
const CapaReviewModulesTypeName = "APT Review";

export const WorkflowLoading = "Workflow Loading";
export const ModulesLoading = "Modules Loading";
@AcxStore
export class WorkflowReviewStore extends BaseStore {
    readonly workflowService = new WorkflowService();
    readonly moduleService = new ModuleService();
    readonly evaluationService = new EvaluationService();
    readonly classifierService = new ClassifierService();
    readonly soundClipService = new SoundClipService();
    readonly metadataService = new MetadataService();
    private readonly audioFilesService: AudioFilesService =
        new AudioFilesService();
    readonly transcriptionService = new TranscriptionService();
    readonly chatMediaService = new ChatMediaService();

    readonly authStore: AuthStore;
    readonly workflowStore: WorkflowStore;
    readonly messageStore: MessageStore;
    soundClipEditorStore?: SoundClipEditorStore;

    enableAutoBindClips: boolean = false;

    @observable currentModule?: LicensedModule;
    @observable licensedModules: LicensedModule[] = [];
    @observable disputeHasStarted: boolean = false;
    @observable chatTranscription?: IChatRecord[] = [];
    @observable stepperLevel?: number;
    @observable evalLicensedModules: EvalLicensedModule[] = [];
    @observable moduleUIModels: Map<string, ModuleUIModel> = observable.map();
    @observable audio?: ClipUIModel;
    @observable clipAudioMetadata?: AudioMetadataModel;
    @observable transcriptionData?: ITranscriptionPayload;
    @observable eddyEffectP2Data: EddyEffectP2Results[] = [];
    @observable soundClips: SoundClip[] = [];

    @observable visibleQuestionIds: string[] = [];

    @observable workflowReviewerInfo?: WorkflowReviewerInfo;

    @observable currentDynamicModule?: string;

    @observable showAIExplanation: { [key: string]: boolean } = {};
    @observable structuredHighlights: Map<string, StructuredHighlights> =
        new Map();
    @observable explanationLoadingStates: { [key: string]: boolean } = {};

    @observable.ref agentList: Agentv2[] = [];
    @observable.ref tags: Array<Tag> = [];
    @observable.ref transcriptionModels?: ITranscriptionEntity[];

    @observable.ref redactedClipDownloader?: () => Promise<ArrayBuffer>;

    @observable orgId?: string;
    @observable workflowId?: string;
    @observable user: User | null = null;

    readonly dynamicEvalModules = [
        ClassifierValidationModuleTypeName,
        CapaReviewModulesTypeName,
    ];

    constructor(public rootStore: IRootStore) {
        super("WorkflowReviewStore");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.workflowStore = new WorkflowStore(this);
        this.messageStore = rootStore.getStore(MessageStore);

        this.loadUserProfile();

        // reset stepper on workflow id change
        reaction(
            () => this.workflowId,
            () => {
                this.stepperLevel = undefined;
            },
        );

        reaction(
            (r) => ({
                evaluationModules: this.evaluationModules,
                modules: [...this.licensedModules],
                response: this.workflowReviewerInfo,
            }),
            (arg) => {
                const evalModulesArray = [
                    ...(arg.response?.workflowInfo.workflowLevelInfos.map(
                        (level) => level.evaluationModule,
                    ) ?? []),
                    ...arg.evaluationModules,
                ].filter(isNotNull);
                if (
                    arg.evaluationModules &&
                    arg.modules &&
                    arg.modules.length > 0 &&
                    arg.response
                ) {
                    let answers =
                        arg.response.workflowType === WorkflowConditionType.APT
                            ? [
                                  ...arg.response.workflowInfo.answers,
                                  ...arg.response.workflowInfo.hiddenAnswers,
                              ]
                            : [...arg.response.workflowInfo.answers];
                    const licensedModulesForEval = [] as EvalLicensedModule[];

                    // Adds Workflow Module Answers
                    arg.response.workflowInfo.workflowLevelInfos.forEach(
                        (workflowLevel) => {
                            if (!!workflowLevel.answers) {
                                answers = answers.concat(workflowLevel.answers);
                            }
                        },
                    );

                    for (let module of arg.modules as EvalLicensedModule[]) {
                        if (module.lmType === LMType.Workflow) {
                            let evalModules = evalModulesArray.filter(
                                (value) =>
                                    value?.licensedModuleId === module.id,
                            );

                            for (let evalModule of evalModules) {
                                if (evalModule) {
                                    const workflowModule =
                                        LicensedModule.fromJson(
                                            _.cloneDeep(
                                                toJS(module),
                                            ) as EvalLicensedModule,
                                        ) as EvalLicensedModule;
                                    workflowModule.evaluationModule =
                                        evalModule;
                                    workflowModule.evaluationModuleId =
                                        evalModule.id;
                                    workflowModule.id =
                                        evalModule.licensedModuleId;
                                    licensedModulesForEval.push(workflowModule);
                                }
                            }
                        } else {
                            let evalModule = arg.evaluationModules.find(
                                (value) =>
                                    value?.licensedModuleId === module.id,
                            );

                            if (evalModule) {
                                module.id = evalModule.licensedModuleId;
                                module.evaluationModuleId = evalModule.id;
                                module.evaluationModule = evalModule;
                                module.hasDisputedAnswers =
                                    this.isModuleDisputedOrEscalated(module);
                                licensedModulesForEval.push(module);
                            }
                        }
                    }
                    runInAction(() => {
                        this.evalLicensedModules = licensedModulesForEval
                            .filter((lm) => lm.isActive)
                            .sort((a, b) => a.order - b.order);

                        const tags: Tag[] = [];
                        this.evalLicensedModules.forEach((lm) => {
                            lm.questions.forEach((ques) => {
                                tags.push(
                                    ...ques.tags.filter(
                                        (value) => value.isActive,
                                    ),
                                );
                            });
                        });

                        this.setTags(tags.map((value) => Tag.fromJson(value)));
                        this.evalLicensedModules.forEach((licensedModule) => {
                            if (
                                !this.moduleUIModels.has(
                                    licensedModule.evaluationModuleId,
                                )
                            ) {
                                this.moduleUIModels.set(
                                    licensedModule.evaluationModuleId,
                                    new ModuleUIModel(
                                        licensedModule.questions,
                                        licensedModule,
                                        [],
                                    ),
                                );
                            }
                        });

                        // TODO: Check if necessary for clipping, when it's brought in
                        // this.groupClipTags.splice(
                        //     0,
                        //     this.groupClipTags.length,
                        //     ...groupedTagsFromTags(this.clipTags),
                        // );

                        answers
                            .flatMap((value) => value.answerTags)
                            .forEach((value) => {
                                value.tag = this.tags?.find(
                                    (value1) => value1.id === value.tagId,
                                );
                            });

                        // answers
                        //     .flatMap((value) => value.soundClipAnswers)
                        //     .forEach((value) => {
                        //         value.soundClip =
                        //             arg.evaluation?.soundClips?.find(
                        //                 (value1) =>
                        //                     value1.id === value.soundClipId,
                        //             );
                        //     });

                        // arg.evaluation?.soundClips?.forEach((value) => {
                        //     parseStringTags(this.clipTags, value);
                        // });

                        let initCurrentModule: LicensedModule | undefined;

                        // TODO Suport disputeReviewModule tab type
                        // TODO not working (latest)
                        // TODO if workflowInstances array contains instances that are not APT,
                        // and not at boss level, then don't show APT module tab in eval page
                        if (
                            this.workflowStore.aptInstanceExists ||
                            this.workflowStore.disputeBossLevelExists
                        ) {
                            this.setCurrentModule(CapaReviewModulesTypeName);
                        } else {
                            initCurrentModule = toJS(
                                this.evalLicensedModules?.filter(
                                    (v) =>
                                        v.renderPlacement ===
                                            RenderPlacement.Middle &&
                                        !v.isWorkflowModule,
                                ),
                            )?.[0];

                            if (initCurrentModule && !this.currentModule) {
                                this.setCurrentModule(initCurrentModule);
                            }
                        }
                    });
                }
            },
        );
    }

    @action
    async initWorkflowReviewStore(workflowId: string, orgId: string) {
        this.workflowId = workflowId;
        this.orgId = orgId;
        this.setupAsyncTask(WorkflowLoading, async () => {
            try {
                await this.getWorkflowsForReviewer(workflowId);
                this.getAllModules();

                if (this.evalId && this.amdId) {
                    if (
                        this.workflowReviewerInfo?.workflowInfo
                            .interactionType === InteractionType.Audio
                    ) {
                        this.getTranscriptions();
                    }
                    if (
                        this.workflowReviewerInfo?.workflowInfo
                            .interactionType === InteractionType.Chat ||
                        this.workflowReviewerInfo?.workflowInfo
                            .interactionType === InteractionType.ProcessedChat
                    ) {
                        this.getChatTranscription();
                    }
                    await this.getClipAudioMetadata();
                    await this.audioClipForEval();
                    this.setSoundClipEditorStore();
                }
            } catch (e: any) {
                if (e.message) {
                    this.messageStore.logError(
                        `Error loading workflow: ${e.message}`,
                    );
                } else {
                    this.messageStore.logError("Error loading workflow.");
                }
            }
        });
    }

    @action
    setCurrentModule = (module: string | LicensedModule) => {
        if (this.dynamicEvalModules.includes(module as string)) {
            this.currentModule = undefined;
            this.currentDynamicModule = module as string;
        } else {
            this.currentDynamicModule = undefined;
            if (typeof module === "string") {
                this.currentModule = this.evalLicensedModules?.find(
                    (value) => value.name === module,
                );
            } else {
                this.currentModule = module;
            }
        }
    };

    @computed
    get modulesNameById(): Map<string, string> {
        const moduleNames: Map<string, string> = new Map<string, string>();
        this.evalLicensedModules
            .filter((value) => value.lmType !== LMType.Workflow)
            .forEach((value) => {
                moduleNames.set(value.id, value.name!);
            });
        return moduleNames;
    }

    @action
    async loadTEddyEffectP2Results(amdId?: string) {
        if (!amdId) return;
        this.eddyEffectP2Data = [];
        const eddyEffectP2Results =
            await this.classifierService.getEddyEffectP2Results(amdId);

        if (!_.isEmpty(eddyEffectP2Results)) {
            runInAction(() => {
                this.eddyEffectP2Data = eddyEffectP2Results.map((eddy) => ({
                    startWordIndex: eddy.eddyStartWordIndex,
                    endWordIndex: eddy.eddyEndWordIndex,
                }));
            });
        }
    }

    @action
    setExplanationLoadingStates(tagId: string, isLoading: boolean) {
        this.explanationLoadingStates[tagId] = isLoading;
    }

    @action
    setTags(tags: Tag[]) {
        this.tags = tags;
    }

    @action
    setShowAIExplanation(questionId: string, showExplanation: boolean) {
        this.showAIExplanation[questionId] = showExplanation;
    }

    @action
    setStructuredHighlights = (
        tagId: string,
        structuredHighlights: StructuredHighlights,
    ) => {
        this.structuredHighlights.set(tagId, structuredHighlights);
    };

    @action
    async fetchActiveSoundClips(evalId: string) {
        const clips = await this.soundClipService.getSoundClipsByEvalId(evalId);
        this.soundClips = clips;
    }

    @computed
    get activeSoundClips() {
        return this.soundClips?.filter((s) => s.isActive && s.id);
    }

    @computed
    get clipsToSegmentList(): PartialSegment[] {
        const soundClipAnswers =
            this.answers.flatMap((answer) =>
                this.visibleQuestionIds.includes(answer.questionId)
                    ? answer.soundClipAnswers.map(
                          (soundClipAnswer) => soundClipAnswer.soundClipId,
                      )
                    : [],
            ) ?? [];

        const activeUniqueSoundClips = _.uniqBy(
            this.activeSoundClips,
            (clip) => clip.id,
        ).filter((clip) => soundClipAnswers.includes(clip.id));

        return activeUniqueSoundClips?.map((clip) => {
            return {
                startTime: clip.startTime ?? 0,
                endTime: clip.endTime ?? 0,
                id: clip.id,
                labelText: clip.segmentName,
            };
        });
    }

    private static getEvalIdFromWorkflowInfo(
        workflowInfo?: WorkflowReviewerInfo,
    ) {
        switch (workflowInfo?.workflowType) {
            case WorkflowConditionType.APT: {
                return (
                    workflowInfo.workflowInfo.evaluationModule?.evaluationId ??
                    null
                );
            }
            case WorkflowConditionType.ScoreEscalation: {
                return (
                    workflowInfo.workflowInfo.scoreEscalationModule
                        .evaluationId ?? null
                );
            }
            case WorkflowConditionType.EvaluationDispute: {
                return (
                    workflowInfo.workflowInfo.evaluationModules?.[0]
                        .evaluationId ?? null
                );
            }
            default: {
                return null;
            }
        }
    }

    @computed
    get evalId(): string | null {
        return WorkflowReviewStore.getEvalIdFromWorkflowInfo(
            this.workflowReviewerInfo,
        );
    }

    @computed
    get amdId(): string | null {
        return this.workflowReviewerInfo?.workflowInfo.audioMetadataId ?? null;
    }

    @computed
    get interactionType(): InteractionType | null {
        return this.workflowReviewerInfo?.workflowInfo.interactionType ?? null;
    }

    @computed
    get triggerModuleIsEditable(): boolean {
        return !!(
            this.workflowReviewerInfo?.workflowInfo.isEvalEditable &&
            this.workflowReviewerInfo.workflowInfo.status !==
                WorkflowStatus.Closed &&
            this.workflowReviewerInfo.workflowInfo.assignedToUser
        );
    }

    @computed
    get triggerNoteIsEditable(): boolean {
        return (
            this.triggerModuleIsEditable &&
            this.workflowReviewerInfo?.workflowType !==
                WorkflowConditionType.APT
        );
    }

    @computed
    get currentLevel() {
        return this.stepperLevel !== undefined &&
            !!this.workflowReviewerInfo?.workflowInfo.workflowLevelInfos?.[
                this.stepperLevel
            ]
            ? {
                  ...this.workflowReviewerInfo?.workflowInfo
                      .workflowLevelInfos?.[this.stepperLevel],
                  isBossLevel:
                      this.stepperLevel ===
                      this.workflowReviewerInfo?.workflowInfo.workflowLevelInfos
                          .length -
                          1,
              }
            : null;
    }

    @computed
    get mediaUrl() {
        return this.workflowReviewerInfo?.workflowInfo.mediaUrl;
    }

    @computed
    get currentLevelState(): WFRInstanceLevelState | undefined {
        if (this.stepperLevel === undefined) {
            return undefined;
        }
        return this.workflowInfoWithAssignedWorkflowInstances?.workflowInfo
            .workflowLevelInfos[this.stepperLevel].state;
    }

    @computed
    get currentLevelName(): string {
        const defaultLevelName = this.workflowReviewerInfo
            ? `Level ` + this.workflowReviewerInfo.workflowInfo.currentLevel + 1
            : "";
        return (
            this.currentLevel?.levelName ??
            this.workflowReviewerInfo?.levelNames?.[
                this.currentLevel?.level ?? -1
            ] ??
            defaultLevelName
        );
    }

    @computed
    get disputedModulesWithScores():
        | {
              module: EvaluationModule;
              score: number;
          }[]
        | null {
        const scoredAnswers: Map<string, number[]> = new Map();
        const scoredWeights: Map<string, number> = new Map<string, number>();

        if (
            this.workflowReviewerInfo?.workflowType !==
            WorkflowConditionType.EvaluationDispute
        ) {
            return null;
        }

        const modules =
            this.workflowReviewerInfo.workflowInfo.evaluationModules
                ?.map((mod) => {
                    const licensedModule = this.evalLicensedModules.find(
                        (module) => module.id === mod.licensedModuleId,
                    );
                    if (!licensedModule) {
                        return null;
                    }
                    return {
                        licensedModule,
                        evalModule: mod,
                    };
                })
                .filter(isNotNull) ?? [];

        return modules
            .map(({ evalModule, licensedModule: module }) => {
                if (module) {
                    module.questions.forEach((question) => {
                        const tags = this.answers
                            .filter(
                                (answer) => answer.questionId === question.id,
                            )
                            .map((answer) => answer.activeTags)
                            .flat();
                        const questionWeight = question.weight ?? 1.0;

                        if (
                            tags.filter(
                                (value) =>
                                    value?.scoredValue !== null &&
                                    value?.scoredValue !== undefined &&
                                    !question.answerType
                                        ?.excludeFromModuleScoreCalculation,
                            ).length
                        ) {
                            scoredWeights.set(
                                question.id,
                                question.weight ?? 1,
                            );
                        } else {
                            scoredWeights.delete(question.id);
                        }

                        const values: number[] = [];
                        tags.forEach((value) => {
                            if (
                                value?.scoredValue !== null &&
                                value?.scoredValue !== undefined &&
                                !question.answerType
                                    ?.excludeFromModuleScoreCalculation
                            ) {
                                values.push(
                                    (value.scoredValue * questionWeight) /
                                        tags.filter(
                                            (value) =>
                                                value.scoredValue !== null &&
                                                value.scoredValue !== undefined,
                                        ).length,
                                );
                            }
                        });

                        scoredAnswers.set(question.id, values);
                    });

                    const scores = [...scoredAnswers.values()].flatMap(
                        (value) => value,
                    );

                    if (scores.length === 0) {
                        return null;
                    }

                    const weightsSum = _.sum([...scoredWeights.values()]);

                    const score =
                        (100 * (_.sum(scores) ?? 0)) /
                        (scores.length === 0
                            ? 1
                            : weightsSum === 0
                            ? scores.length
                            : weightsSum);
                    return { score, module: evalModule };
                }
                return null;
            })
            .filter(isNotNull);
    }

    @computed
    get moduleScores() {
        // const module = this.evalLicensedModules.find(
        //     (module) =>
        //         module.id ===
        //             this.evaluationModule.
        // );

        const modules = this.evaluationModules.map((evaluationModule) =>
            this.evalLicensedModules.find(
                (evalLicensedModule) =>
                    evalLicensedModule.id === evaluationModule.licensedModuleId,
            ),
        );

        return modules.map((module) => {
            const scoredAnswers: Map<string, number[]> = new Map();
            const scoredWeights: Map<string, number> = new Map<
                string,
                number
            >();
            if (module) {
                module.questions.forEach((question) => {
                    const tags = this.answers
                        .filter((answer) => answer.questionId === question.id)
                        .map((answer) => answer.activeTags)
                        .flat();
                    const questionWeight = question.weight ?? 1.0;

                    if (
                        tags.filter(
                            (value) =>
                                value?.scoredValue !== null &&
                                value?.scoredValue !== undefined &&
                                !question.answerType
                                    ?.excludeFromModuleScoreCalculation,
                        ).length
                    ) {
                        scoredWeights.set(question.id, question.weight ?? 1);
                    } else {
                        scoredWeights.delete(question.id);
                    }

                    const values: number[] = [];
                    tags.forEach((value) => {
                        if (
                            value?.scoredValue !== null &&
                            value?.scoredValue !== undefined &&
                            !question.answerType
                                ?.excludeFromModuleScoreCalculation
                        ) {
                            values.push(
                                (value.scoredValue * questionWeight) /
                                    tags.filter(
                                        (value) =>
                                            value.scoredValue !== null &&
                                            value.scoredValue !== undefined,
                                    ).length,
                            );
                        }
                    });

                    scoredAnswers.set(question.id, values);
                });

                const scores = [...scoredAnswers.values()].flatMap(
                    (value) => value,
                );

                if (scores.length === 0) {
                    return null;
                }

                const weightsSum = _.sum([...scoredWeights.values()]);

                const score =
                    (100 * (_.sum(scores) ?? 0)) /
                    (scores.length === 0
                        ? 1
                        : weightsSum === 0
                        ? scores.length
                        : weightsSum);
                return { score, ...module };
            }
            return null;
        });
    }

    @computed
    get moduleScore(): number | null {
        const scoredAnswers: Map<string, number[]> = new Map();
        const scoredWeights: Map<string, number> = new Map<string, number>();

        const modules = this.evaluationModules.map((evaluationModule) =>
            this.evalLicensedModules.find(
                (evalLicensedModule) =>
                    evalLicensedModule.id === evaluationModule.licensedModuleId,
            ),
        );

        const module = modules.length > 0 && modules[0];

        if (module) {
            module.questions.forEach((question) => {
                const tags = this.answers
                    .filter((answer) => answer.questionId === question.id)
                    .map((answer) => answer.activeTags)
                    .flat();

                const questionWeight = question.weight ?? 1.0;

                if (
                    tags.filter(
                        (value) =>
                            value?.scoredValue !== null &&
                            value?.scoredValue !== undefined &&
                            !question.answerType
                                ?.excludeFromModuleScoreCalculation,
                    ).length
                ) {
                    scoredWeights.set(question.id, question.weight ?? 1);
                } else {
                    scoredWeights.delete(question.id);
                }

                const values: number[] = [];
                tags.forEach((value) => {
                    if (
                        value?.scoredValue !== null &&
                        value?.scoredValue !== undefined &&
                        !question.answerType?.excludeFromModuleScoreCalculation
                    ) {
                        values.push(
                            (value.scoredValue * questionWeight) /
                                tags.filter(
                                    (value) =>
                                        value.scoredValue !== null &&
                                        value.scoredValue !== undefined,
                                ).length,
                        );
                    }
                });

                scoredAnswers.set(question.id, values);
            });

            const scores = [...scoredAnswers.values()].flatMap(
                (value) => value,
            );

            if (scores.length === 0) {
                return null;
            }

            const weightsSum = _.sum([...scoredWeights.values()]);

            const score =
                (100 * (_.sum(scores) ?? 0)) /
                (scores.length === 0
                    ? 1
                    : weightsSum === 0
                    ? scores.length
                    : weightsSum);
            return score;
        }
        return null;
    }

    @computed
    get userCanEditWorkflow(): boolean {
        return (
            !!this.workflowReviewerInfo?.workflowInfo.assignedToUser &&
            this.workflowReviewerInfo?.workflowInfo.currentLevel ===
                this.stepperLevel &&
            this.authStore.canUserView("Workflows") &&
            this.workflowReviewerInfo.workflowInfo.status !==
                WorkflowStatus.Closed
        );
    }

    @action
    async getAllModules() {
        this.setupAsyncTask(ModulesLoading, async () => {
            try {
                if (this.orgId && this.evalId) {
                    const modules =
                        await this.moduleService.getAllLicensedModuleTypes(
                            this.orgId,
                            this.evalId,
                        );
                    runInAction(() => {
                        this.licensedModules = modules.map((value) =>
                            LicensedModule.fromJson(value),
                        );
                    });
                }
            } catch (e: any) {
                if (e.message) {
                    this.messageStore.logError(
                        `Error loading modules: ${e.message}`,
                    );
                } else {
                    this.messageStore.logError("Error loading modules");
                }
            }
        });
    }

    private loadUserProfile(): Promise<User | null> {
        return this.authStore
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.user = value;
                });
                return value;
            })
            .catch((reason) => {
                console.error(`EvalStore::failed to load userInfo ${reason}`);
                return null;
            });
    }

    @action
    public async getHighlightsForTag(tagId: string, amdId: string) {
        try {
            let highlightsTagResponse =
                await this.evaluationService.getHighlightsForTag(tagId, amdId);
            this.formatAndSetStructuredHighlights(highlightsTagResponse);
        } catch (error) {
            throw error;
        }
    }

    @action
    formatAndSetStructuredHighlights(highlightTagRes: HighlightsForTagResults) {
        if (highlightTagRes.predictorType !== "Lucene") {
            return;
        }
        let speakers: { [key: string]: string } = {};

        const splitHighlightsArr = highlightTagRes.highlights
            ?.split("/n")
            .filter((message) => message.length);

        const aiSpeakerHighlights: AISpeakerHighlight[] =
            splitHighlightsArr?.map((message, index) => {
                const [speaker, ...messageParts] = message.split(": ");
                const speakerTitle =
                    this.convertSpeakerTitleInHighlights(speaker);
                const formattedHighlightText = messageParts.join(": ");
                const wordsFromHighlightText: {
                    phrase: string;
                    count: number;
                }[] = [];
                if (!speakers[speakerTitle]) {
                    speakers[speakerTitle] = getSpeakerColorByChannelOrPersona(
                        this.getSpeakerChannelOrChatPersona(speaker),
                    );
                }
                const capitalize = (str: string) => {
                    return str[0].toUpperCase() + str.slice(1).toLowerCase();
                };
                const rbcRegex = /<b>(.*?)<\/b>/g;
                let match = message.match(rbcRegex);
                match?.forEach((m) => {
                    const phraseCleaned = capitalize(
                        m.replace("<b>", "").replace("</b>", ""),
                    );
                    const found = _.findIndex(
                        wordsFromHighlightText,
                        (e) => phraseCleaned === e.phrase,
                    );
                    if (
                        found !== -1 &&
                        wordsFromHighlightText?.[found]?.count
                    ) {
                        wordsFromHighlightText[found].count =
                            wordsFromHighlightText[found].count + 1;
                    } else {
                        wordsFromHighlightText.push({
                            phrase: phraseCleaned,
                            count: 1,
                        });
                    }
                });
                return {
                    speaker: speakerTitle,
                    highlightText: formattedHighlightText,
                    color: speakers[speakerTitle],
                    wordsForRbc: wordsFromHighlightText,
                };
            });

        this.setStructuredHighlights(highlightTagRes.tagId, {
            aiSpeakerHighlights,
            ...highlightTagRes,
        });
    }

    ///
    /// This needs to be removed
    ///
    @computed
    get workflowAnswer(): Answer | undefined {
        switch (this.workflowReviewerInfo?.workflowType) {
            case WorkflowConditionType.APT:
                return this.workflowReviewerInfo.workflowInfo.answers?.[0];
            case WorkflowConditionType.ScoreEscalation:
                const seModule =
                    this.workflowReviewerInfo.workflowInfo
                        .scoreEscalationModule;
                return this.workflowReviewerInfo.workflowInfo.answers.find(
                    (answer) => answer.evaluationModuleId === seModule.id,
                );
            default:
                return undefined;
        }
    }

    @computed
    get workflowEvalModules(): EvalLicensedModule[] {
        return (
            this.evalLicensedModules?.filter(
                (value) => value.lmType === LMType.Workflow,
            ) ?? []
        );
    }

    @computed
    get workflowInfoWithAssignedWorkflowInstances():
        | WorkflowInfoAssignedWorkflowInstance
        | undefined {
        if (!this.workflowReviewerInfo) {
            return undefined;
        }
        const user = this.authStore._user;

        const {
            workflowInfo: { workflowLevelInfos, ...workflowInfo },
            workflowType,
            levelNames,
        } = this.workflowReviewerInfo;

        const bossLevelIndex =
            this.workflowReviewerInfo.levelNames?.length - 1 ?? 0;
        const bossModule =
            this.workflowReviewerInfo.workflowInfo.workflowLevelInfos[
                bossLevelIndex
            ]?.evaluationModule;

        return {
            workflowInfo: {
                ...workflowInfo,

                assignedToUser: workflowInfo.assignedToUser,
                implementationType: workflowType,
                status: workflowInfo.status,
                activeLevel: workflowInfo.currentLevel,
                conditionName: workflowInfo.conditionName,
                question:
                    this.workflowAnswer?.question?.shortQuestionText ?? "",
                questionModuleId:
                    this.workflowAnswer?.question?.licensedModuleId ?? "",
                bossEvalModule: bossModule,
                bossEvalModuleId: bossModule?.id,
                instanceId: workflowInfo.instanceId,
                id: workflowInfo.workflowInstanceId,
                evaluationId: workflowInfo.evaluationModule?.evaluationId ?? "",
                evaluationModule: workflowInfo.evaluationModule,
                workflowLevelInfos: workflowLevelInfos.map((level, idx) => {
                    const state: WFRInstanceLevelState =
                        bossModule &&
                        level.evaluationModule?.id === bossModule?.id &&
                        bossModule?.id !== null
                            ? "boss"
                            : level.result !== null
                            ? "complete"
                            : "pending";

                    return {
                        ...level,
                        workflowInstanceId: workflowInfo.workflowInstanceId,
                        result: level.result as "approve" | "reject" | null,
                        note: level.note,
                        workflowMessagingMetadataId:
                            level.workflowMessagingMetadataId,
                        evaluationModuleId: level.evaluationModule?.id ?? "",
                        evaluationModule: level.evaluationModule,
                        id: level.workflowInstanceLevelId,
                        userId: user.profile.sub,
                        state,
                    };
                }),
            },
            workflowType,
            levelNames,
        };
    }

    @action
    setStepperLevel(level: number) {
        if (
            level <
            (this.workflowReviewerInfo?.workflowInfo.workflowLevelInfos
                .length ?? 0)
        ) {
            this.stepperLevel = level;
        }
    }

    @action
    async getWorkflowsForReviewer(instanceId: string) {
        try {
            const res = await this.workflowService.getWorkflowForReviewer(
                instanceId,
            );
            const instance = res[0];
            const evalId =
                WorkflowReviewStore.getEvalIdFromWorkflowInfo(instance);

            instance.workflowInfo.answers = instance.workflowInfo.answers.map(
                (answer) => Answer.fromJson(answer),
            );

            if (instance.workflowType === WorkflowConditionType.APT) {
                instance.workflowInfo.hiddenAnswers =
                    instance.workflowInfo.hiddenAnswers.map((answer) =>
                        Answer.fromJson(answer),
                    );
            }
            instance.workflowInfo.workflowLevelInfos =
                instance.workflowInfo.workflowLevelInfos.map((level) => {
                    const answers =
                        level.answers?.map((answer) =>
                            Answer.fromJson(answer),
                        ) ?? null;
                    return { ...level, answers };
                });
            if (
                instance.workflowType ===
                WorkflowConditionType.EvaluationDispute
            ) {
                const getDisputesForAnswer = (answer: Answer) => {
                    const answerDisputes =
                        instance?.workflowInfo?.answerDisputes.filter(
                            (dispute) => answer.id === dispute.answerId,
                        );
                    if (answerDisputes.length > 0) {
                        answer.answerDisputes = answerDisputes;
                        answer.isDisputed = true;
                    }
                };
                instance.workflowInfo.answers.forEach(getDisputesForAnswer);
            }
            if (instance && evalId) {
                await this.fetchActiveSoundClips(evalId);
                const mapSoundClipToAnswer = (answer) => {
                    answer.soundClipAnswers.forEach((soundClipAnswer) => {
                        if (soundClipAnswer.soundClipId) {
                            const soundClip = this.soundClips.find(
                                (soundClip) =>
                                    soundClip.id ===
                                    soundClipAnswer.soundClipId,
                            );
                            soundClipAnswer.soundClip = soundClip;
                        }
                    });
                };
                instance.workflowInfo.answers.forEach(mapSoundClipToAnswer);
                instance.workflowInfo.workflowLevelInfos.forEach((level) => {
                    if (level.answers) {
                        level.answers.forEach(mapSoundClipToAnswer);
                    }
                });
                if (
                    instance.workflowType === WorkflowConditionType.APT &&
                    instance.workflowInfo.hiddenAnswers
                ) {
                    instance.workflowInfo.hiddenAnswers.forEach(
                        mapSoundClipToAnswer,
                    );
                }
            }
            this.workflowReviewerInfo = instance;
            if (this.stepperLevel === undefined) {
                if (instance.workflowInfo.status === WorkflowStatus.Closed) {
                    this.setStepperLevel(
                        instance.workflowInfo.workflowLevelInfos.length - 1,
                    );
                } else {
                    this.setStepperLevel(instance.workflowInfo.currentLevel);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    @action
    private isModuleDisputedOrEscalated: (
        module: EvalLicensedModule,
    ) => boolean = (module: EvalLicensedModule) => {
        if (
            this.workflowReviewerInfo?.workflowType ===
            WorkflowConditionType.ScoreEscalation
        ) {
            if (
                module.evaluationModule.moduleScore == null ||
                this.workflowReviewerInfo.workflowInfo.scoreThreshold == null
            )
                return false;
            else if (
                this.workflowReviewerInfo?.workflowType ===
                    WorkflowConditionType.ScoreEscalation &&
                module.evaluationModule.moduleScore <
                    this.workflowReviewerInfo?.workflowInfo.scoreThreshold
            )
                return true;
            else return false;
        } else if (
            this.workflowReviewerInfo?.workflowType ===
            WorkflowConditionType.EvaluationDispute
        ) {
            const answers =
                this.workflowReviewerInfo.workflowInfo?.answers?.filter(
                    (value) =>
                        value.isDisputed &&
                        value.question?.licensedModuleId === module.id,
                );
            if (answers.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    @computed
    get thresholdMet() {
        return (
            this.workflowReviewerInfo?.workflowType ===
                WorkflowConditionType.ScoreEscalation &&
            this.workflowReviewerInfo.workflowInfo.scoreThreshold <=
                (this?.moduleScore ?? 0)
        );
    }

    @computed
    get questions() {
        return this.evalLicensedModules.flatMap((module) => module.questions);
    }

    @computed
    get answers(): Answer[] {
        let answers: Answer[] = [];
        const levelAnswers =
            this.workflowReviewerInfo?.workflowInfo.workflowLevelInfos
                .flatMap((level) => level.answers)
                .filter(isNotNull) ?? [];
        if (
            this.workflowReviewerInfo?.workflowType ===
            WorkflowConditionType.APT
        ) {
            answers = [
                ...this.workflowReviewerInfo.workflowInfo.answers,
                ...this.workflowReviewerInfo.workflowInfo.hiddenAnswers,
                ...levelAnswers,
            ];
        } else {
            answers = [
                ...(this.workflowReviewerInfo?.workflowInfo.answers ?? []),
                ...levelAnswers,
            ];
        }

        if (
            this.workflowReviewerInfo?.workflowType ===
            WorkflowConditionType.EvaluationDispute
        ) {
            const disputes =
                this.workflowReviewerInfo?.workflowInfo.answerDisputes;
            answers.forEach((answer) => {
                const answerDisputes = disputes.filter(
                    (dispute) => answer.id === dispute.answerId,
                );
                if (answerDisputes.length > 0) {
                    answer.answerDisputes = answerDisputes;
                    answer.isDisputed = true;
                }
            });
        }

        answers.forEach((answer) => {
            const question = this.questions.find(
                (question) => question.id === answer?.questionId,
            );
            if (question) {
                answer.question = question;
            }
        });

        return answers;
    }

    @computed
    get evaluationModules(): EvaluationModule[] {
        switch (this.workflowReviewerInfo?.workflowType) {
            case WorkflowConditionType.APT: {
                return !!this.workflowReviewerInfo.workflowInfo.evaluationModule
                    ? [this.workflowReviewerInfo.workflowInfo.evaluationModule]
                    : [];
            }
            case WorkflowConditionType.ScoreEscalation: {
                return !!this.workflowReviewerInfo.workflowInfo
                    .scoreEscalationModule
                    ? [
                          this.workflowReviewerInfo.workflowInfo
                              .scoreEscalationModule,
                      ]
                    : [];
            }
            case WorkflowConditionType.EvaluationDispute: {
                return !!this.workflowReviewerInfo.workflowInfo
                    .evaluationModules
                    ? this.workflowReviewerInfo.workflowInfo.evaluationModules
                    : [];
            }
            default: {
                return [];
            }
        }
    }

    getModuleUIModel = computedFn((moduleId?: string) => {
        if (moduleId) {
            return this.moduleUIModels.get(moduleId);
        }
    });

    isRequiredQuestionsForWorkflowModule(evaluationModuleId: string): boolean {
        if (this.workflowReviewerInfo) {
            const requiredQuestions =
                this.evalLicensedModules
                    ?.filter((value) => value.isActive)
                    ?.flatMap((value) => {
                        const licensedModule = this.licensedModules.find(
                            (module) => module.id === value.id,
                        );
                        value.questions.forEach((q) => {
                            (q as EvalQuestion).evaluationModuleId =
                                value.evaluationModuleId;
                        });
                        return value.questions.filter((q) =>
                            shouldRender(
                                this.answers ?? [],
                                q as EvalQuestion,
                                [],
                                !!licensedModule?.isWorkflowModule,
                            ),
                        ) as EvalQuestion[];
                    })
                    .filter((q) => q.isActive)
                    .filter((value) => value.required) ?? [];

            var question = requiredQuestions.filter(
                (q) => q.evaluationModuleId === evaluationModuleId,
            );

            if (question) {
                for (let requiredQuestionGrouping of Object.entries<
                    Array<EvalQuestion>
                >(_.groupBy<EvalQuestion>(question, "id"))) {
                    let isFinished = true;
                    let requiredQuestionId = requiredQuestionGrouping[0];
                    let requiredQuestionsArray = requiredQuestionGrouping[1];

                    const answers =
                        this.workflowReviewerInfo?.workflowInfo.workflowLevelInfos?.[
                            this.workflowReviewerInfo?.workflowInfo
                                .currentLevel ?? -1
                        ]?.answers?.filter(
                            (ans) =>
                                ans.questionId === requiredQuestionId &&
                                requiredQuestionsArray
                                    .map((evalQ) => evalQ.evaluationModuleId)
                                    .some(
                                        (arg) =>
                                            arg === ans.evaluationModuleId ||
                                            !ans.evaluationModuleId,
                                    ),
                        ) ?? [];

                    if (answers.length < requiredQuestionsArray.length) {
                        isFinished = false;
                    }

                    for (let ans of answers) {
                        const question = requiredQuestions.find(
                            (q) => q.id === ans.questionId,
                        );
                        isFinished =
                            isFinished &&
                            ((ans?.answerTags.filter((v) => v.isActive)
                                .length ?? 0) > 0 ||
                                (question!.answerType.isFillInAnswer &&
                                    !!ans.fillInAnswerValue));
                    }

                    if (!isFinished) {
                        return true;
                    }
                }
            } else {
                return false;
            }
        }

        //no required questions were found
        return false;
    }

    private getSpeakerChannelOrChatPersona(speaker: string): Speaker {
        const speakerIndex = Number(speaker.split(" ")[1]);
        switch (speakerIndex) {
            case 0:
                if (this.chatTranscription) {
                    return ChatPersona.Agent;
                } else {
                    return speakerIndex;
                }
            case 1:
                if (this.chatTranscription) {
                    return ChatPersona.Customer;
                } else {
                    return speakerIndex;
                }
            default:
                return speakerIndex;
        }
    }

    private convertSpeakerTitleInHighlights(speaker: string): string {
        switch (speaker) {
            case "Speaker 0":
                if (this.chatTranscription) {
                    return "Agent";
                } else {
                    return "Speaker 1";
                }
            case "Speaker 1":
                if (this.chatTranscription) {
                    return "Customer";
                } else {
                    return "Speaker 2";
                }
            default:
                if (speaker.includes("Speaker ")) {
                    const [, speakerNumber] = speaker.split(" ");
                    return `Speaker ${Number(speakerNumber) + 1}`;
                } else {
                    return "Speaker";
                }
        }
    }

    getAnswerForQuestion = (
        question: Question,
        evaluationModuleId: string | undefined,
    ): Answer | undefined => {
        let ans: Answer | undefined = undefined;
        const answers = this.answers?.filter(
            (value) =>
                value.questionId === question.id &&
                (!value.evaluationModuleId ||
                    value.evaluationModuleId === evaluationModuleId),
        );

        if (answers.length > 0) {
            ans = answers[0];
        }

        ans?.setQuestion(question);
        return ans;
    };

    @action
    updateAnswer(
        answer: Answer,
        questionId: string | undefined,
        evaluationModuleId: string | undefined,
        newTags: Tag[] = [],
        autoBindClips?: boolean,
        messageStore?: MessageStore,
    ) {
        if (questionId === undefined || !this.workflowReviewerInfo) {
            return;
        }

        const isWorkflowModule = this.evalLicensedModules.find(
            (module) => module.evaluationModuleId === evaluationModuleId,
        )?.isWorkflowModule;

        const existingIndex = (
            isWorkflowModule
                ? this.workflowReviewerInfo.workflowInfo.workflowLevelInfos[
                      this.currentLevel?.level ?? -1
                  ].answers ?? []
                : this.workflowReviewerInfo.workflowInfo.answers
        ).findIndex(
            (value) =>
                value.questionId === questionId &&
                (!value.evaluationModuleId ||
                    value.evaluationModuleId === evaluationModuleId),
        );
        answer.evaluationModuleId = evaluationModuleId;

        if (existingIndex === -1) {
            if (isWorkflowModule) {
                this.workflowReviewerInfo.workflowInfo.workflowLevelInfos[
                    this.currentLevel?.level ?? -1
                ].answers = [
                    ...(this.workflowReviewerInfo.workflowInfo
                        .workflowLevelInfos[this.currentLevel?.level ?? -1]
                        .answers ?? []),
                    answer,
                ];
            } else {
                this.workflowReviewerInfo.workflowInfo.answers = [
                    ...this.workflowReviewerInfo.workflowInfo.answers,
                    answer,
                ];
            }
        } else {
            if (isWorkflowModule) {
                this.workflowReviewerInfo.workflowInfo.workflowLevelInfos[
                    this.currentLevel?.level ?? -1
                ].answers?.splice(existingIndex, 1, answer);
                this.workflowReviewerInfo.workflowInfo.workflowLevelInfos[
                    this.currentLevel?.level ?? -1
                ].answers = [
                    ...(this.workflowReviewerInfo.workflowInfo
                        .workflowLevelInfos[this.currentLevel?.level ?? -1]
                        .answers ?? []),
                ];
            } else {
                this.workflowReviewerInfo.workflowInfo.answers?.splice(
                    existingIndex,
                    1,
                    answer,
                );
                this.workflowReviewerInfo.workflowInfo.answers = [
                    ...this.workflowReviewerInfo.workflowInfo.answers,
                ];
            }
        }

        if (!autoBindClips || !newTags.length) return;

        const soundClipAnswersOnQuestion: SoundClipAnswer[] =
            answer.soundClipAnswers ?? [];

        let answerTypeName = answer.question.answerType.answerTypeName;

        const isTagResponseTypeAndNotSingle =
            (answerTypeName === "Tag Response" ||
                answerTypeName === "Scored Tag Response") &&
            answer.question.answerType.variation !== "Single";

        if (isTagResponseTypeAndNotSingle) return;

        soundClipAnswersOnQuestion.forEach((soundClipAnswer) => {
            const soundClip = soundClipAnswer.soundClip;
            if (!soundClip) return;

            let existingTags: ClipTag[] = soundClip.clipTags ?? [];

            if (existingTags.length !== 0) {
                const isTagInNewTags = (tag: Tag) => {
                    return newTags.some((newTag) => newTag.id === tag.id);
                };
                const removedOrChangedTag = existingTags.find(
                    (tag) =>
                        tag.questionId === questionId && !isTagInNewTags(tag),
                );

                const addNewClipTags = () => {
                    let hasTagsForQuestion = existingTags.some(
                        (tag) => tag.questionId === questionId,
                    );

                    if (hasTagsForQuestion) {
                        const tagIndex = existingTags.findIndex(
                            (tag) => tag.questionId === questionId,
                        );
                        existingTags.splice(tagIndex, 1);
                    }
                    soundClip.setClipTags([...existingTags, ...newTags] ?? []);
                };

                const setRemovedOrChangedClipTags = () => {
                    const tagsMinusRemovedChangedTag = existingTags.filter(
                        (tag) => tag.id !== removedOrChangedTag?.id,
                    );
                    soundClip.setClipTags([...tagsMinusRemovedChangedTag]);
                };

                if (removedOrChangedTag) {
                    const sameQuestionChanged = existingTags.some(
                        (tag) => tag.questionId === questionId,
                    );

                    if (!sameQuestionChanged) {
                        setRemovedOrChangedClipTags();
                    } else {
                        addNewClipTags();
                    }
                } else {
                    addNewClipTags();
                }
            } else {
                soundClip.setClipTags([...newTags]);
            }
        });
        if (soundClipAnswersOnQuestion.length) {
            messageStore?.logMessage(
                "Clip(s) attached to this question have been updated with the selected answer.",
                "success",
            );
        }
    }

    setAnswerForQuestion = (
        question: Question,
        tags: Tag[],
        answer: Answer | undefined,
        evaluationModuleId?: string,
        autoBindClips?: boolean,
        messageStore?: MessageStore,
    ) => {
        if (this.workflowReviewerInfo && this.evalId) {
            if (answer === undefined) {
                const newAnswer = new Answer(
                    this.evalId,
                    question.id,
                    question,
                    undefined,
                    this.authStore._user.profile.email,
                    this.authStore._user.profile.email,
                );
                this.updateAnswer(
                    newAnswer,
                    question.id,
                    newAnswer.evaluationModuleId ?? evaluationModuleId,
                    tags,
                    autoBindClips,
                    messageStore,
                );
                answer = newAnswer;
            } else {
                this.updateAnswer(
                    answer,
                    question.id,
                    answer.evaluationModuleId ?? evaluationModuleId,
                    tags,
                    autoBindClips,
                    messageStore,
                );
            }
            answer.setAnswerTags(tags);

            return answer;
        }
    };

    @action
    setFillInAnswerForQuestion = (
        question: Question,
        fillInAnswer: string,
        answer: Answer | undefined,
        evaluationModuleId?: string,
    ) => {
        if (this.workflowReviewerInfo && this.evalId) {
            if (answer === undefined) {
                const newAnswer = new Answer(
                    this.evalId,
                    question.id,
                    question,
                    undefined,
                    this.authStore._user.profile.email,
                    this.authStore._user.profile.email,
                    fillInAnswer,
                );

                this.updateAnswer(
                    newAnswer,
                    question.id,
                    newAnswer.evaluationModuleId ?? evaluationModuleId,
                );
                answer = newAnswer;
            } else {
                answer.fillInAnswerValue = fillInAnswer;
                this.updateAnswer(
                    answer,
                    question.id,
                    answer.evaluationModuleId ?? evaluationModuleId,
                );
            }
        }
        return answer;
    };

    async setSoundClipEditorStore() {
        this.soundClipEditorStore = new SoundClipEditorStore(
            "workflow-review-sound-clip-editor",
            this.audio,
            undefined,
            { mediaUrl: this.mediaUrl },
        );
    }

    private async getClipAudioMetadata() {
        if (this.amdId) {
            try {
                const metadata: AudioMetadataModel =
                    await this.metadataService.getMetadataById(this.amdId);
                this.clipAudioMetadata = metadata;
            } catch (e: any) {
                if (e.message) {
                    this.messageStore.logError(e.message);
                } else {
                    this.messageStore.logError(
                        "Error retrieving audio metadata.",
                    );
                }
            }
        }
    }

    private async audioClipForEval() {
        if (this.amdId) {
            if (
                this.clipAudioMetadata?.directory?.account &&
                this.interactionType === InteractionType.Audio &&
                this.authStore.orgStore.selectedOrganization
            ) {
                const sc = new ClipUIModel(uuidv4());

                const url = `https://${this.clipAudioMetadata.directory.account}.blob.core.windows.net/${this.clipAudioMetadata.blobFileKey}`;
                sc.url = url;
                sc.segmentName = `${this.authStore.orgStore.selectedOrganization.qbAppId}`; // TODO: Double check this
                sc.filePath = url;
                sc.startTime = 0;
                sc.organizationId =
                    this.authStore.orgStore.selectedOrganization.id;
                sc.storageAccount = this.clipAudioMetadata.directory.account;
                sc.directoryId = this.clipAudioMetadata.directory.id;
                sc.fileName = this.clipAudioMetadata.fileName;
                sc.storageAccountUse = StorageAccountUseOptions.Main;

                const redactionEnabled = Boolean(
                    this.authStore.orgStore.selectedOrganization.licensedData.find(
                        (value) =>
                            value.isActive &&
                            value.option?.processingOption ===
                                DataProcessingOptions.Redaction,
                    ),
                );

                this.transcriptionModels =
                    this.clipAudioMetadata.transcriptions;

                if (
                    redactionEnabled &&
                    !!this.amdId &&
                    (this.transcriptionModels?.length ?? 0) > 0
                ) {
                    this.redactedClipDownloader = () =>
                        this.audioFilesService.getRedactedAudioFile(
                            this.amdId!,
                        );
                }

                this.audio = sc;
            }
        }
    }

    private async getTranscriptions() {
        if (this.amdId) {
            try {
                const transcriptions =
                    await this.transcriptionService.getMulitLanguageTranscriptionByAmdId(
                        this.amdId,
                    );
                this.transcriptionData = transcriptions.find(
                    (item) => item.languageCode === "en",
                )?.transcription;
            } catch (e: any) {
                if (e.message) {
                    this.messageStore.logError(e.message);
                } else {
                    this.messageStore.logError(
                        "Error retrieving transcriptions.",
                    );
                }
            }
        }
    }

    private async getChatTranscription() {
        if (this.amdId) {
            try {
                const chats = await this.chatMediaService.getChatMediaFile(
                    this.amdId,
                );
                this.chatTranscription = chats;
            } catch (e: any) {
                if (e.message) {
                    this.messageStore.logError(e.message);
                } else {
                    this.messageStore.logError("Error loading chat records.");
                }
            }
        }
    }

    @action
    resetWorkflowStore() {
        this.currentModule = undefined;
        this.licensedModules = [];
        this.disputeHasStarted = false;
        this.chatTranscription = [];
        this.stepperLevel = undefined;
        this.evalLicensedModules = [];
        this.moduleUIModels = observable.map();
        this.audio = undefined;
        this.clipAudioMetadata = undefined;
        this.transcriptionData = undefined;
        this.eddyEffectP2Data = [];
        this.workflowReviewerInfo = undefined;
        this.currentDynamicModule = undefined;
        this.showAIExplanation = {};
        this.structuredHighlights = new Map();
        this.explanationLoadingStates = {};
        this.agentList = [];
        this.tags = [];
        this.transcriptionModels = undefined;
        this.redactedClipDownloader = undefined;
        this.orgId = undefined;
        this.workflowId = undefined;
        this.user = null;
        this.visibleQuestionIds = [];
    }
}
