import { ButtonBase, Grid, Link, Theme, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import makeStyles from "@mui/styles/makeStyles";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import {
    NotificationArgument,
    NotificationType,
} from "../../../stores/RealTimeMessages/MessageStoreBase";
import hexToRGB from "../../../utils/hexToRGB";
import { defaultTimeFromNowFormatter } from "../../ManagerInteractions/Util";
import AcxChipList from "components/UI/AcxChipList";

const useStyles = makeStyles((theme: Theme) => ({
    icon: (props: Props) => ({
        color:
            props.notification.type === NotificationType.Success
                ? theme.palette.success.main
                : props.notification.type === NotificationType.Error
                ? theme.palette.red.main
                : theme.palette.info.main,

        backgroundColor: theme.palette.white.main,
        marginTop: "6px",
    }),
    messageContainer: {
        // marginTop: theme.spacing(0.25),
        // paddingLeft:theme.spacing(1.25),
        paddingLeft: theme.spacing(0.75),
        paddingTop: theme.spacing(0.5),
    },
    timestampContainer: {
        marginTop: theme.spacing(1.5),
    },

    titleText: (props: Props) => ({
        // fontSize: "14px",
        // color: theme.palette.info.dark,
    }),
    subTitleText: (props: Props) => ({
        fontSize: "12px",
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    }),

    messageText: (props: Props) => ({
        fontSize: "13px",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
    }),

    headerContent: {
        paddingTop: theme.spacing(0.75),
    },
    mainContent: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        // marginTop: theme.spacing(2),
    },
    closeIcon: {
        color: hexToRGB(theme.palette.gray.main, 0.6),
    },
    itemLoader: {
        marginRight: theme.spacing(0.5),
    },

    curLink: {
        color: "#3564D5",
        fontWeight: "bold",
        textAlign: "left",
    },
    rbcContainer: {
        marginLeft: "-2px",
    },
}));

interface OwnProps {
    notification: NotificationArgument;

    onClick?: (arg: NotificationArgument) => void;

    onDismiss?: (arg: NotificationArgument) => void;
}

type Props = OwnProps;

const NotificationItem: React.FC<Props> = observer((props) => {
    const classes = useStyles(props);

    function notificationDismissCallback() {
        props.onDismiss?.(props.notification);
    }

    // function notificationClickHandler() {
    //     props.onClick?.(props.notification);
    // }

    function renderContent(TypeIcon: OverridableComponent<SvgIconTypeMap>) {
        // used for reclassify notification arguments
        const addtlNotifDetails = props.notification.addtlDetails ?? {};
        const formattedNotificationDetails = Object.entries(
            addtlNotifDetails,
        ).map(([key, value]) => {
            if (key === "classifierNames") {
                return (
                    <Grid
                        key={"rclsfy-chips"}
                        item
                        className={classes.rbcContainer}
                    >
                        <AcxChipList
                            size={"small"}
                            color={"blue"}
                            values={
                                props.notification.addtlDetails?.classifierNames
                            }
                            multiToolTip
                        />
                    </Grid>
                );
            } else if (key === "tenantName") {
                return (
                    <Typography key={"rclsfy-org"}>
                        {"Organization: " + value}
                        <br />
                    </Typography>
                );
            } else if (key === "dateReference") {
                const dateRefAndRange = `${value} Range: ${addtlNotifDetails["startDate"]} - ${addtlNotifDetails["endDate"]}`;
                return (
                    <Typography key={"rclsfy-dateRef"}>
                        {dateRefAndRange}
                    </Typography>
                );
            } else {
                return null;
            }
        });

        return (
            <Grid container>
                <Grid
                    container
                    item
                    xs={12}
                    wrap={"nowrap"}
                    alignItems={"center"}
                    key={"notification-item-" + props.notification.id}
                >
                    {props.notification.type ===
                        NotificationType.InProgress && (
                        <Grid item className={classes.itemLoader}>
                            <BeatLoader
                                size={6}
                                loading={
                                    props.notification.type ===
                                    NotificationType.InProgress
                                }
                            />
                        </Grid>
                    )}

                    <Grid item className={classes.headerContent}>
                        <TypeIcon className={classes.icon} fontSize={"small"} />
                    </Grid>
                    <Grid
                        item
                        xs
                        zeroMinWidth
                        style={{ paddingLeft: "8px" }}
                        className={classes.headerContent}
                    >
                        <Typography noWrap className={classes.titleText}>
                            <Link
                                variant="body1"
                                className={clsx(classes.curLink)}
                                component={
                                    props.notification.appPath
                                        ? RouterLink
                                        : "button"
                                }
                                to={`${props.notification.appPath}`}
                                color="textPrimary"
                                style={{
                                    textAlign: "left",
                                    pointerEvents: !props.notification.appPath
                                        ? "none"
                                        : "auto",
                                }}
                            >
                                {props.notification.title}
                            </Link>
                        </Typography>
                        {props.notification.subTitle && (
                            <Typography noWrap className={classes.subTitleText}>
                                {props.notification.subTitle}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        container
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                    >
                        <ButtonBase onClick={notificationDismissCallback}>
                            <CloseIcon
                                className={classes.closeIcon}
                                fontSize={"small"}
                            ></CloseIcon>
                        </ButtonBase>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.messageContainer}
                    zeroMinWidth
                >
                    {Boolean(typeof props.notification.message === "string") ? (
                        <>
                            <Typography
                                display={"block"}
                                noWrap
                                className={classes.messageText}
                            >
                                {props.notification.message}
                                <br />
                            </Typography>
                            {formattedNotificationDetails}
                        </>
                    ) : (
                        <>{props.notification.message}</>
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    justifyContent={"flex-end"}
                    className={classes.timestampContainer}
                >
                    <Typography className={classes.messageText}>
                        {defaultTimeFromNowFormatter(
                            props.notification.timestamp,
                        )}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    switch (props.notification.type) {
        case NotificationType.Error:
            return (
                <div className={classes.mainContent}>
                    {renderContent(ErrorIcon)}
                </div>
            );
        case NotificationType.Success:
            return (
                <div className={classes.mainContent}>
                    {renderContent(CheckCircleIcon)}
                </div>
            );

        default:
        case NotificationType.Info:
            return (
                <div className={classes.mainContent}>
                    {renderContent(InfoIcon)}
                </div>
            );
    }
});

export default NotificationItem;
