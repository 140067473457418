import type {
    ThreadSuggestionAction,
    ThreadSuggestion,
} from "../Models/ThreadSuggestion";

const submitSuggestionContentAction: ThreadSuggestionAction = (
    store,
    suggestion,
) =>
    store.streamingSubmitInput({
        manualInput: suggestion.content,
        suggestion: true,
    });

export const preCannedSuggestions: ThreadSuggestion[] = [
    "What are the top 5 topics in conversations with Eddies?",
    "What was the average call duration in the past month?",
    "What are the main topics in conversations with negative ending sentiment?",
    "What topics are causing an increase in call duration?",
    "What new topics are creating eddies?",
    "How many calls started with a positive sentiment but included an Eddy?",
    "Who has been calling this month?",
    "Who experiences the most Eddies?",
    "How much preventable talk time was there last week?",
    "What is an Eddy?",
    "How does Authenticx reduce risk?",
    "Why is analyzing call data important?"
].map(content => ({ content, action: submitSuggestionContentAction, doNotSendToLlm: true }));

function randomIndex(arr: unknown[]): number {
    return Math.floor(Math.random() * arr.length);
}

export function selectTwoRandomSuggestions(
    options: ThreadSuggestion[],
): ThreadSuggestion[] {
    const firstIdx = randomIndex(options);
    let secondIdx: number;
    do {
        secondIdx = randomIndex(options);
    } while (firstIdx === secondIdx);

    return [options[firstIdx], options[secondIdx]];
}
