import type { PeaksInstance } from "@beacx/peacx";
import Peaks, { Segment, SegmentAddOptions } from "@beacx/peacx";
import theme from "Theme/AppTheme";
import { getAmplitudeScaleFromVolume } from "components/AudioEditor/utils";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { isNil } from "lodash";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import SoundClip, { ClipUIModel } from "models/SoundClip";
import AudioContextProvider from "providers/AudioContextProvider";
import { SoundClipService } from "services/SoundClipService";
import { BaseStore } from "stores/BaseStore";
import RootStore from "stores/RootStore";
import { isUndefinedType } from "utils/TypeGuards";
import { v4 as uuidv4 } from "uuid";
import { PartialSegment, PlayerOptions } from "../SoundClipEditor";
import { delay } from "../../../utils/helpers";
import { getColorFromClipIndex } from "../utils";

export const CreateClipTask = "Create Clip";
export const UpdateClipTask = "Update Clip";
export const DownloadAudioClipTask = "Download Audio Clip";

const initialZoomLevelsArray = [8, 28, 56, 128, 256, 512, 1024, 2048];
const NeverUsedSegmentID = "%!__unused-id-3.14159__!%";
const defaultClipMenuPosition: MenuPosition = {
    mouseX: null,
    mouseY: null,
};

type MenuPosition = {
    mouseX?: number | null;
    mouseY?: number | null;
};
interface PausableSegment extends Segment {
    resumeTime?: number;
}

type OnSegmentCreatedParams = {
    mouseX: any;
    mouseY: any;
    startTime: any;
    endTime: any;
    color: any;
    editable: any;
    id: any;
    labelText: any;
};

type SoundClipEditorOptions = {
    segmentList: PartialSegment[];
    clipBlob: Blob;
    clipBuffer: AudioBuffer;
    visualizerHeight: number;
    minimize: boolean;
    noMenu: boolean;
    generateMp3: boolean;
    throwWarning?: boolean;
    alternateClipDownloader?: () => Promise<ArrayBuffer>;
    onClipLoading: (id: string, isLoading: boolean) => void;
    onClipGenerated: (startTime: number, endTime: number) => void;
    onClipUpdated: (segment: Partial<Peaks.Segment>) => void;
    onAudioBufferReady: ({
        id,
        index,
        audioBuffer,
        blob,
    }: {
        id: string;
        index: number;
        audioBuffer: AudioBuffer;
        blob: Blob;
    }) => void;
    onSegmentMouseOver: (
        id?: string,
        startTime?: number,
        endTime?: number,
    ) => void;
    onSegmentClick: (id?: string | null) => void;
    onVisualizerReady: (isSuccess: boolean) => void;
    mediaUrl?: string;
    maxClipDuration?: number;
    disableClip?: boolean;
    colorClips?: boolean;
};

export default class SoundClipEditorStore extends BaseStore {
    private readonly soundClipService: SoundClipService =
        new SoundClipService();
    private readonly audioContextProvider = new AudioContextProvider();
    private readonly audioCtx = new AudioContext();
    @observable private source: MediaElementAudioSourceNode;
    private gainNodeChannel1: GainNode;
    private gainNodeChannel2: GainNode;
    private tempZoomValues: {
        showZoomView?: boolean;
        zoomIndex?: number;
        playheadTime?: number;
    } = {};

    @observable isMultiChannel = false;
    @observable segmentMenuPosition: MenuPosition = defaultClipMenuPosition;
    @observable latestSegment?: Partial<PausableSegment>;
    @observable isPeaksInit: boolean = false;
    @observable clip?: ClipUIModel;
    @observable playHeadTime: number = 0;
    @observable isPlaying: boolean = false;
    @observable autoExpandSegment: boolean = false;
    @observable throwWarning: boolean = true;
    @observable showZoomView: boolean = false;
    @observable zoomLevelsArray: number[] = initialZoomLevelsArray;
    @observable zoomIndex: number = this.zoomLevelsArray.length - 1;
    @observable playbackRate: number = 1;
    @observable maxClipDurationSeconds: number = 300;
    @observable canvasRect: DOMRect | null = null;
    @observable popoverOpen: boolean = false;
    @observable selectedSegmentId?: string | null = null;
    @observable isDragging: boolean = false;
    @observable disableClip?: boolean = true;
    @observable colorClips?: boolean = false;

    @observable.shallow private initClipList: PartialSegment[] = [];
    @observable.ref private audioElement?: HTMLAudioElement | null;
    @observable.ref private mediaUrl?: string;
    @observable.ref private overviewElement?: HTMLDivElement | null;
    @observable.ref private zoomElement?: HTMLDivElement | null;
    @observable.ref clipAudioBuff?: AudioBuffer;
    @observable.ref private clipBlob?: Blob;
    @observable.ref private visualizerHeight?: number;
    @observable.ref private peaksInstance?: PeaksInstance;
    @observable.ref _playerRef?: HTMLDivElement | null | undefined;

    public setPlayerRef = (instance) => {
        this._playerRef = instance;
    };

    private lastPlayHeadTime: number | undefined = undefined;
    private mouseCoordinates: { clientX: number; clientY: number };
    private playTimerId: NodeJS.Timer | undefined;
    private readonly blobAndBufferProvider?: boolean;
    private readonly minimize?: boolean;
    private readonly noMenu?: boolean;
    private readonly generateMp3?: boolean;

    readonly onClipGenerated?: (startTime: number, endTime: number) => void;
    readonly onClipUpdated?: (soundClip: Partial<Peaks.Segment>) => void;

    private readonly onClipLoading?: (id: string, isLoading: boolean) => void;
    private readonly onWaveformReady?: (isSuccess: boolean) => void;
    private readonly onAudioBufferReady?: ({
        id,
        index,
        audioBuffer,
        blob,
    }: {
        id: string;
        index: number;
        audioBuffer: AudioBuffer;
        blob: Blob;
    }) => void;
    private alternateClipDownloader?: () => Promise<ArrayBuffer>;
    private readonly onSegmentMouseOver?: (
        id?: string,
        startTime?: number,
        endTime?: number,
    ) => void;
    private readonly onSegmentClick?: (id?: string | null) => void;
    private segmentList: PartialSegment[] = [];

    constructor(
        private id: string,
        clip: ClipUIModel | undefined,
        private index: number | undefined,
        options: Partial<SoundClipEditorOptions> = {},
    ) {
        super("SoundClipEditorStore");

        makeObservable(this);

        this.visualizerHeight = options.visualizerHeight;
        this.onClipLoading = options.onClipLoading;
        this.onAudioBufferReady = options.onAudioBufferReady;
        this.onClipGenerated = options.onClipGenerated;
        this.onClipUpdated = options.onClipUpdated;
        this.blobAndBufferProvider =
            Boolean(options.clipBlob) && Boolean(options.clipBuffer);
        this.onWaveformReady = options.onVisualizerReady;
        this.alternateClipDownloader = options.alternateClipDownloader;
        this.onSegmentMouseOver = options.onSegmentMouseOver;
        this.onSegmentClick = options.onSegmentClick;
        this.minimize = options.minimize;
        this.clip = clip;
        this.noMenu = options.noMenu;
        this.generateMp3 = options.generateMp3;
        this.mediaUrl = options.mediaUrl;
        this.maxClipDurationSeconds =
            options.maxClipDuration ?? this.maxClipDurationSeconds;
        this.segmentList = options.segmentList ?? [];
        this.disableClip = options.disableClip;
        this.colorClips = options.colorClips;

        if (options.throwWarning !== undefined) {
            this.throwWarning = options.throwWarning;
        }

        reaction(
            () => this.audioElement,
            () => {
                this.splitAndMergeAudioChannels();
            },
        );

        reaction(
            (r) => ({
                clip: this.clip,
                alternateDownloader: this.alternateClipDownloader,
            }),
            () => {
                if (this.clip) {
                    this.setupAsyncTask(DownloadAudioClipTask, () =>
                        this.downloadAndDecodeAudioClip(
                            this.clip,
                            options.clipBlob,
                            options.clipBuffer,
                        ),
                    );
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => ({
                clipList: [...this.createdClipList],
                isInit: this.isPeaksInit,
            }),
            (arg) => {
                if (arg.isInit) {
                    this.peaksInstance?.segments.removeAll();
                    this.peaksInstance?.points.removeAll();

                    arg.clipList.forEach((value, index) => {
                        const isSelected = this.selectedSegmentId === value.id;
                        if (value.startTime && value.endTime) {
                            this.peaksInstance?.segments.add({
                                endTime: value.endTime,
                                startTime: value.startTime,
                                id: value.id,
                                labelText:
                                    value.labelText ?? `Clip ${index + 1}`,
                                color: isSelected
                                    ? theme.palette.highlight.salmon.main
                                    : theme.palette.red[900],
                                editable: false,
                                backgroundColor: "none",
                            });

                            const startPoint = {
                                id: value.id + "startPoint",
                                time: value.startTime,
                                color: theme.palette.red[900],
                                editable: false,
                            };

                            const endPoint = {
                                id: value.id + "endPoint",
                                time: value.endTime,
                                color: theme.palette.red[900],
                                editable: false,
                            };

                            this.peaksInstance?.points.add(startPoint);
                            this.peaksInstance?.points.add(endPoint);
                        }
                    });
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => ({
                overview: this.overviewElement,
                zoom: this.zoomElement,
                audioelem: this.audioElement,
                audioBlob: this.clipBlob,
                height: this.visualizerHeight,
            }),
            (prereqs) => {
                if (
                    prereqs.audioelem &&
                    prereqs.overview &&
                    prereqs.audioBlob
                ) {
                    if (this.peaksInstance) {
                        this.isPeaksInit = false;
                        this.lastPlayHeadTime = this.playHeadTime;

                        if (this.playTimerId) {
                            clearInterval(this.playTimerId);
                            this.playTimerId = undefined;
                        }

                        this.peaksInstance?.destroy();
                    }

                    if (this.audioElement?.src) {
                        URL.revokeObjectURL(this.audioElement?.src);
                    }

                    prereqs.audioelem.src = URL.createObjectURL(
                        prereqs.audioBlob,
                    );
                    prereqs.audioelem.load();
                    if (prereqs.audioelem.playbackRate !== this.playbackRate) {
                        prereqs.audioelem.playbackRate = this.playbackRate;
                    }
                    this.initializePeaks(initialZoomLevelsArray);
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => ({
                playing: this.isPlaying,
                peaksInit: this.isPeaksInit,
                peaksInstance: this.peaksInstance,
            }),
            (arg) => {
                if (arg.peaksInit && arg.playing && arg.peaksInstance) {
                    this.playTimerId = setInterval(() => {
                        let currTime =
                            arg.peaksInstance?.player.getCurrentTime();

                        if (!isUndefinedType(currTime)) {
                            if (
                                !isUndefinedType(this.lastPlayHeadTime) &&
                                currTime < this.lastPlayHeadTime
                            ) {
                                currTime = this.lastPlayHeadTime;
                                arg.peaksInstance?.player.seek(currTime);
                                this.lastPlayHeadTime = undefined;
                                arg.peaksInstance?.player.play();
                            }

                            if (
                                this.autoExpandSegment &&
                                this.latestSegment &&
                                this.latestSegment.endTime !== undefined
                            ) {
                                if (
                                    currTime >
                                    this.latestSegment.endTime - 3.5
                                ) {
                                    this.latestSegment.endTime +=
                                        currTime -
                                        this.latestSegment.endTime +
                                        3.5;
                                    this.updatePeaksSegment(
                                        this.latestSegment as SegmentAddOptions,
                                        this.latestSegment.endTime,
                                    );
                                }
                            }
                            this.setPlayHeadTime(currTime);
                        }
                    }, 1000);
                } else {
                    if (this.playTimerId) {
                        clearInterval(this.playTimerId);
                        this.playTimerId = undefined;
                    }
                }
            },
        );
    }

    @action
    setAutoExpandSegment = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.autoExpandSegment = event.target.checked;
    };

    @action
    public setClip(clip?: ClipUIModel) {
        this.clip = clip;
    }

    @action
    public handlePlaybackRate = (rate: number) => {
        this.playbackRate = rate;
        if (this.peaksInstance) {
            (
                (this.peaksInstance.player as any)._mediaElement as any
            ).playbackRate = rate;
        }
    };

    public goToEnd = () => {
        this.peaksInstance?.player.seek(
            this.peaksInstance?.player.getDuration() ?? 0,
        );
    };

    public goToBegin = () => {
        this.peaksInstance?.player.seek(0);
    };

    public goBack30 = () => {
        let curTime = this.peaksInstance?.player.getCurrentTime();
        if (!isUndefinedType(curTime)) {
            let newTime = curTime > 30 ? curTime - 30 : 0;
            this.peaksInstance?.player.seek(newTime);
        }
    };

    public goForward30 = () => {
        let curTime = this.peaksInstance?.player.getCurrentTime();
        let newTime = (curTime ?? 0) + 30;
        this.peaksInstance?.player.seek(newTime);
    };

    public changeVolume = (volume: number) => {
        let scale = getAmplitudeScaleFromVolume(volume);

        if (this && this.peaksInstance && this.peaksInstance.views) {
            let v = this.peaksInstance.views.getView("zoomview");

            if (v) {
                v.setAmplitudeScale(scale);
            }
        }

        if (this.audioElement) {
            this.audioElement.volume = volume;
        }
    };

    public changeChannel1Volume = (volume: number) => {
        if (this.gainNodeChannel1) {
            this.gainNodeChannel1.gain.value = volume;
        }
    };

    public changeChannel2Volume = (volume: number) => {
        if (this.gainNodeChannel2) {
            this.gainNodeChannel2.gain.value = volume;
        }
    };

    @action onBlurbPlaySegment = (playerOptions: PlayerOptions) => {
        this.isPlaying = playerOptions.isPlaying;
        if (playerOptions.isPlaying) {
            this.peaksInstance?.player.pause();
            this.peaksInstance?.player.seek(playerOptions.startTime);
            this.peaksInstance?.player.play();
        } else {
            this.peaksInstance?.player.pause();
        }
    };

    @action.bind(this) onPlayingEvent = () => {
        this.isPlaying = true;
    };

    @action.bind(this) onPausingEvent = () => {
        this.isPlaying = false;
    };

    togglePlayerPlay() {
        if (this.isPlaying) {
            this.peaksInstance?.player.pause();
            this.isPlaying = false;
        } else {
            this.peaksInstance?.player.play();
            this.isPlaying = true;
        }
    }

    @action onPlayClick = () => {
        // Resume audio context if needed, then toggle player play/pause
        if (this.audioCtx.state === "suspended") {
            this.audioCtx.resume().then(() => {
                this.togglePlayerPlay();
            });
        } else {
            this.togglePlayerPlay();
        }
    };

    @action
    setPopoverOpen(open: boolean) {
        this.popoverOpen = open;
    }

    @action
    setLatestSegmentStartTime(time?: number) {
        const segment = this.latestSegment;
        const start = segment?.startTime;
        const end = segment?.endTime;
        if (segment && start && end) {
            const existingSegmentIndex = this.createdClipList.findIndex(
                (clip) =>
                    segment.id === clip.id &&
                    ((clip.startTime && Math.abs(start - clip.startTime) < 1) ||
                        (clip.endTime && Math.abs(end - clip.endTime) < 1)),
            );

            if (existingSegmentIndex !== -1) {
                this.initClipList[existingSegmentIndex] = {
                    startTime: time,
                    endTime: segment.endTime,
                    id: segment.id,
                    labelText: segment.labelText,
                    defaultColor: this.getSegmentColorFromId(segment?.id),
                };
            } else {
                const existingSegmentIds = this.createdClipList
                    .map((clip) => clip.id)
                    .filter((id) => !!id);
                const newSegment = this.peaksInstance?.segments
                    .getSegments()
                    .find((seg) => !existingSegmentIds.includes(seg.id));
                if (newSegment) {
                    newSegment.update({ startTime: time });
                }
            }

            this.setLatestSegment({
                endTime: segment.endTime,
                startTime: time,
                id: segment.id,
                editable: segment.editable,
                labelText: segment.labelText,
                color: segment.color,
                mouseX: this.mouseCoordinates?.clientX,
                mouseY: this.mouseCoordinates?.clientY,
            });
        }
    }

    @action
    setLatestSegmentEndTime(time?: number) {
        const segment = this.latestSegment;
        const start = segment?.startTime;
        const end = segment?.endTime;
        if (segment && start && end) {
            const existingSegmentIndex = this.createdClipList.findIndex(
                (clip) =>
                    segment.id === clip.id &&
                    ((clip.startTime && Math.abs(start - clip.startTime) < 1) ||
                        (clip.endTime && Math.abs(end - clip.endTime) < 1)),
            );

            if (existingSegmentIndex !== -1) {
                this.initClipList[existingSegmentIndex] = {
                    startTime: segment.startTime,
                    endTime: time,
                    id: segment.id,
                    labelText: segment.labelText,
                    defaultColor: this.getSegmentColorFromId(segment?.id),
                };
            } else {
                const existingSegmentIds = this.createdClipList
                    .map((clip) => clip.id)
                    .filter((id) => !!id);
                const newSegment = this.peaksInstance?.segments
                    .getSegments()
                    .find((seg) => !existingSegmentIds.includes(seg.id));
                if (newSegment) {
                    newSegment.update({ endTime: time });
                }
            }

            this.setLatestSegment({
                endTime: time,
                startTime: segment.startTime,
                id: segment.id,
                editable: segment.editable,
                labelText: segment.labelText,
                color: segment.color,
                mouseX: this.mouseCoordinates?.clientX,
                mouseY: this.mouseCoordinates?.clientY,
            });
        }
    }

    @action
    setSegmentList(segmentList: PartialSegment[]) {
        this.segmentList = segmentList;
    }

    @action
    refreshSegmentList(
        segmentList?: Array<{
            startTime: number;
            endTime: number;
            id: string;
            labelText?: string;
        }>,
    ) {
        this.initClipList = segmentList ?? this.segmentList ?? [];
    }

    @action
    replaceAudioBuffer(audioBuffer?: AudioBuffer) {
        if (audioBuffer) {
            this.clipAudioBuff = audioBuffer;
            this.clipBlob = this.audioContextProvider.export(audioBuffer).blob;
        }
    }

    @action
    changeVisualizerHeight(height?: number) {
        this.visualizerHeight = height;
    }

    @action dispose = () => {
        this.audioContextProvider.close();
        this.clip = undefined;
        this.clipAudioBuff = undefined;
        this.clipBlob = undefined;
        this.alternateClipDownloader = undefined;

        if (this.audioElement?.src) {
            URL.revokeObjectURL(this.audioElement?.src);
        }
        this.audioElement = null;
        this.zoomElement = null;
        this.overviewElement = null;

        this.peaksInstance?.destroy();
    };

    @action setOverviewElement = (elem: HTMLDivElement | null) => {
        this.overviewElement = elem;
    };
    @action setZoomviewElement = (elem: HTMLDivElement | null) => {
        this.zoomElement = elem;
    };

    @action
    setPlayHeadTime(time: number, updatePeaks?: boolean) {
        this.playHeadTime = time;
        if (updatePeaks) {
            this.peaksInstance?.player.seek(time);
        }
    }

    @action
    setCanvasRect(rect: DOMRect | null) {
        this.canvasRect = rect;
    }

    @action setAudioElement = (audioElement: HTMLAudioElement | null) => {
        this.audioElement = audioElement;

        if (this.audioElement && this.clipBlob) {
            if (this.audioElement.src) {
                URL.revokeObjectURL(this.audioElement.src);
            }

            this.audioElement.src = URL.createObjectURL(this.clipBlob);

            this.audioElement.load();
        }
    };

    @computed
    get segmentMenuAnchorPosition() {
        const top = this.overviewElement?.getBoundingClientRect().top;
        return !!this.segmentMenuPosition.mouseY &&
            !!this.segmentMenuPosition.mouseX
            ? {
                  top: top ?? this.segmentMenuPosition.mouseY,
                  left: this.segmentMenuPosition.mouseX,
              }
            : undefined;
    }

    @action
    setLatestSegment = (event?: OnSegmentCreatedParams) => {
        const segmentEndPosition = {
            mouseX: event?.mouseX,
            mouseY: event?.mouseY,
        };
        if (!event || !Boolean(event?.startTime)) {
            return;
        }

        this.latestSegment = {
            startTime: event?.startTime,
            endTime: event?.endTime,
            color: event?.color,
            editable: event?.editable,
            id: event?.id,
            labelText: event?.labelText,
        };

        if (
            this.throwWarning &&
            this.latestSegment.endTime !== undefined &&
            this.latestSegment.startTime !== undefined &&
            this.latestSegment.endTime - this.latestSegment.startTime > 300
        ) {
            RootStore()
                .getStore(MessageStore)
                .logMessage(
                    `Clip times may not exceed ${this.maxDurationDisplay}.`,
                    "error",
                );
        }

        this.setSegmentMenuPosition(segmentEndPosition);
    };

    @computed
    get maxDurationDisplay() {
        return this.maxClipDurationSeconds > 90
            ? `${Math.floor(this.maxClipDurationSeconds / 60)} minutes${
                  this.maxClipDurationSeconds % 60 !== 0
                      ? ` ${this.maxClipDurationSeconds % 60} seconds`
                      : ""
              }`
            : `${this.maxClipDurationSeconds} seconds`;
    }

    downloadAndDecodeAudioClip = async function (
        this: SoundClipEditorStore,
        clip: ClipUIModel | undefined,
        existingBlob?: Blob,
        existingBuffer?: AudioBuffer,
    ) {
        if (!clip) {
            return;
        }

        await delay(0);
        this.onClipLoading?.(this.id, true);

        try {
            if (this.blobAndBufferProvider) {
                this.clipAudioBuff = existingBuffer;
                this.clipBlob = existingBlob;
            } else {
                let audioBuffer: AudioBuffer | undefined = existingBuffer;
                if (clip.filePath) {
                    if (!audioBuffer) {
                        let arrayBuffer: Promise<ArrayBuffer>;
                        if (!this.mediaUrl) {
                            if (this.alternateClipDownloader) {
                                arrayBuffer = this.alternateClipDownloader();
                            } else {
                                const filePath = clip.filePath;
                                arrayBuffer =
                                    this.soundClipService.downloadAudioClip(
                                        filePath,
                                        clip.storageAccountUse,
                                    );
                            }
                            audioBuffer = await this.audioCtx.decodeAudioData(
                                await arrayBuffer,
                            );
                        } else {
                            const mediaUrlAudio =
                                await this.audioContextProvider.fetchAudio([
                                    this.mediaUrl,
                                ]);
                            audioBuffer = mediaUrlAudio[0];
                        }
                    }
                    this.setIsMultiChannel(audioBuffer.numberOfChannels);
                    const res = this.audioContextProvider.export(audioBuffer!);

                    this.clipBlob = res.blob;
                    this.clipAudioBuff = res.audioBuffer;

                    await delay(0);
                    this.onAudioBufferReady?.({
                        index: this.index ?? 0,
                        audioBuffer: this.clipAudioBuff!,
                        id: this.id,
                        blob: this.clipBlob,
                    });
                }
            }
        } catch (err) {
            console.error(
                `SoundClipEditorStore failed to download clip: ${err}`,
            );
            await delay(0);
            this.onWaveformReady?.(false);

            throw err;
        } finally {
            await delay(0);
            this.onClipLoading?.(this.id, false);
        }
    };

    @action
    setIsMultiChannel(channelCount: number) {
        this.isMultiChannel = channelCount > 1;
    }

    @action
    createClipFromSegmentInternal = async (
        startTime?: number,
        endTime?: number,
    ) => {
        if (!this.clipAudioBuff || !this.latestSegment || !this.audioElement) {
            return;
        }

        let start = startTime ?? this.latestSegment.startTime;
        let end = endTime ?? this.latestSegment.endTime;

        if (start === undefined || end === undefined) {
            return;
        }

        if (start === 0) {
            start = 1;
        }

        if (end === 0) {
            end = 1;
        }

        if (end - start >= 90) {
            this.logLongClipWarning();
        }

        const clip = this.audioContextProvider.sliceBuffer(
            this.clipAudioBuff,
            start * this.clipAudioBuff.sampleRate,
            end * this.clipAudioBuff.sampleRate,
        );

        const existing = this.createdClipList.findIndex(
            (value) =>
                value.startTime?.toFixed(0) === start!.toFixed(0) &&
                value.endTime?.toFixed(0) === end!.toFixed(0),
        );

        if (existing !== -1) {
            console.warn(
                "Cannot create many clips with identical start and end times",
            );
            return;
        }

        try {
            if (this.generateMp3) {
                await new Promise((resolve, reject) => {
                    this.audioContextProvider.exportMP3(
                        clip,
                        async (error, blob) => {
                            if (error) {
                                console.error(
                                    `$SoundClipEditorStore-${this.id} failure: ${error}`,
                                );

                                reject(error);
                            } else if (blob) {
                                this.onClipGenerated?.(start!, end!);
                                resolve(blob);
                                this.latestSegment = undefined;
                                this.autoExpandSegment = false;
                            }
                        },
                    );
                });
            } else {
                this.onClipGenerated?.(start, end);
                this.latestSegment = undefined;
                this.autoExpandSegment = false;
            }
        } finally {
            this.closeSegmentMenu();
        }
    };

    @action
    updateClipFromSegmentInternal = async () => {
        if (!this.clipAudioBuff || !this.latestSegment || !this.audioElement) {
            return;
        }

        let start = this.latestSegment.startTime;
        let end = this.latestSegment.endTime;

        if (start === undefined || end === undefined) {
            return;
        }

        if (start === 0) {
            start = 1;
        }

        if (end === 0) {
            end = 1;
        }

        if (end - start >= 90) {
            this.logLongClipWarning();
        }

        const buffer = this.audioContextProvider.sliceBuffer(
            this.clipAudioBuff,
            start * this.clipAudioBuff.sampleRate,
            end * this.clipAudioBuff.sampleRate,
        );

        const existing = this.createdClipList.findIndex(
            (value) =>
                value.startTime?.toFixed(0) === start!.toFixed(0) &&
                value.endTime?.toFixed(0) === end!.toFixed(0),
        );

        if (existing === -1) {
            console.warn("Cannot find existing clip");
            return;
        }

        const segment = this.createdClipList[existing];
        segment.startTime = this.latestSegment.startTime;
        segment.endTime = this.latestSegment.endTime;

        try {
            if (this.generateMp3) {
                await new Promise((resolve, reject) => {
                    this.audioContextProvider.exportMP3(
                        buffer,
                        async (error, blob) => {
                            if (error) {
                                console.error(
                                    `$SoundClipEditorStore-${this.id} failure: ${error}`,
                                );

                                reject(error);
                            } else if (blob) {
                                this.onClipUpdated?.(segment);
                                resolve(blob);
                                this.latestSegment = undefined;
                                this.autoExpandSegment = false;
                            }
                        },
                    );
                });
            } else {
                this.onClipUpdated?.(segment);
                this.latestSegment = undefined;
                this.autoExpandSegment = false;
            }
        } finally {
            this.closeSegmentMenu();
        }
    };

    @action
    createClipFromSegment = (startTime?: number, endTime?: number) => {
        this.setupAsyncTask(CreateClipTask, () =>
            this.createClipFromSegmentInternal(startTime, endTime),
        );
    };

    @action
    updateLatestClipFromSegment = () => {
        if (!this.canUpdateClips) return;
        this.setupAsyncTask(UpdateClipTask, () =>
            this.updateClipFromSegmentInternal(),
        );
    };

    @computed
    get isSegmentRemovable() {
        if (
            this.latestSegment &&
            !isUndefinedType(this.latestSegment.endTime) &&
            !isUndefinedType(this.latestSegment.startTime)
        ) {
            const removeEnd = this.latestSegment.endTime;
            const removeStart = this.latestSegment.startTime;

            const overLap = this.createdClipList.filter(
                (value) =>
                    (removeStart > (value.startTime ?? 0) &&
                        removeStart < (value.endTime ?? 0)) ||
                    (removeEnd > (value.startTime ?? 0) &&
                        removeEnd < (value.endTime ?? 0)) ||
                    (removeStart < (value.startTime ?? 0) &&
                        removeEnd > (value.endTime ?? 0)),
            );

            return overLap.length === 0;
        }

        return true;
    }

    saveZoomParameters() {
        this.tempZoomValues = {
            showZoomView: this.showZoomView,
            zoomIndex: this.zoomIndex,
            playheadTime: this.playHeadTime,
        };
    }

    restoreZoomView() {
        const { showZoomView } = this.tempZoomValues;
        if (showZoomView) {
            this.setZoomView(showZoomView);
            delete this.tempZoomValues.showZoomView;
        }
    }

    restoreZoomParameters() {
        const { zoomIndex, playheadTime } = this.tempZoomValues;

        if (!isNil(zoomIndex)) {
            this.peaksInstance?.zoom.setZoom(zoomIndex);
            this.zoomIndex = zoomIndex;
            delete this.tempZoomValues.zoomIndex;
        } else {
            this.peaksInstance?.zoom.setZoom(this.zoomLevelsArray.length - 1);
            this.zoomIndex = this.zoomLevelsArray.length - 1;
        }

        if (!isNil(playheadTime)) {
            this.peaksInstance?.player.seek(playheadTime);
            delete this.tempZoomValues.playheadTime;
        }
    }

    @action
    removeSegment = (
        updateUndoHistory: (start: number, end: number, id: string) => void,
        startTime?: number,
        endTime?: number,
    ) => {
        if (this.showZoomView) {
            this.saveZoomParameters();
        }
        this.showZoomView = false;

        this.peaksInstance?.segments.removeById(this.latestSegment?.id!);
        this.closeSegmentMenu();

        if (!this.clipAudioBuff || !this.latestSegment || !this.audioElement) {
            return;
        }

        let start = startTime ?? this.latestSegment.startTime;
        let end = endTime ?? this.latestSegment.endTime;

        if (start === 0) {
            start = 1;
        }

        if (end === 0) {
            end = 1;
        }

        if (start === undefined || end === undefined) {
            return;
        }

        const removeDuration = end - start;
        const removeEnd = end;

        this.createdClipList.forEach((value) => {
            if (removeEnd < value.startTime!) {
                value.startTime = Math.max(
                    value.startTime! - removeDuration,
                    0,
                );
                value.endTime = value.endTime! - removeDuration;
            }
        });

        const left = this.audioContextProvider.sliceBuffer(
            this.clipAudioBuff,
            0 * this.clipAudioBuff.sampleRate,
            start * this.clipAudioBuff.sampleRate,
        );

        const right = this.audioContextProvider.sliceBuffer(
            this.clipAudioBuff,
            end * this.clipAudioBuff.sampleRate,
            this.clipAudioBuff.duration * this.clipAudioBuff.sampleRate,
        );

        const res = this.audioContextProvider.export(
            this.audioContextProvider.concatBuffers([left, right]),
        );

        if (res.audioBuffer.duration < 1.55) {
            return;
        }

        this.playHeadTime = 0;
        this.lastPlayHeadTime = undefined;

        this.clipAudioBuff = res.audioBuffer;
        this.clipBlob = res.blob;

        if (!isUndefinedType(this.index)) {
            this.onAudioBufferReady?.({
                index: this.index,
                audioBuffer: res.audioBuffer,
                id: this.id,
                blob: res.blob,
            });
        }
        if (updateUndoHistory) {
            updateUndoHistory(start, end, this.id);
        }
    };

    @action
    removeUndoSegment = (
        startTime: number,
        endTime: number,
        audioBuffer: AudioBuffer,
    ) => {
        const removeDuration = endTime - startTime;
        const removeEnd = endTime;

        this.createdClipList.forEach((value) => {
            if (removeEnd < value.startTime!) {
                value.startTime = Math.max(
                    value.startTime! - removeDuration,
                    0,
                );
                value.endTime = value.endTime! - removeDuration;
            }
        });

        const left = this.audioContextProvider.sliceBuffer(
            audioBuffer,
            0 * audioBuffer.sampleRate,
            startTime * audioBuffer.sampleRate,
        );

        const right = this.audioContextProvider.sliceBuffer(
            audioBuffer,
            endTime * audioBuffer.sampleRate,
            audioBuffer.duration * audioBuffer.sampleRate,
        );

        const res = this.audioContextProvider.export(
            this.audioContextProvider.concatBuffers([left, right]),
        );

        return res.audioBuffer;
    };

    togglePlaySegment = () => {
        if (this.latestSegment) {
            if (this.isPlaying) {
                this.latestSegment.resumeTime =
                    this.peaksInstance?.player.getCurrentTime();
                this.peaksInstance?.player.pause();
            } else {
                if (this.latestSegment.resumeTime !== undefined) {
                    this.peaksInstance?.player.playSegment({
                        ...(this.latestSegment as Segment),
                        startTime: this.latestSegment.resumeTime,
                    });

                    this.latestSegment.resumeTime = undefined;
                } else {
                    this.peaksInstance?.player.playSegment(
                        this.latestSegment as Segment,
                    );
                }
            }
        }
    };

    @action closeSegmentMenu = () => {
        this.segmentMenuPosition = defaultClipMenuPosition;
    };

    @action setSegmentMenuPosition = (position: MenuPosition) => {
        this.segmentMenuPosition = position;
    };

    trackMouseCoordinates = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        this.mouseCoordinates = {
            clientX: event.clientX,
            clientY: event.clientY,
        };
    };

    @action
    highlightSelectedSegment = () => {
        if (!this.selectedSegmentId) return;
        this.peaksInstance?.segments
            .getSegment(this.selectedSegmentId)
            ?.update({
                color: theme.palette.highlight.salmon.main,
            });
    };

    @action
    removeHighlightFromSelectedSegment = () => {
        if (!this.selectedSegmentId) return;
        this.peaksInstance?.segments
            .getSegment(this.selectedSegmentId)
            ?.update({
                color: theme.palette.primary[800],
            });
    };

    @action
    unsetSelectedSegmentId = () => {
        this.selectedSegmentId = null;
    };

    @action
    onPeaksReady = (error: Error, peaks?: PeaksInstance) => {
        if (!error && peaks) {
            this.peaksInstance = peaks;
            this.isPeaksInit = true;

            let potentialSegment: SegmentAddOptions | undefined = undefined;

            this.createdClipList?.forEach((value, index) => {
                if (value.startTime && value.endTime) {
                    this.peaksInstance?.segments.add({
                        endTime: value.endTime,
                        startTime: value.startTime,
                        id: value.id,
                        labelText: value.labelText ?? `Clip ${index + 1}`,
                        color: theme.palette.red[900],
                        editable: false,
                        backgroundColor: "none",
                    });

                    const startPoint = {
                        id: value.id + "startPoint",
                        time: value.startTime,
                        color: theme.palette.red[900],
                        editable: false,
                    };

                    const endPoint = {
                        id: value.id + "endPoint",
                        time: value.endTime,
                        color: theme.palette.red[900],
                        editable: false,
                    };

                    this.peaksInstance?.points.add(startPoint);
                    this.peaksInstance?.points.add(endPoint);
                }
            });

            this.peaksInstance.views
                .getView("overview")
                ?.enableAutoScroll(true);

            this.peaksInstance.views
                .getView("overview")
                ?.enableMarkerEditing(true);

            this.peaksInstance.views
                .getView("overview")
                ?.setAmplitudeScale(this.minimize ? 1.6 : 1.7);

            const onSegmentsMouseEnter = (segment: Segment) => {
                this.onSegmentMouseOver?.(
                    segment.id,
                    segment.startTime,
                    segment.endTime,
                );
                if (this.isDragging) return;
                if (
                    this.selectedSegmentId &&
                    this.selectedSegmentId !== segment.id
                ) {
                    this.peaksInstance?.segments
                        .getSegment(this.selectedSegmentId)
                        ?.update({
                            color: theme.palette.primary[800],
                            editable: false,
                        });
                }
                if (
                    segment.color !== theme.palette.highlight.salmon.main ||
                    segment.editable !== this.canUpdateClips
                ) {
                    segment.update({
                        color: theme.palette.highlight.salmon.main,
                        editable: this.canUpdateClips,
                    });
                }
                if (
                    this.selectedSegmentId !== segment.id &&
                    this.canUpdateClips
                ) {
                    this.selectedSegmentId = segment.id;
                }
            };

            const onSegmentsMouseLeave = (segment: Segment) => {
                this.onSegmentMouseOver?.();
                if (this.isDragging) return;
                if (
                    segment.color !== this.getSegmentColorFromId(segment?.id) ||
                    segment.editable
                ) {
                    segment.update({
                        color: this.getSegmentColorFromId(segment?.id),
                        editable: false,
                    });
                }
                if (this.selectedSegmentId !== null) {
                    this.selectedSegmentId = null;
                }
            };

            this.peaksInstance.on("player_seek", (time) => {
                this.setPlayHeadTime(time);
            });

            const onSegmentSelect = (segment: Peaks.Segment, inMarker) => {
                const existingSegmentIndex = this.createdClipList.findIndex(
                    (clip) =>
                        segment.id === clip.id &&
                        ((clip.startTime &&
                            Math.abs(segment.startTime - clip.startTime) < 1) ||
                            (clip.endTime &&
                                Math.abs(segment.endTime - clip.endTime) < 1)),
                );

                if (existingSegmentIndex !== -1) {
                    this.initClipList[existingSegmentIndex] = {
                        startTime: segment.startTime,
                        endTime: segment.endTime,
                        id: segment.id,
                        labelText: segment.labelText,
                        defaultColor: this.getSegmentColorFromId(segment?.id),
                    };
                }
                this.setLatestSegment({
                    endTime: segment.endTime,
                    startTime: segment.startTime,
                    id: segment.id,
                    editable: segment.editable,
                    labelText: segment.labelText,
                    color: segment.color,
                    mouseX: this.mouseCoordinates?.clientX,
                    mouseY: this.mouseCoordinates?.clientY,
                });

                if (this.onSegmentClick) {
                    this.onSegmentClick(segment.id);
                }
            };

            const dragEndHandler = (time, modifiers) => {
                if (!potentialSegment) {
                    return;
                }

                let last = this.peaksInstance?.segments.getSegment(
                    potentialSegment.id!,
                );
                if (!last) {
                    return;
                }

                this.setLatestSegment({
                    endTime: last.endTime,
                    startTime: last.startTime,
                    id: last.id,
                    editable: last.editable,
                    labelText: last.labelText,
                    color: last.color,
                    mouseX: this.mouseCoordinates?.clientX,
                    mouseY: this.mouseCoordinates?.clientY,
                });

                potentialSegment = undefined;
                this.isDragging = false;
            };

            const dragStartHandler = (time) => {
                this.refreshSegmentList();
                this.setPopoverOpen(false);
                this.peaksInstance?.segments
                    .getSegments()
                    .filter((segment) => {
                        return (
                            segment.id &&
                            !new Set(
                                this.createdClipList.map((value) => value.id),
                            ).has(segment.id)
                        );
                    })
                    .forEach((value) => {
                        if (value.id) {
                            this.peaksInstance?.segments.removeById(value.id);
                        }
                    });

                const segment = buildSegment(time, time + 0.001);

                if (segment) {
                    potentialSegment = segment;
                }
            };

            const dragMoveHandler = (time) => {
                this.setPlayHeadTime(time);
                this.updatePeaksSegment(potentialSegment, time);
            };

            this.peaksInstance.on("segments.dragend", onSegmentSelect);

            this.peaksInstance.on("segments.mouseenter", onSegmentsMouseEnter);

            this.peaksInstance.on("segments.mouseleave", onSegmentsMouseLeave);

            if (!this.noMenu) {
                this.peaksInstance.on("overview.dragend", dragEndHandler);

                this.peaksInstance.on("zoomview.dragend", dragEndHandler);

                this.peaksInstance.on("overview.dragmove", () => {
                    this.isDragging = true;
                });

                this.peaksInstance.on("overview.dragstart", dragStartHandler);

                this.peaksInstance.on("zoomview.dragstart", dragStartHandler);

                this.peaksInstance.on("overview.dragmove", dragMoveHandler);

                this.peaksInstance.on("zoomview.dragmove", dragMoveHandler);
            }

            this.onWaveformReady?.(true);

            this.restoreZoomView();
        } else {
            RootStore()
                .getStore(MessageStore)
                .logMessage(
                    "There was a problem loading the audio file. Please Refresh.",
                    "error",
                );
            console.error(
                `AudioEditor::PeaksInit failed ${error.name} - ${error.message}`,
            );
            this.peaksInstance = undefined;
            this.isPeaksInit = false;
        }
    };

    @computed
    get popoverX(): number | null {
        if (
            this.latestSegment?.startTime &&
            this.latestSegment.endTime &&
            this.audioElement
        ) {
            if (this.showZoomView && this.zoomElement) {
                const container = this.zoomElement.getBoundingClientRect();
                return container.width / 4 + container.left;
            } else if (!this.showZoomView && this.overviewElement) {
                const container = this.overviewElement.getBoundingClientRect();
                const audioLength = this.audioElement.duration;
                const segmentLeft =
                    (this.latestSegment.startTime / audioLength) *
                        container.width +
                    container.left;
                const segmentRight =
                    (this.latestSegment.endTime / audioLength) *
                        container.width +
                    container.left;
                const segmentWidth = segmentRight - segmentLeft;
                return segmentWidth / 2 + segmentLeft;
            }
        }
        return null;
    }

    private updatePeaksSegment(
        potentialSegment: SegmentAddOptions | undefined,
        time: number,
    ) {
        // time in seconds;
        const existing = this.peaksInstance?.segments.getSegment(
            potentialSegment?.id ?? NeverUsedSegmentID,
        );

        if (potentialSegment && !existing) {
            if (Math.abs(time - potentialSegment.endTime) > 0.11) {
                this.peaksInstance?.segments.add(potentialSegment);
            }
        } else if (existing) {
            const last = existing;

            if (last.endTime < time) {
                if (time !== last.startTime) {
                    last.update({ endTime: time });
                }
            } else if (last.startTime > time) {
                if (time !== last.endTime) {
                    last.update({ startTime: time });
                }
            }
        }
    }

    private timeToPixel(
        currentTime: number,
        elementWidth: number,
        audioDuration: number,
        sampleRate: number,
    ) {
        const pixels_per_sample =
            (elementWidth ?? 0) / ((audioDuration ?? 1) * sampleRate);
        return ((currentTime ?? 0) * pixels_per_sample) / sampleRate;
    }

    private pixelToTime(
        currentPosition: number,
        elementWidth: number,
        audioDuration: number,
        sampleRate: number,
    ) {
        // this could also be defined as peaks.scale option; so keep that in mind
        const samples_per_pixel =
            ((audioDuration ?? 0) * sampleRate) / (elementWidth ?? 1);
        return ((currentPosition ?? 0) * samples_per_pixel) / sampleRate;
    }

    @computed
    get isMaxClipTimeExceeded() {
        if (
            this.latestSegment &&
            this.latestSegment.endTime &&
            this.latestSegment.startTime &&
            this.latestSegment.endTime - this.latestSegment.startTime >=
                this.maxClipDurationSeconds
        ) {
            return true;
        }

        return false;
    }

    @action initializePeaks(zoomLevelsInput: number[]) {
        const options = {
            nudgeIncrement: 0.01,
            keyboard: undefined,
            containers: {
                overview: this.overviewElement as HTMLElement,
                zoomview: this.zoomElement as HTMLElement,
            },
            mediaElement: this.audioElement as HTMLMediaElement,
            webAudio: {
                audioContext: this.audioCtx,
                multiChannel: false,
                scale: 128,
                audioBuffer: this.clipAudioBuff,
            },
            logger: console.error.bind(console),
            zoomAdapter: undefined,
            zoomLevels: zoomLevelsInput,
            randomizeSegmentColor: false,
            playheadColor: theme.palette.highlight.salmon.main,
            playheadWidth: 1,
            playheadTextColor: theme.palette.black.main.toString(),
            waveformBuilderOptions: {
                scale: 128,
                scale_adjuster: 127,
                amplitude_scale: 127,
                disable_worker: true,
            },
            overviewWaveformColor: theme.palette.primary[300],
            zoomWaveformColor: theme.palette.primary[300],
            segmentColor: theme.palette.highlight.salmon.main,
            segmentBackgroundColor: theme.palette.highlight.salmon.light,
            showPlayheadTime: false,
            inMarkerColor: theme.palette.highlight.salmon.main,
            outMarkerColor: theme.palette.highlight.salmon.main,
            editableOverview: true,
            axisGridlineColor: "#CCC",
            axisLabelColor: this.minimize
                ? "transparent"
                : theme.palette.black.main.toString(),
            emitCueEvents: true,
        };

        Peaks.init(options, this.onPeaksReady);
    }

    splitAndMergeAudioChannels() {
        if (this.audioElement) {
            this.source = this.audioCtx.createMediaElementSource(
                this.audioElement,
            );

            const splitter = this.audioCtx.createChannelSplitter(
                this.source.channelCount,
            );
            this.source.connect(splitter);
            const merger = this.audioCtx.createChannelMerger(
                this.source.channelCount,
            );

            for (let i = 0; i < this.source.channelCount; i++) {
                if (i === 0) {
                    this.gainNodeChannel1 = this.audioCtx.createGain();
                    splitter.connect(this.gainNodeChannel1, i);
                    this.gainNodeChannel1.connect(merger, 0, i);
                } else if (i === 1) {
                    this.gainNodeChannel2 = this.audioCtx.createGain();
                    splitter.connect(this.gainNodeChannel2, i);
                    this.gainNodeChannel2.connect(merger, 0, i);
                } else {
                    splitter.connect(merger, 0, i);
                }
            }

            merger.connect(this.audioCtx.destination);
        }
    }

    @action
    zoomIn = () => {
        this.peaksInstance?.zoom.zoomIn();
        this.zoomIndex = this.peaksInstance!.zoom.getZoom();
    };

    @action
    zoomOut = () => {
        this.peaksInstance?.zoom.zoomOut();
        this.zoomIndex = this.peaksInstance!.zoom.getZoom();
    };

    @action
    createSoloZoomview = () => {
        if (this.zoomElement) {
            const clipDuration = this.clipAudioBuff?.duration ?? 0;
            if (clipDuration < 60) {
                let finalZoomLevelIndex = initialZoomLevelsArray.length - 1;
                if (clipDuration < 4) {
                    finalZoomLevelIndex = initialZoomLevelsArray.length - 5;
                } else if (clipDuration < 8) {
                    finalZoomLevelIndex = initialZoomLevelsArray.length - 4;
                } else if (clipDuration < 15) {
                    finalZoomLevelIndex = initialZoomLevelsArray.length - 3;
                } else if (clipDuration < 35) {
                    finalZoomLevelIndex = initialZoomLevelsArray.length - 2;
                }
                const newZoomLevels = initialZoomLevelsArray.slice(
                    0,
                    finalZoomLevelIndex,
                );
                this.zoomLevelsArray = newZoomLevels;
                this.initializePeaks(this.zoomLevelsArray);
            }
            this.peaksInstance?.views.createZoomview(this.zoomElement);

            this.restoreZoomParameters();
        }
    };

    @action
    toggleZoomView = () => {
        this.showZoomView = !this.showZoomView;
    };

    @action
    setZoomView = (show: boolean) => {
        this.showZoomView = show;
    };

    @action
    selectAndEditSoundClip(soundClip: SoundClip) {
        this.selectedSegmentId = soundClip.id;
        this.latestSegment = {
            id: soundClip.id,
            startTime: soundClip.startTime,
            endTime: soundClip.endTime,
            labelText: soundClip.segmentName,
        };

        this.highlightSelectedSegment();

        // snap to beginning of the segment if zoomed
        if (this.showZoomView) {
            this.setPlayHeadTime(soundClip.startTime, true);
        }

        this.setPopoverOpen(true);
    }

    @computed
    get isExistingClip(): boolean {
        return (
            this.createdClipList
                .map((clip) => clip.id)
                .includes(this.latestSegment?.id) &&
            this.latestSegment?.id === this.selectedSegmentId
        );
    }

    @computed
    get canUpdateClips(): boolean {
        return (
            (!!this.onClipUpdated || !!this.isSegmentRemovable) &&
            !this.disableClip
        );
    }

    @computed
    private get createdClipList(): PartialSegment[] {
        return this.initClipList.map((clip, idx) => ({
            ...clip,
            defaultColor: getColorFromClipIndex(idx, !this.colorClips),
        }));
    }

    @computed
    private get segmentIdColorMap(): Map<string, string> {
        const idColorMap = new Map();
        this.createdClipList.forEach((clip) => {
            idColorMap.set(clip.id, clip.defaultColor);
        });
        return idColorMap;
    }

    getSegmentColorFromId(id?: string) {
        const defaultColor = this.segmentIdColorMap.get(id ?? "");
        if (defaultColor) {
            return defaultColor;
        }
        const peacxSegment = this.peaksInstance?.segments.getSegment(id ?? "");
        if (peacxSegment) {
            return theme.palette.warning.dark;
        }
        return theme.palette.primary[800];
    }

    logLongClipWarning() {
        RootStore()
            .getStore(MessageStore)
            .logMessage(
                `Longer clips may take extra time to process, and updating will be unavailable during this time.`,
                "info",
            );
    }
}

function buildSegment(
    time1: number,
    time2: number,
    labelText?: string,
    color?: string,
): SegmentAddOptions | undefined {
    let startTime: number;
    let endTime: number;

    if (time1 < time2) {
        startTime = time1;
        endTime = time2;
    } else if (time2 < time1) {
        startTime = time2;
        endTime = time1;
    } else {
        return undefined;
    }

    const peaksId = `peaks-segment-${uuidv4()}`;

    return {
        id: peaksId,
        startTime: startTime,
        endTime: endTime,
        editable: true,
        labelText: labelText,
        color: color,
    };
}
