import { Grid, Paper, Switch, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SubjectIcon from "@mui/icons-material/Subject";
import {
    LmType,
    Module,
    Question,
} from "components/Admin/Organizations/types/Module.type";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import FocusColumns from "Layouts/Focus/FocusColumns";
import { observer } from "mobx-react";
import { AnswerTypeNames } from "models/AnswerType";
import { InteractionType } from "models/InteractionType";
import {
    QuestionDependencyCondition,
    QuestionDependencyQuestionAnswerOperator,
    QuestionDependencyType,
} from "models/Question";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "shared/hooks/useForm";
import useStyles from "Styles/Styles";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import { useStore } from "utils/useStore";
import ServiceHierarchySelect from "../fields/ServiceHierarchySelect";
import { useAnswerTypes } from "../hooks/useAnswerTypes";
import ModuleService from "../Module.service";
import { lmTypeOptions, renderPlacementOptions } from "../modules-constants";
import { OrganizationModuleDetailsStore } from "./OrganizationModuleDetailsStore";
import { emptyGUID } from "./OrganizationModuleQuestionDependencies";
import OrganizationModuleQuestions from "./OrganizationModuleQuestions";
import { v4 as uuidv4 } from "uuid";
import { useModuleCategories } from "../hooks/useModuleCategories";
import { useQuestionCategories } from "../hooks/useQuestionCategories";

function getAllIdsOfQuestionsThisQuestionIsDependentOn(question: Question) {
    if (!question.dependencies?.conditions?.length) {
        return [];
    }

    const allOtherQuestions: string[] = [];

    function checkDependency(dep: QuestionDependencyCondition) {
        switch (dep.type) {
            case QuestionDependencyType.QuestionAnswer:
                allOtherQuestions.push(dep.dependsOnQuestionId);
                break;
            case QuestionDependencyType.ConditionalGroup:
                if (dep.conditions?.length) {
                    for (const subDep of dep.conditions) {
                        checkDependency(subDep);
                    }
                }
        }
    }
    for (const dep of question.dependencies.conditions) {
        checkDependency(dep);
    }
    return allOtherQuestions;
}

const validateQuestions = (
    questions: Question[],
    path = "",
    module?: Module,
) => {
    let returnObj = (questions || []).reduce((obj, question, index) => {
        if (!question.questionText) {
            obj[`${path}Questions[${index}].QuestionText`] =
                "Question text is required.";
        }
        if (!question.shortQuestionText) {
            obj[`${path}Questions[${index}].ShortQuestionText`] =
                "Short question text is required.";
        }
        if (
            question.answerType.answerTypeName !==
                AnswerTypeNames.QuestionGrouping &&
            !question.answerType.isFillInAnswer &&
            !question.tags?.length
        ) {
            obj[`${path}Questions[${index}].Tags`] =
                "Please specify at least one Tag.";
            question.tags?.forEach((tag, i) => {
                if (!tag.value) {
                    obj[`${path}Questions[${index}].Tags[${i}]`] =
                        "Value is required.";
                }
            });
        }
        if (
            question.answerType.answerTypeName ===
                AnswerTypeNames.QuestionGrouping &&
            !question.questions?.length
        ) {
            obj[`${path}Questions[${index}].Questions`] =
                "Please specify at least one Question.";
        }
        if (question?.questions?.length) {
            const validatedSubquestions = validateQuestions(
                question.questions,
                `${path}Questions[${index}].`,
                module,
            );
            obj = {
                ...obj,
                ...validatedSubquestions,
            };
        }

        if (question?.dependencies?.conditions?.length) {
            const emptyDependsOnId = question?.dependencies?.conditions?.find(
                (_d) => {
                    if (_d.type === QuestionDependencyType.QuestionAnswer) {
                        return _d?.dependsOnQuestionId === emptyGUID;
                    } else {
                        return _d.conditions.find((_subD) => {
                            if (
                                _subD.type ===
                                QuestionDependencyType.QuestionAnswer
                            ) {
                                return _subD.dependsOnQuestionId === emptyGUID;
                            }
                            return false;
                        });
                    }
                },
            );
            if (emptyDependsOnId) {
                obj[`${path}Questions[${index}].Dependencies`] =
                    "Missing required Question field";
            } else if (
                question?.dependencies?.conditions?.find((_d) => {
                    if (_d.type === QuestionDependencyType.QuestionAnswer) {
                        if (
                            _d.op ===
                            QuestionDependencyQuestionAnswerOperator.IsAnswered
                        ) {
                            return false;
                        }
                        return _d?.value === "";
                    } else {
                        return _d.conditions.find((_subD) => {
                            if (
                                _subD.type ===
                                QuestionDependencyType.QuestionAnswer
                            ) {
                                if (
                                    _subD.op ===
                                    QuestionDependencyQuestionAnswerOperator.IsAnswered
                                ) {
                                    return false;
                                }
                                return _subD.value === "";
                            }
                            return false;
                        });
                    }
                })
            ) {
                obj[`${path}Questions[${index}].Dependencies`] =
                    "Missing required Answer field";
            } else {
                const allDependentQuestionIds =
                    getAllIdsOfQuestionsThisQuestionIsDependentOn(question);
                for (const otherQuestion of module!.questions.filter(
                    (q) => q.id && allDependentQuestionIds.includes(q.id),
                )) {
                    const otherDependentQuestionIds =
                        getAllIdsOfQuestionsThisQuestionIsDependentOn(
                            otherQuestion,
                        );
                    if (
                        question.id &&
                        otherDependentQuestionIds.includes(question.id)
                    ) {
                        obj[`${path}Questions[${index}].Dependencies`] =
                            "Circular dependency detected - this question and at least one more question are dependent on each other, which is not valid.";
                    }
                }

                if (question.isActive) {
                    for (const otherQuestion of module!.questions.filter(
                        (q) => q.id && allDependentQuestionIds.includes(q.id),
                    )) {
                        if (!otherQuestion.isActive) {
                            obj[`${path}Questions[${index}].Dependencies`] =
                                "At least one dependency is dependent on another question " +
                                "that is marked as inactive. Please remove that dependency or set this question to inactive.";
                        }
                    }
                }
            }
        }

        return obj;
    }, {});
    const hasErrors = !!Object.keys(returnObj)?.length;
    return hasErrors ? returnObj : false;
};

const styles = (theme: Theme) => {
    return createStyles({
        pageContainer: {
            width: "100%",
        },
        questionTypeIcon: {
            width: theme.typography.pxToRem(18),
            height: theme.typography.pxToRem(18),
            marginRight: theme.typography.pxToRem(4),
        },
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
        clickable: {
            cursor: "pointer",
        },
        error: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.error.main,
            fontSize: "12px",
            lineHeight: "16px",
        },
    });
};

const validation = {
    name: {
        required: {
            message: "Name is required.",
        },
    },
    mediaTypes: {
        function: {
            value: (values) => {
                if (
                    !values["mediaTypes"]?.length &&
                    (values["lmType"] === LmType.Always ||
                        values["lmType"] === LmType.Hierarchy)
                ) {
                    return "At least one Associated Media Type must be selected.";
                }
            },
        },
    },
    lmType: {
        required: {
            message: "LM Type is required.",
        },
    },
    organizationStructureMemberIds: {
        function: {
            value: (values: Module) => {
                const { lmType, organizationStructureMemberIds } = values;
                if (
                    lmType === LmType.Always &&
                    !organizationStructureMemberIds?.length
                ) {
                    return "At least one hierarchy structure member is required.";
                }
                return false;
            },
        },
    },
    questionErrors: {
        function: {
            value: (values: Module) => {
                let returnObj = validateQuestions(
                    values.questions || [],
                    "",
                    values,
                );
                const hasErrors = !!Object.keys(returnObj)?.length;
                return hasErrors ? returnObj : false;
            },
        },
    },
};

type Props = {
    module: Module;
    updateModule: any;
    refetch: any;
    isLoading: boolean;
};

const OrganizationModuleDetailsForm: React.FC<Props> = observer(
    ({ module, updateModule, refetch, isLoading }) => {
        const messageStore = useStore(MessageStore);
        const classes = useStyles(styles);
        const navigate = useNavigate();
        const { organizationId, moduleId } = useParams();
        // const { organizationId, moduleId } = params as {
        //     organizationId: string;
        //     moduleId: string;
        // };

        const store = useStore(OrganizationModuleDetailsStore);

        const {
            value,
            registerSubmit,
            isSubmitting,
            onChange,
            errors,
            initialValue,
            patchValue,
        } = useForm<Module>(module, validation);

        const { answerTypes, isLoading: answerTypesIsLoading } =
            useAnswerTypes();
        const { moduleCategories, isLoading: moduleCategoriesIsLoading } =
            useModuleCategories();
        const { questionCategories, isLoading: questionCategoriesIsLoading } =
            useQuestionCategories();

        const isABunchOfStuffLoading =
            answerTypesIsLoading ||
            moduleCategoriesIsLoading ||
            questionCategoriesIsLoading;

        const [questionsValue, setQuestionsValue] =
            React.useState<Question[]>();

        const [lmType, setLmType] = React.useState<number | undefined>();

        const onChangeQuestions = (val: Question[]) => {
            setQuestionsValue(val);
            if (store) store.setQuestions(val);
        };

        const onChangeLmType = (val: { value: number; label: string }) => {
            setLmType(val.value);
            onChange("lmType", val.value);
            store.setLmType(val.value);
            if (val.value === LmType["Safety Event"]) {
                handleNameChange("Safety Event Identified");

                const q1Id = uuidv4();
                const SEQuestions = [
                    {
                        questionText: "Was a Safety Event acknowledged?",
                        shortQuestionText: "Safety Event",
                        answerType: {
                            answerTypeName: "Scaled Response",
                            variation: "Radio",
                            id: answerTypes!.find(
                                (a) => a.answerTypeName === "Scaled Response",
                            )!.id,
                        },
                        helperText:
                            "The agent provides a verbal acknowledgement of the safety event, in which it is explicitly stated that a safety event report will be completed.",
                        weight: 1,
                        required: true,
                        isActive: true,
                        id: q1Id,
                        tags: [
                            {
                                order: 1,
                                value: "Yes",
                                scoredValue: 1,
                                isActive: true,
                                isNew: true,
                                sentiment: 0,
                            } as any,
                            {
                                order: 2,
                                value: "No",
                                scoredValue: 0,
                                isActive: true,
                                sentiment: 2,
                            } as any,
                            {
                                order: 3,
                                value: "Safety Event Not Present",
                                scoredValue: null,
                                isActive: true,
                                sentiment: 1,
                            } as any,
                        ],
                        isDefault: true,
                        isNew: true,
                        category: "Safety Event",
                    },
                    {
                        questionText: "Select Event Type",
                        shortQuestionText: "Event Type",
                        answerType: {
                            answerTypeName: "Tag Response",
                            variation: "No variation", // this gets deleted anyway in translateQuestionsForServer and replaced with answerType.id
                            id: answerTypes!.find(
                                (a) => a.answerTypeName === "Tag Response",
                            )!.id,
                        },
                        variation: answerTypes!.find(
                            (a) => a.answerTypeName === "Tag Response",
                        ),
                        helperText:
                            "<b>Adverse Event:</b>  Any unfavorable and unintended medical occurrence with the use of a medical product, ranging from mild side effects such as those noted in the product labeling to serious health complications such as death, related hospitalization, and worsening conditions. <br/><br/> <b>Special Situation:</b> Circumstances or conditions that are not covered by AE and PQC definitions but still provide valuable information when assessing the safety of a drug. This can include off-label use, drug abuse and addiction, pregnancy/breastfeeding, occupational exposure, or other similar situations. <br/><br/> <b>Product Quality Complaint:</b> Any written, electronic, or oral communication that alleges potential deficiencies of a drug product or medical device, regardless of whether or not the product was used or administered, after it is released for commercial distribution. This can include physical characteristics, identity, quality, purity, potency, durability, reliability, safety, effectiveness, or performance.",
                        weight: 1,
                        required: true,
                        isActive: true,
                        tags: [
                            {
                                order: 0,
                                value: "Adverse Event",
                                isActive: true,
                                isNew: true,
                                sentiment: 1,
                            } as any,
                            {
                                order: 1,
                                value: "Product Quality Complaint",
                                isActive: true,
                                sentiment: 1,
                            } as any,
                            {
                                order: 2,
                                value: "Special Situation",
                                isActive: true,
                                sentiment: 1,
                            } as any,
                        ],
                        dependencies: {
                            conditions: [
                                {
                                    conditions: [
                                        {
                                            dependsOnQuestionId: q1Id,
                                            op: 1,
                                            value: "Yes",
                                            type: QuestionDependencyType.QuestionAnswer as number,
                                        },
                                        {
                                            dependsOnQuestionId: q1Id,
                                            op: 1,
                                            value: "No",
                                            type: QuestionDependencyType.QuestionAnswer as number,
                                        },
                                    ],
                                    conditionalGroupType: 2,
                                    type: QuestionDependencyType.ConditionalGroup as number,
                                },
                            ],
                        },
                        isDefault: true,
                        isNew: true,
                        id: uuidv4(),
                        category: "Safety Event",
                    },
                ];
                initialValue.questions = SEQuestions;
            }
        };

        React.useEffect(() => {
            if (lmTypeOptions[value?.lmType]) {
                setLmType(value?.lmType);
            }
        }, [value?.lmType]);

        const questionTypes = {
            singleSelect: {
                name: "Single Select",
                icon: (
                    <SubjectIcon
                        className={classes.questionTypeIcon}
                        color="secondary"
                    />
                ),
            },
            tags: {
                name: "Tags",
                icon: (
                    <LocalOfferIcon
                        className={classes.questionTypeIcon}
                        color="secondary"
                    />
                ),
            },
        };

        const mediaTypes = EnumToDropdownOptionConversion(InteractionType);

        const onCancel = () => {
            navigate(`/admin/organizations/${organizationId}/modules`);
        };

        useEffect(() => {
            patchValue({ questions: questionsValue });
        }, [questionsValue, patchValue]);

        const onSave = async () => {
            return updateModule(value);
        };

        const onSuccess = () => {
            if (value.questions?.length > 0) {
                value.questions = value.questions.map((question) => {
                    question.isNew = false;
                    return question;
                });
            }

            setQuestionsValue(value.questions);
            messageStore.logMessage(`Successfully saved module`, "success");
            navigate(`/admin/organizations/${organizationId}/modules`);
        };

        const onFail = () => {
            messageStore.logMessage(
                `Failed to save module, please check fields for errors.`,
                "error",
            );
        };

        const addTagsToDelete = useCallback(
            (id: string | string[]) => {
                if (id) {
                    onChange(
                        "tagsToDelete",
                        (value.tagsToDelete || []).concat(id),
                    );
                }
            },
            [value.tagsToDelete, onChange],
        );

        const renderPlacementOptionsMemo = useMemo(
            () => Object.values(renderPlacementOptions),
            [],
        );

        const defaultRenderPlacement = useMemo(() => {
            return typeof value?.renderPlacement === "number"
                ? renderPlacementOptionsMemo[value.renderPlacement]
                : null;
        }, [value, renderPlacementOptionsMemo]);

        const handleNameChange = (val) => {
            onChange("name", val);
            store.setName(val);
        };

        console.log(value);

        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
            <FocusColumns
                onCancel={onCancel}
                title={moduleId === "add" ? "Add Module" : "Edit Module"}
                showSave={true}
                cancelText="Done"
                saveText={moduleId === "add" ? "Create" : "Save"}
                onSave={registerSubmit(onSave, {
                    onSuccess,
                    onFail,
                })}
                loading={isSubmitting}
                leftSide={
                    <>
                        <Typography variant="h1">
                            Configure Module Questions
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 16,
                                letterSpacing: 0,
                                lineHeight: "24px",
                                marginTop: 16,
                            }}
                        >
                            Create your module questions by giving them a title
                            and selecting a question type. The description will
                            appear as a hover state on the information icon for
                            the analyst during the evaluation.
                        </Typography>
                        <div style={{ marginTop: 16, display: "flex" }}>
                            <div style={{ display: "grid", padding: 6 }}>
                                <ArrowUpwardIcon
                                    className={classes.questionTypeIcon}
                                    color="secondary"
                                />
                                <ArrowDownwardIcon
                                    className={classes.questionTypeIcon}
                                    color="secondary"
                                />
                            </div>
                            <Typography style={{ padding: 6, maxWidth: 300 }}>
                                Move your questions up and down to change the
                                order by clicking on the arrows above the title.
                            </Typography>
                        </div>
                        <div
                            style={{
                                marginTop: 16,
                                display: "flex",
                                marginLeft: -18,
                            }}
                        >
                            <div style={{ padding: 6 }}>
                                <Switch defaultChecked sx={{ width: 40 }} />
                            </div>
                            <Typography style={{ padding: 6, maxWidth: 300 }}>
                                Click the switch next to a question or tag to
                                toggle that item between active and inactive.
                            </Typography>
                        </div>
                        <div style={{ marginTop: 16, display: "flex" }}>
                            <div style={{ padding: 6 }}>
                                <ControlPointDuplicateIcon
                                    className={classes.questionTypeIcon}
                                    color="secondary"
                                />
                            </div>
                            <Typography style={{ padding: 6, maxWidth: 300 }}>
                                Duplicate a question to copy all of its
                                properties.
                            </Typography>
                        </div>
                        <div style={{ marginTop: 16, display: "flex" }}>
                            <div style={{ padding: 6 }}>
                                {questionTypes.singleSelect.icon}
                            </div>
                            <Typography style={{ padding: 6, maxWidth: 300 }}>
                                Selections appear for single select questions.
                                You must have at least two selections and can
                                add up to five total.
                            </Typography>
                        </div>
                        <div style={{ marginTop: 16, display: "flex" }}>
                            <div style={{ padding: 6 }}>
                                {questionTypes.tags.icon}
                            </div>
                            <Typography style={{ padding: 6, maxWidth: 300 }}>
                                For tag questions, choose the tags that an
                                analyst can select. You must have at least two
                                tags for a tag question.
                            </Typography>
                        </div>
                    </>
                }
                leftSize={3}
                rightSide={
                    <div className={classes.pageContainer}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item style={{ width: "100%" }}>
                                <Typography
                                    variant="subtitle1"
                                    style={{ lineHeight: 1 }}
                                >
                                    {value.name ? `${value.name}` : "Module"}
                                </Typography>
                                <Paper
                                    elevation={1}
                                    className={classes.formContainer}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h5"
                                                style={{
                                                    paddingBottom: 24,
                                                }}
                                            >
                                                Detail
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AcxSelectSingle
                                                inputLabel="LM Type"
                                                id={`lmType`}
                                                valueField="value"
                                                labelField="label"
                                                options={Object.values(
                                                    lmTypeOptions,
                                                )}
                                                defaultValue={
                                                    lmTypeOptions[value?.lmType]
                                                }
                                                onChange={(val) =>
                                                    onChangeLmType(val)
                                                }
                                                error={
                                                    !!errors?.fieldErrors
                                                        ?.lmType?.length
                                                }
                                                helperText={
                                                    errors?.fieldErrors?.lmType
                                                }
                                                fullWidth
                                                // need to have answerTypes loaded before allowing LM type selection due to default questions for SE
                                                isDisabled={
                                                    value.lmType ===
                                                        LmType[
                                                            "Safety Event"
                                                        ] ||
                                                    isABunchOfStuffLoading
                                                }
                                                isLoading={
                                                    isABunchOfStuffLoading
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {moduleCategories && (
                                                <AcxSelectSingle
                                                    inputLabel="LM Category"
                                                    id={`lmCategory`}
                                                    valueField="id"
                                                    labelField="name"
                                                    options={moduleCategories}
                                                    onChange={(val) => {
                                                        onChange(
                                                            "moduleCategoryId",
                                                            val.id,
                                                        );
                                                    }}
                                                    defaultValue={moduleCategories.find(
                                                        (mc) =>
                                                            mc.id ===
                                                            value.moduleCategoryId,
                                                    )}
                                                    error={
                                                        !!errors?.fieldErrors
                                                            ?.lmCategory?.length
                                                    }
                                                    helperText={
                                                        errors?.fieldErrors
                                                            ?.lmCategory
                                                    }
                                                    fullWidth
                                                    isDisabled={
                                                        isABunchOfStuffLoading
                                                    }
                                                    isLoading={
                                                        isABunchOfStuffLoading
                                                    }
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AcxMainTextField
                                                value={value.name}
                                                labelText={"Name"}
                                                onBlur={handleNameChange}
                                                id={`name`}
                                                error={
                                                    !!errors?.fieldErrors?.name
                                                        ?.length
                                                }
                                                helperText={
                                                    errors?.fieldErrors?.name
                                                }
                                                required
                                                textareaRootStyles={{
                                                    marginRight: 0,
                                                }}
                                            />
                                        </Grid>

                                        {lmType !== LmType["Safety Event"] && (
                                            <>
                                                <Grid item xs={12}>
                                                    <AcxMainTextField
                                                        value={
                                                            value.description
                                                        }
                                                        labelText={
                                                            "Description"
                                                        }
                                                        onBlur={(val) => {
                                                            onChange(
                                                                "description",
                                                                val,
                                                            );
                                                            store.setDescription(
                                                                val,
                                                            );
                                                        }}
                                                        id={`description`}
                                                        error={
                                                            !!errors
                                                                ?.fieldErrors
                                                                ?.description
                                                                ?.length
                                                        }
                                                        helperText={
                                                            errors?.fieldErrors
                                                                ?.description
                                                        }
                                                        multiline
                                                        rows={3}
                                                        textareaRootStyles={{
                                                            marginRight: 0,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <AcxSelectSingle
                                                        inputLabel="Render Placement"
                                                        id={`renderPlacement`}
                                                        valueField="value"
                                                        labelField="label"
                                                        options={
                                                            renderPlacementOptionsMemo
                                                        }
                                                        defaultValue={
                                                            defaultRenderPlacement
                                                        }
                                                        onChange={(
                                                            val: any,
                                                        ) => {
                                                            onChange(
                                                                "renderPlacement",
                                                                val.value,
                                                            );
                                                            store.setRenderPlacement(
                                                                val.value,
                                                            );
                                                        }}
                                                        error={
                                                            !!errors
                                                                ?.fieldErrors
                                                                ?.renderPlacement
                                                                ?.length
                                                        }
                                                        helperText={
                                                            errors?.fieldErrors
                                                                ?.renderPlacement
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <AcxMainTextField
                                                        value={value.order}
                                                        type="number"
                                                        labelText={"Order"}
                                                        onBlur={(val) => {
                                                            onChange(
                                                                "order",
                                                                val,
                                                            );
                                                            store.setOrder(val);
                                                        }}
                                                        id={`order`}
                                                        error={
                                                            !!errors
                                                                ?.fieldErrors
                                                                ?.order?.length
                                                        }
                                                        helperText={
                                                            errors?.fieldErrors
                                                                ?.order
                                                        }
                                                        textareaRootStyles={{
                                                            marginRight: 0,
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        {lmType !== LmType.Workflow &&
                                            lmType !==
                                                LmType["Safety Event"] && (
                                                <Grid item xs={12}>
                                                    <ServiceHierarchySelect
                                                        orgId={organizationId!}
                                                        value={
                                                            value.organizationStructureMemberIds
                                                        }
                                                        onChange={(val) => {
                                                            onChange(
                                                                "organizationStructureMemberIds",
                                                                val,
                                                            );
                                                            store.setOrganizationStructureMemberIds(
                                                                val,
                                                            );
                                                        }}
                                                        error={
                                                            !!errors
                                                                ?.fieldErrors
                                                                ?.organizationStructureMemberIds
                                                                ?.length
                                                        }
                                                        helperText={
                                                            errors?.fieldErrors
                                                                ?.organizationStructureMemberIds
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}

                                        {lmType !== LmType.Workflow &&
                                            lmType !==
                                                LmType["Safety Event"] && (
                                                <Grid item xs={12}>
                                                    <AcxSelectMulti
                                                        id="module-media-type-selector"
                                                        options={mediaTypes}
                                                        valueField="value"
                                                        labelField="label"
                                                        inputLabel="Associated Media Types"
                                                        required
                                                        showAllErrors={true}
                                                        fullWidth={false}
                                                        containerHeight="auto"
                                                        defaultValue={mediaTypes.filter(
                                                            (mediaType) =>
                                                                value.mediaTypes?.includes(
                                                                    mediaType.value,
                                                                ),
                                                        )}
                                                        onChange={(val) => {
                                                            onChange(
                                                                "mediaTypes",
                                                                val?.map(
                                                                    (
                                                                        option: any,
                                                                    ) =>
                                                                        option.value,
                                                                ),
                                                            );
                                                            store.setMediaTypes(
                                                                val?.map(
                                                                    (
                                                                        option: any,
                                                                    ) =>
                                                                        option.value,
                                                                ),
                                                            );
                                                        }}
                                                        error={
                                                            !!errors
                                                                ?.fieldErrors
                                                                ?.mediaTypes
                                                                ?.length
                                                        }
                                                        helperText={
                                                            errors?.fieldErrors
                                                                ?.mediaTypes
                                                        }
                                                    />
                                                </Grid>
                                            )}

                                        <Grid item xs={12}>
                                            <Switch
                                                checked={module.isActive}
                                                onChange={() =>
                                                    ModuleService.toggleModule(
                                                        module,
                                                    ).then(refetch)
                                                }
                                                disabled={isLoading}
                                                sx={{ minWidth: "40px" }}
                                            />
                                            {module.isActive
                                                ? "This module is active"
                                                : "This module is not active"}
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper
                                    elevation={1}
                                    className={classes.formContainer}
                                >
                                    <OrganizationModuleQuestions
                                        organizationId={organizationId!}
                                        initialQuestions={
                                            initialValue.questions
                                        }
                                        thingsAreLoading={
                                            isABunchOfStuffLoading
                                        }
                                        answerTypes={answerTypes}
                                        questionCategories={questionCategories}
                                        errors={errors?.fieldErrors}
                                        questionErrors={
                                            errors?.fieldErrors?.questionErrors
                                        }
                                        onChange={onChangeQuestions}
                                        addTagsToDelete={addTagsToDelete}
                                    />
                                </Paper>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {errors?.form?.map((err, i) => (
                                            <AcxInputLabel
                                                key={i}
                                                className={classes.error}
                                            >
                                                {err}
                                            </AcxInputLabel>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }
                rightSize={9}
            />
        );
    },
);

export default OrganizationModuleDetailsForm;
