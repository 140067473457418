import moment, { Moment } from "moment";
import {
    DateReferenceOption,
    InteractionDateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";

// serializes to utc
export function serializeToUtc(localDt?: Moment) {
    // toISOString serializes to UTC by default

    const d = localDt?.clone();
    return d?.toISOString();
    // return localDt?.toISOString();
}

export function toIsoByDateReference(
    beginDate: Moment,
    endDate: Moment,
    dateRef: DateReferenceOption,
) {
    return {
        beginDate: serializeToUtc(beginDate),
        endDate: serializeToUtc(endDate),
    };
}

// serializes to iso format but does not apply UTC
export function toIso(dt: Moment) {
    return dt.format("YYYY-MM-DDTHH:mm:ss.sss");
}

export function parseFromISO(stringDateTime: string, isDateOnly?: boolean) {
    // since we call toISOString or UTCNow on all dateTimes saved in DB, we know it comes back in ISO UTC
    return moment.utc(stringDateTime).local(isDateOnly);
}

export function standardDateTimeFormat(
    localDt: Moment,
    formatOverride?: string,
) {
    return localDt.format(formatOverride ?? "YYYY-MM-DD hh:mm A");
}

export function standardDateFormat(localDt: Moment, formatOverride?: string) {
    return localDt.format(formatOverride ?? "YYYY-MM-DD");
}

export function getDateReferenceOptionLabel(dateRef: string): string {
    switch (dateRef) {
        case DateReferenceOption.ArrivalDate:
            return "Arrival Date";
        case DateReferenceOption.InteractionDate:
            return "Interaction Date";
        case DateReferenceOption.EvaluationCompleteDate:
            return "Evaluation Complete Date";
        case InteractionDateReferenceOption.EvaluationModifiedDate:
            return "Evaluation Modified Date";
        case DateReferenceOption.EvaluationStartDate:
            return "Evaluation Start Date";
        case DateReferenceOption.CreatedOnDate:
            return "Created On Date";
        default:
            return dateRef;
    }
}

export function getFirstDayOfQuarter(quarterString: string) {
    const match = quarterString.match(/^Q([1-4]) (\d{4})$/);

    if (!match) {
        throw new Error("Invalid quarter string format");
    }

    const quarter = parseInt(match[1], 10);
    const year = parseInt(match[2], 10);

    if (quarter < 1 || quarter > 4) {
        throw new Error("Invalid quarter number");
    }

    const firstDay = moment({
        year,
        month: (quarter - 1) * 3,
        day: 1,
    });

    return firstDay;
}
