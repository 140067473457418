import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { BaseStore } from "../../../stores/BaseStore";
import SignalsReportStore from "./SignalsReportStore";
import { AudioFileSamplerStore } from "components/Admin/AdminUIComponents/AudioFileSampler/Stores/AudioFileSamplerStore";
import { RecommendationStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/RecommendationStepStore";
import { SourceFilesStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/SourceFilesStepStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    GridPaginationModel,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid-pro";
import theme from "Theme/AppTheme";
import AcxChip from "components/UI/AcxChip";
import { durationColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DurationColType";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { EvalType } from "models/Evaluation";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "components/Navigation/Routes";
import { AuthStore } from "stores/AuthStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import {
    getFirstDayOfQuarter,
    serializeToUtc,
    toIsoByDateReference,
} from "utils/DateTimeUtils";
import * as SignalsModels from "../Models/SignalsModels";
import { Topics } from "components/Conversations/Stores/ConversationsStore";
import AcxFormatters from "components/UI/AcxDataGrid/Formatters/AcxFormatters";
import { v4 as uuidv4 } from "uuid";
import AcxProgress from "components/UI/AcxDataGrid/Formatters/AcxProgress";
import AcxButton from "components/UI/AcxButton";
import { Observer } from "mobx-react";

@AcxStore
class SignalsDrilldownStore extends BaseStore {
    readonly signalsReportStore: SignalsReportStore;
    readonly authStore: AuthStore;
    readonly applicationFiltersStore: ApplicationFiltersStore;

    @observable
    showSelectedDataDialog: boolean = false;

    @observable
    selectedDataDgStore: AcxDataGridStore = new AcxDataGridStore(
        "SignalsDrilldown",
        "Signals",
    );

    @observable
    drilldownDialogTitle: React.ReactNode = "";

    @observable
    currentOnSelectPayload: {
        series: string | null;
        x: string | number | null;
        y: any;
        chartId?: string;
    };

    @observable
    drilldownSubtitle?: string = "";

    @observable
    drilldownSql?: string;

    @observable
    isTopics?: boolean = false;

    private abortController = new AbortController();

    sourceFilesStepStore: SourceFilesStepStore;
    audioFileSamplerStore: AudioFileSamplerStore;
    recommendationStepStore: RecommendationStepStore;

    conversationColumns: IColDef[] = [];

    topicsColumns: IColDef[] = [];

    public constructor(rootStore: IRootStore) {
        super("Signals Store");
        makeObservable(this);

        this.sourceFilesStepStore = rootStore.getStore(SourceFilesStepStore);
        this.audioFileSamplerStore = rootStore.getStore(AudioFileSamplerStore);
        this.recommendationStepStore = rootStore.getStore(
            RecommendationStepStore,
        );
        this.authStore = rootStore.getStore(AuthStore);
        this.applicationFiltersStore = rootStore.getStore(
            ApplicationFiltersStore,
        );
        this.signalsReportStore = rootStore.getStore(SignalsReportStore);

        this.conversationColumns = [
            {
                headerName: "Conversation",
                field: "audioMetadataId",
                filterable: false,
                sortable: false,
                width: 135,
                renderCell: (params: GridRenderCellParams) => {
                    const evalId = params.row.audioMetadataId;

                    return (
                        <Link
                            to={Routes.makeConversationsRoute(
                                undefined,
                                evalId ?? "",
                            )}
                        >
                            View Conversation
                        </Link>
                    );
                },
            },
            {
                field: "blobUrl",
                headerName: " ",
                hideSortIcons: true,
                filterable: false,
                width: 50,
                sortable: false,
                resizable: false,
                renderCell: new AcxFormatters().playerFormatter,
            },
            {
                headerName: this.applicationFiltersStore.dateReferenceOption,
                field: "timestamp",
                filterable: false,
                sortable: false,
                align: "center",
                renderCell: (params) => {
                    const text = params.row.timestamp
                        ? params.row.timestamp
                        : params.row.arrivedOn;
                    return (
                        <Grid container>
                            {moment(text).format("MMM DD, YYYY ")}
                        </Grid>
                    );
                },
            },
            {
                headerName: "Agent Name",
                field: "agentFullName",
                type: "string",
                width: 120,
                filterable: false,
                sortable: false,
            },
            {
                headerName: "Duration",
                field: "callDurationMillis",
                width: 85,
                filterable: false,
                sortable: false,
                ...durationColumnType,
            },
            {
                headerName: "Evaluation",
                field: "evalType",
                renderCell: (params) => {
                    if (params.value) {
                        const classes = makeStyles(() => ({
                            link: {
                                textAlign: "left",
                                color: theme.palette.neutral[600],
                                textDecoration: "underline",
                                textUnderlineOffset: theme.spacing(0.5),
                                "&:hover": {
                                    fontWeight: "bolder",
                                },
                            },
                        }))();

                        return (
                            <Link
                                to={Routes.makeEvaluationRoute(
                                    this.authStore.orgStore
                                        .selectedOrganization!.id,
                                    params.row.evaluationId,
                                )}
                                target="_blank"
                                className={classes.link}
                            >
                                {params.value === EvalType[1] ? "AI" : "Human"}
                            </Link>
                        );
                    }
                },
                filterable: false,
                sortable: false,
                resizable: false,
            },
            {
                headerName: "Topics",
                field: "topics",
                renderCell: (params) => {
                    const topicData: { label: string; description: string }[] =
                        [];
                    for (const key in params.value as any) {
                        if (
                            params.value &&
                            (key === "topic1" ||
                                key === "topic2" ||
                                key === "topic3")
                        ) {
                            topicData.push({
                                label: params.value[key],
                                description: params.value[key + "Reasoning"],
                            });
                        }
                    }

                    return (
                        <Grid
                            container
                            wrap="nowrap"
                            alignItems={"center"}
                            gap={1}
                        >
                            {topicData.map((data, index) => {
                                if (data.label && data.description) {
                                    return (
                                        <Grid item key={index}>
                                            <AcxChip
                                                size="small"
                                                label={data.label}
                                                color="gray"
                                                customTooltip={data.description}
                                            />
                                        </Grid>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Grid>
                    );
                },
                filterable: false,
                sortable: false,
                width: 300,
            },

            {
                field: "hierarchy",
                headerName: "Service Hierarchy",
                filterable: false,
                sortable: false,
                width: 200,
            },

            {
                headerName: "File Path",
                field: "fileName",
                filterable: false,
                sortable: false,
                width: 150,
            },
        ];

        this.topicsColumns = [
            {
                headerName: "Topics",
                field: "name",
                type: "string",
                flex: 6,
                filterable: false,
            },
            {
                headerName: "Percent",
                field: "percent",
                renderCell: (params) => {
                    return (
                        <Grid
                            container
                            item
                            xs={12}
                            wrap="nowrap"
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <Grid item xs={10}>
                                <AcxProgress
                                    key={params.id}
                                    percentComplete={params.value?.toString()}
                                    backgroundColor={theme.palette.primary.main}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                {params.value}%
                            </Grid>
                        </Grid>
                    );
                },
                filterable: false,
                flex: 1,
            },

            {
                headerName: "Volume",
                field: "volume",
                filterable: false,
                flex: 1,
            },
        ];

        this.selectedDataDgStore.removeHeight = "75px";
        this.selectedDataDgStore.hideVertIcon = true;
        this.selectedDataDgStore.hideHeader = true;

        this.selectedDataDgStore.paginationMode = "server";
        this.selectedDataDgStore.filterMode = "server";
        this.selectedDataDgStore.sortingMode = "server";
        this.selectedDataDgStore.hideFilter = true;

        this.selectedDataDgStore.pagination = true;
        this.selectedDataDgStore.pageSizeOptions = [10];
        this.selectedDataDgStore.paginationSize = 10;
        this.selectedDataDgStore.disableLocalConfig = true;

        this.selectedDataDgStore.isRowSelectable = (param: GridRowParams) => {
            const isRowReadyToSample = param.row.isReadyToSample;
            const selectedRowCount =
                this.selectedDataDgStore.selectedRows.length;
            const isSelected = this.selectedDataDgStore.selectedRowIds.includes(
                param.row.id,
            );
            return isRowReadyToSample && (selectedRowCount < 10 || isSelected);
        };

        reaction(
            () => this.showSelectedDataDialog,
            () => {
                this.initializeSampler();
            },
        );
    }

    onSelect = async (
        series: string | null,
        x: string | number | null,
        y: any,
        chartId?: string,
        isTopics?: boolean,
    ) => {
        const chartDataModel = this.signalsReportStore.renderedChartData.find(
            (cd) => cd.id === chartId,
        );
        if (!chartDataModel || !chartId) return;

        this.showSelectedDataDialog = true;
        this.selectedDataDgStore.clearSelected();
        this.selectedDataDgStore.rows = [];
        let titleText: any = "";

        if (
            chartDataModel.dataEndpoint === SignalsModels.TopicPrevalenceID ||
            chartDataModel.dataEndpoint === SignalsModels.TopicPrevalenceAndEEID
        ) {
            titleText = x;
        } else if (
            chartDataModel.dataEndpoint ===
            SignalsModels.TopicPrevalenceOverTimeID
        ) {
            titleText = series;
        }

        this.setDrillEddyDialogTitle(chartId as string, !!isTopics, titleText);

        if (!isTopics && chartDataModel.selectionType !== "Topics") {
            this.drilldownSubtitle = this.getDrilldownSubtitle(
                chartId ?? "",
                x,
                series,
            );
        } else {
            this.drilldownSubtitle = "";
        }

        this.currentOnSelectPayload = {
            series,
            x,
            y,
            chartId,
        };

        const getDrilldownDateRange = (
            startDateX,
            tempGrain: SignalsModels.SignalsTemporalLabels,
        ) => {
            const startDateCopy = moment(
                this.applicationFiltersStore.quickFiltersStore.startDate,
            );
            const endDateCopy = moment(
                this.applicationFiltersStore.quickFiltersStore.endDate,
            );

            let firstDay =
                moment(startDateX) < startDateCopy
                    ? startDateCopy
                    : moment(startDateX);
            let lastDay = moment();

            switch (tempGrain) {
                case "Daily":
                    lastDay = moment(firstDay).endOf("d");
                    break;
                case "Weekly":
                    const lastDayW = moment(startDateX).add(7, "days");
                    lastDay = lastDayW > endDateCopy ? endDateCopy : lastDayW;
                    break;
                case "Monthly":
                    const lastDayM = moment(startDateX).add(1, "month");
                    lastDay = lastDayM > endDateCopy ? endDateCopy : lastDayM;
                    break;
                case "Quarterly":
                    firstDay =
                        getFirstDayOfQuarter(startDateX) < startDateCopy
                            ? startDateCopy
                            : getFirstDayOfQuarter(startDateX);

                    const lastDayQ = getFirstDayOfQuarter(startDateX).add(
                        1,
                        "quarter",
                    );

                    lastDay = lastDayQ > endDateCopy ? endDateCopy : lastDayQ;
                    break;
            }
            return toIsoByDateReference(
                firstDay,
                lastDay,
                this.applicationFiltersStore.dateReferenceOption,
            );
        };

        // copy toJS to avoid updating the observable
        const requestState = toJS(
            this.signalsReportStore.chartStateByChartId[chartId as string],
        );

        return await this.setupAsyncTask(
            "Load Signals Drill Down",
            async () => {
                if (requestState) {
                    this.selectedDataDgStore.setLoading(true);

                    if (chartDataModel?.isTemporal) {
                        const dateRange = getDrilldownDateRange(
                            x,
                            this.signalsReportStore.aggregationLabel,
                        );
                        requestState.startDate = dateRange.beginDate!;
                        requestState.endDate = dateRange.endDate!;
                    }

                    if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.EEByClassifierID
                    ) {
                        const selectedClassifierId =
                            this.signalsReportStore.userClassifiers.find(
                                (c) => c.name === x,
                            )?.id;

                        requestState.classifierId = selectedClassifierId;
                        delete requestState.classifierIds;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.EEByClassifiersOverTimeID
                    ) {
                        const selectedClassifierId =
                            this.signalsReportStore.userClassifiers.find(
                                (c) => c.name === series,
                            )?.id;

                        requestState.classifierId = selectedClassifierId;
                        delete requestState.classifierIds;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.EEPrevalenceByQuestionID
                    ) {
                        const selectedTagId =
                            this.signalsReportStore.selectedQuestionByChartId[
                                chartId
                            ]?.tags?.find((t) => t.value === x)?.id;

                        requestState.tagId = selectedTagId;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.EEPrevalenceByQuestionOverTimeID
                    ) {
                        const selectedTagId =
                            this.signalsReportStore.selectedQuestionByChartId[
                                chartId
                            ]?.tags?.find((t) => t.value === series)?.id;

                        requestState.tagId = selectedTagId;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.AverageScoreByQuestionID
                    ) {
                        const selectedModuleId =
                            this.signalsReportStore.selectedModuleIdByChartId[
                                chartId
                            ];

                        const selectedQuestionId =
                            this.signalsReportStore.orgModules
                                .find((m) => m.id === selectedModuleId)
                                ?.questions.find(
                                    (q) => q.shortQuestionText === x,
                                )?.id;

                        requestState.questionId = selectedQuestionId;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.AverageScoreByQuestionAndEddyOverTimeID
                    ) {
                        requestState.questionId =
                            this.signalsReportStore.selectedQuestionByChartId[
                                chartId
                            ]?.id;
                        requestState.eddyIdentified =
                            series === "Eddy Identified";
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.ClassifierAndEddyID
                    ) {
                        const selectedClassifierId =
                            this.signalsReportStore.userClassifiers.find(
                                (c) => c.name === x,
                            )?.id;

                        requestState.classifierId = selectedClassifierId;
                        delete requestState.classifierIds;
                        requestState.eddyIdentified =
                            series === "Eddy Identified";
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.ClassifierOverTimeId
                    ) {
                        const selectedClassifierId =
                            this.signalsReportStore.userClassifiers.find(
                                (c) => c.name === series,
                            )?.id;

                        requestState.classifierId = selectedClassifierId;
                        delete requestState.classifierIds;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.HipaaCompliancePrevalenceOverTimeId
                    ) {
                        requestState.hipaaValue = series;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.SEPrevalenceOverTimeID
                    ) {
                        requestState.safetyEvent =
                            SignalsModels.getSafetyEventTypeEnumIndex(series);
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.AverageScoreByModuleOverTimeID
                    ) {
                        requestState.moduleId =
                            this.signalsReportStore.orgScoredModules.find(
                                (module) => module.displayName === series,
                            )?.id;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.AverageScoreByQuestionOverTimeID
                    ) {
                        const selectedModuleId =
                            this.signalsReportStore.selectedModuleIdByChartId[
                                chartId
                            ];

                        const selectedQuestionId =
                            this.signalsReportStore.orgModules
                                .find((m) => m.id === selectedModuleId)
                                ?.questions.find(
                                    (q) => q.shortQuestionText === series,
                                )?.id;

                        requestState.questionId = selectedQuestionId;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.TopicPrevalenceID
                    ) {
                        const selectedTopicId =
                            this.signalsReportStore.topics.find(
                                (t) => t.topicLabel === x,
                            )?.id;

                        requestState.topicId = selectedTopicId;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.TopicPrevalenceOverTimeID
                    ) {
                        const selectedTopicId =
                            this.signalsReportStore.topics.find(
                                (t) => t.topicLabel === series,
                            )?.id;

                        requestState.topicId = selectedTopicId;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.TopicPrevalenceAndEEID
                    ) {
                        const selectedTopicId =
                            this.signalsReportStore.topics.find(
                                (t) => t.topicLabel === x,
                            )?.id;

                        requestState.topicId = selectedTopicId;
                        requestState.eddyIdentified =
                            series === "Eddy Identified";
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.ContactTypePrevalenceId
                    ) {
                        requestState.contactType = x;
                    } else if (
                        chartDataModel.dataEndpoint ===
                            SignalsModels.ContactTypePrevalenceOverTimeId ||
                        chartDataModel.dataEndpoint ===
                            SignalsModels.EddyByContactTypeOverTimeId
                    ) {
                        requestState.contactType = series;
                    } else if (
                        chartDataModel.dataEndpoint ===
                        SignalsModels.ContactTypePrevalenceAndEEId
                    ) {
                        requestState.contactType = x;
                        requestState.eddyIdentified =
                            series === "Eddy Identified";
                    }

                    // handle compare prev drilldown
                    if (requestState.previousPeriodStartDate) {
                        const clickedSeriesLastDay = moment(
                            series?.split("-")[1],
                        ).startOf("date");

                        if (
                            clickedSeriesLastDay <
                            moment(requestState.startDate).endOf("date")
                        ) {
                            // clicked last day is before selected first day, so we use comparePrev dates
                            requestState.startDate = serializeToUtc(
                                moment(requestState.previousPeriodStartDate),
                            )!;
                            requestState.endDate = serializeToUtc(
                                moment(requestState.previousPeriodEndDate),
                            )!;
                        }
                    }

                    requestState.pageNumber =
                        this.selectedDataDgStore.paginationModel!.page + 1;
                    requestState.pageSize =
                        this.selectedDataDgStore.paginationModel?.pageSize;

                    this.isTopics = !!isTopics;

                    if (isTopics) {
                        this.selectedDataDgStore.checkboxSelection = false;
                        this.selectedDataDgStore.setColumns(this.topicsColumns);
                        const res =
                            await this.signalsReportStore.signalsService.getTopicDrillDownForChart(
                                chartDataModel?.dataEndpoint,
                                requestState,
                                this.abortController.signal,
                            );

                        runInAction(() => {
                            this.handleTopicDrilldownResponse(res);
                        });
                    } else {
                        this.selectedDataDgStore.checkboxSelection = true;
                        this.selectedDataDgStore.setColumns(
                            this.conversationColumns,
                        );
                        this.selectedDataDgStore.columns.forEach((column) => {
                            if (column.field === "timestamp") {
                                column.headerName =
                                    this.applicationFiltersStore.quickFiltersStore.datePickerStore!.dateRangeFilterLabel;
                            }
                        });
                        const res =
                            await this.signalsReportStore.signalsService.getDrillDownForChart(
                                chartDataModel?.dataEndpoint,
                                requestState,
                                this.abortController.signal,
                            );

                        runInAction(() => {
                            this.handleDrilldownResponse(res);
                        });
                    }
                }
            },
        );
    };

    @action
    setDrillEddyDialogTitle = (
        chartId: string,
        isTopics: boolean,
        titleText: string | number | null,
    ) => {
        const chartModel = this.signalsReportStore.renderedChartData.find(
            (cd) => cd.id === chartId,
        );

        if (isTopics) {
            this.drilldownDialogTitle = titleText;
        } else {
            if (chartModel?.selectionType === "Topics") {
                this.drilldownDialogTitle = (
                    <Observer>
                        {() => (
                            <Grid container item alignItems={"center"} gap={1}>
                                <Grid item>Conversations for </Grid>
                                <Grid item>
                                    <AcxButton
                                        onClick={() => {
                                            this.selectedDataDgStore.paginationModel!.page = 0;
                                            this.onSelect(
                                                this.currentOnSelectPayload
                                                    .series,
                                                this.currentOnSelectPayload.x,
                                                this.currentOnSelectPayload.y,
                                                this.currentOnSelectPayload
                                                    .chartId,
                                                true,
                                            );
                                        }}
                                        loading={this.getTaskLoading(
                                            "Load Signals Drill Down",
                                        )}
                                        id={"signals-theme-drilldown-button"}
                                        color={"inherit"}
                                    >
                                        {titleText}
                                    </AcxButton>
                                </Grid>
                            </Grid>
                        )}
                    </Observer>
                );
            } else {
                this.drilldownDialogTitle = chartModel?.chartTitle ?? "";
            }
        }
    };

    getDrilldownSubtitle = (
        chartId: string,
        x: string | number | null,
        series: string | null,
    ) => {
        const chartDataModel = this.signalsReportStore.renderedChartData.find(
            (cd) => cd.id === chartId,
        );

        const seriesText = series ? " and " + series : "";
        let text = chartDataModel?.isTemporal
            ? this.signalsReportStore.getXDateRangeLabel(
                  x,
                  this.signalsReportStore.aggregationLabel,
              ) + seriesText
            : x + seriesText;
        return "Data for " + text;
    };

    handleDrilldownResponse = (
        res: SignalsModels.ISignalsDrilldownResponse,
    ) => {
        this.drilldownSql = res.sqlQuery;

        this.selectedDataDgStore.onPaginationModelChange = (
            model: GridPaginationModel,
        ) => {
            this.selectedDataDgStore.paginationModel = model;

            this.onSelect(
                this.currentOnSelectPayload?.series ?? null,
                this.currentOnSelectPayload.x,
                this.currentOnSelectPayload.y,
                this.currentOnSelectPayload.chartId,
            );
        };

        this.selectedDataDgStore.rows = res.data.map((item) => ({
            ...item,
            id: item.audioMetadataId,
            topics: this.getTopicsFromClassifiers(item.classifiers),
            agentFullName:
                !item.agentFirstName && !item.agentLastName
                    ? "Unknown Agent"
                    : item.agentFirstName + " " + item.agentLastName,
        }));
        this.selectedDataDgStore.rowCount = res.totalCount;
        this.selectedDataDgStore.paginationSize = res.pageSize;

        this.selectedDataDgStore.setLoading(false);
    };

    handleTopicDrilldownResponse = (
        res: SignalsModels.ISignalsTopicsDrilldownResponse,
    ) => {
        this.selectedDataDgStore.onPaginationModelChange = (
            model: GridPaginationModel,
        ) => {
            this.selectedDataDgStore.paginationModel = model;

            this.onSelect(
                this.currentOnSelectPayload?.series ?? null,
                this.currentOnSelectPayload.x,
                this.currentOnSelectPayload.y,
                this.currentOnSelectPayload.chartId,
                true,
            );
        };

        this.selectedDataDgStore.rows = res.data.map((item) => ({
            ...item,
            id: uuidv4(),
        }));
        this.selectedDataDgStore.rowCount = res.totalCount;
        this.selectedDataDgStore.paginationSize = res.pageSize;
        this.drilldownSubtitle = res.totalCount + " Topics";

        this.selectedDataDgStore.setLoading(false);
    };

    getTopicsFromClassifiers(
        classifiersFromDrilldown: SignalsModels.IClassifiersFromDrilldown[],
    ): Topics | null {
        const topicsClassifier:
            | SignalsModels.IClassifiersFromDrilldown
            | undefined = classifiersFromDrilldown.find(
            (classifier: SignalsModels.IClassifiersFromDrilldown) =>
                classifier.result &&
                classifier.classifierName === "ML-Topic-LLM",
        );

        if (topicsClassifier) {
            const result = JSON.parse(topicsClassifier.result);
            const topics: Topics = {
                topic1:
                    result["Topic 1"] && result["Topic 1"] !== "NA"
                        ? result["Topic 1"]
                        : null,
                topic2:
                    result["Topic 2"] && result["Topic 2"] !== "NA"
                        ? result["Topic 2"]
                        : null,
                topic3:
                    result["Topic 3"] && result["Topic 3"] !== "NA"
                        ? result["Topic 3"]
                        : null,
                topic1Reasoning:
                    (result["Topic 1"] &&
                        result["Topic 1 Reasoning"] &&
                        result["Topic 1"]) !== "NA"
                        ? result["Topic 1 Reasoning"]
                        : null,
                topic2Reasoning:
                    (result["Topic 2"] &&
                        result["Topic 2 Reasoning"] &&
                        result["Topic 2"]) !== "NA"
                        ? result["Topic 2 Reasoning"]
                        : null,
                topic3Reasoning:
                    (result["Topic 3"] &&
                        result["Topic 3 Reasoning"] &&
                        result["Topic 3"]) !== "NA"
                        ? result["Topic 3 Reasoning"]
                        : null,
            };
            return topics;
        } else {
            return null;
        }
    }

    initializeSampler = () => {
        this.sourceFilesStepStore.isFromOutsideSampler = true;
        this.sourceFilesStepStore.orgSelectStore.selectOrg(
            this.authStore.orgStore.selectedOrganization,
        );
        this.audioFileSamplerStore.isFromOutsideSampler = true;
        this.audioFileSamplerStore.orgId =
            this.authStore.orgStore.selectedOrganization?.id;
    };

    @action
    closeDrilldownDialog = () => {
        this.abortController.abort();
        this.abortController = new AbortController();

        this.selectedDataDgStore.paginationModel!.page = 0;
        this.selectedDataDgStore.clearSelected();
        this.selectedDataDgStore.rows = [];
        this.selectedDataDgStore.rowCount = undefined;
        this.showSelectedDataDialog = false;
    };
}

export default SignalsDrilldownStore;
