import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { MessageAuthor } from "./MessageAuthor";
import type { ThreadActionsMap } from "./ThreadAction";
import { TemplatedThreadMessage, ThreadMessage } from "./ThreadMessage";
import type { IThreadMessage } from "./ThreadMessage";
import { fromServerSuggestion } from "./ThreadSuggestion";
import type {
    ThreadSuggestion,
    ThreadServerSuggestion,
    ThreadSuggestionAction,
} from "./ThreadSuggestion";
import { ThreadActions } from "./ThreadAction";
import { Thread } from "./Thread";
import { HasParent } from "./Parent";

export interface IThreadMessageGroup {
    readonly id: string;
    author: MessageAuthor;
    messages: IThreadMessage[];
    suggestions?: ThreadSuggestion[];
    actions?: ThreadActions;
    transientMessage?: IThreadMessage;
}

/**
 * Represents a collection of messages sent by an individual author.
 */
export class ThreadMessageGroup
    implements IThreadMessageGroup, HasParent<Thread>
{
    public readonly id: string;

    public readonly parent: Thread;

    @observable author: MessageAuthor;
    @observable messages: IThreadMessage[];
    @observable suggestions?: ThreadSuggestion[];
    @observable actions: ThreadActions;
    @observable transientMessage?: IThreadMessage;
    @observable previousSuggestions?: ThreadSuggestion[];

    constructor(thread: Thread, author: MessageAuthor) {
        makeObservable(this);

        this.parent = thread;

        this.id = uuidv4();
        this.author = author;
        this.messages = [];
        this.suggestions = [];
        this.actions = new ThreadActions();
    }

    @computed get recentMessage(): IThreadMessage | undefined {
        return this.messages.at(-1);
    }

    public addMessage(message: IThreadMessage): IThreadMessage;
    public addMessage(content: string): IThreadMessage;
    @action
    public addMessage(
        messageOrContent: string | IThreadMessage,
    ): IThreadMessage {
        let message: IThreadMessage;

        if (typeof messageOrContent === "string")
            message = new ThreadMessage(this, messageOrContent);
        else message = messageOrContent;

        this.messages.push(message);
        return message;
    }

    @action
    public setActions(actions: ThreadActionsMap) {
        this.actions = ThreadActions.from(actions);
    }

    public addSuggestion(suggestion: ThreadSuggestion): ThreadSuggestion;
    public addSuggestion(
        content: string,
        action: ThreadSuggestionAction,
    ): ThreadSuggestion;
    @action
    public addSuggestion(
        suggestionOrContent: string | ThreadSuggestion,
        action?: ThreadSuggestionAction,
    ): ThreadSuggestion {
        let suggestion: ThreadSuggestion;

        if (typeof suggestionOrContent === "string") {
            if (!action) throw new Error("An action must be provided");
            suggestion = { content: suggestionOrContent, action };
        } else suggestion = suggestionOrContent;

        if (!this.suggestions) this.suggestions = [];
        this.suggestions.push(suggestion);
        return suggestion;
    }

    public addServerSuggestion(
        serverSuggestion: ThreadServerSuggestion,
    ): ThreadSuggestion | undefined {
        const suggestion = fromServerSuggestion(serverSuggestion, this);
        if (!this.suggestions) this.suggestions = [];
        if (!suggestion) return;
        this.suggestions.push(suggestion);
        return suggestion;
    }

    public addServerSuggestions(
        serverSuggestions: ThreadServerSuggestion[],
    ): ThreadSuggestion[] {
        const suggestions: ThreadSuggestion[] = [];
        for (const serverSuggestion of serverSuggestions) {
            const suggestion = this.addServerSuggestion(serverSuggestion);
            if (!suggestion) continue;
            suggestions.push(suggestion);
        }
        return suggestions;
    }

    @action
    public setSuggestions(suggestions: ThreadSuggestion[]) {
        this.suggestions = suggestions;
    }

    @action
    public clearSuggestions() {
        this.suggestions = [];
    }

    @action
    public setTransientMessage(
        content: string,
        suggestions?: ThreadSuggestion[],
    ) {
        this.transientMessage = new ThreadMessage(this, content);
        if (suggestions) {
            this.previousSuggestions = this.suggestions;
            this.setSuggestions(suggestions);
        }
    }

    @action public setTransientMessageTemplated(
        bindingObject: unknown,
        buildContent: (arg: unknown) => string,
        suggestions?: ThreadSuggestion[],
    ) {
        this.transientMessage = new TemplatedThreadMessage(
            this,
            bindingObject,
            buildContent,
        );
        if (suggestions) {
            this.previousSuggestions = this.suggestions;
            this.setSuggestions(suggestions);
        }
    }

    /**
     * If a transient message is set, it is added to the `messages` array and
     * `clearTransientMessage` is called.
     */
    @action
    public commitTransientMessage() {
        var message = this.transientMessage;
        this.previousSuggestions = undefined;
        if (!message) return;
        this.addMessage(message);
        this.clearTransientMessage();
        return message;
    }

    @action
    public clearTransientMessage() {
        this.transientMessage = undefined;
    }
}
