import { AgentModel } from "../models/AgentModel";
import { MetadataSpec } from "../models/MetadataSpec";
import Organization from "../models/Organization";
import { isUndefinedType } from "../utils/TypeGuards";
import { BaseService } from "./BaseService";

export class MetadataService extends BaseService {
    protected urlBase: string = "api/Metadata";

    public async getMetadataSpec(
        orgId?: string,
        orgStructureMemberId?: string,
    ): Promise<MetadataSpec.IMetadataSpecModel> {
        if (!orgId || !orgStructureMemberId) {
            throw new Error("OrgId and OrgStuctureId must be non-null");
        }
        const urlSearchParams = new URLSearchParams({
            orgId,
            orgStructureMemberId,
        });

        return await this.get<MetadataSpec.IMetadataSpecModel>(
            urlSearchParams,
            `Spec`,
        );
    }

    public async getMetadataById(amdId: string) {
        return this.get(new URLSearchParams(), amdId);
    }

    public async getExtendedMetadataValuesById(audioMetadataId: string) {
        return await this.get(
            new URLSearchParams({audioMetadataId}),
            "GetExtendedMetadataValuesById",
        );
    }

    public async agentsList(org: Organization | undefined) {
        if (isUndefinedType(org) || isUndefinedType(org?.qbAppId)) {
            throw new Error(
                `AgentsList requires non-empty QbAppId for Organization ${org?.name}`,
            );
        }

        const agents = await this.get<AgentModel[]>(
            new URLSearchParams({ qbAppId: org.qbAppId! }),
            "AgentsList",
        );

        return agents;
    }

    public async getMetadataSpecList(
        orgId: string,
    ): Promise<Array<MetadataSpec.IMetadataSpecModel>> {
        const urlSearchParams = new URLSearchParams({ orgId });

        return await this.get<Array<MetadataSpec.IMetadataSpecModel>>(
            urlSearchParams,
            `SpecList`,
        );
    }

    public async deleteMetadataSpec(specId: string): Promise<Response> {
        return this.delete(new URLSearchParams(), `SpecModel/${specId}`);
    }

    public async createMetadataSpec(
        orgId: string,
        specName: string,
        spec: MetadataSpec.ISpecType,
        orgStructMemberId: string,
    ): Promise<MetadataSpec.IMetadataSpecModel> {
        if (!orgId || !spec) {
            throw new Error(
                `MetadataSpecService: OrgId,CallBatchId, and Spec must be non-empty/non-null`,
            );
        }

        const urlSearchParams = new URLSearchParams({ orgId, specName });
        if (orgStructMemberId) {
            urlSearchParams.append("orgStructMemberId", orgStructMemberId);
        }

        return await this.post(
            JSON.stringify(spec),
            urlSearchParams,
            `SpecModel`,
        );
    }

    public async parseUpdateMetadata(
        specId: string | undefined,
        dirIds: string[],
        startDate: string,
        endDate: string,
        csvContents: string,
        taskId: string,
        orgId,
        allowNullOverwrites = false,
    ) {
        // ParseUpdateMetadata

        const urlSearchParams = new URLSearchParams({
            startDate,
            endDate,
            allowNullOverwrites: allowNullOverwrites.toString(),
            taskId: taskId,
            orgId,
        });

        if (specId) {
            urlSearchParams.append("specId", specId);
        }

        for (const dirId of dirIds) {
            urlSearchParams.append("dirIds", dirId);
        }

        return await this.post(
            csvContents ?? "",
            urlSearchParams,
            "ParseUpdateMetadata",
        );
    }
}
