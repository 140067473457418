import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, makeObservable, observable, reaction } from "mobx";
import IntegrationConfiguration, {
    AgentGroupingOptions,
    IntegrationType,
} from "models/OrganizationModels/OrganizationIntegrationConfiguration";
import { IntegrationService } from "services/IntegrationService";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { v4 as uuidv4 } from "uuid";

export const LOAD_INTEGRATION = "Load Integration Configuration";
export const SET_INTEGRATION = "Set Integration Configuration";

@AcxStore
export class IntegrationStore extends BaseStore {
    @observable
    orgId: string;

    @observable
    hasError: boolean = false;

    @observable
    integrationConfiguration: IntegrationConfiguration[] = [];

    private readonly integrationService: IntegrationService =
        new IntegrationService();

    private readonly messageStore: MessageStore;

    constructor(public rootStore: IRootStore) {
        super("OrganizationStore");

        makeObservable(this);

        this.messageStore = rootStore.getStore(MessageStore);

        reaction(
            (r) => ({
                orgId: this.orgId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    args.activeLocation.location.includes("admin/organizations")
                ) {
                    this.setupAsyncTask(LOAD_INTEGRATION, async () => {
                        this.integrationConfiguration = [];
                        if (args.orgId) {
                            const config =
                                await this.integrationService.getIntegrationConfiguration(
                                    args.orgId,
                                );
                            if (config.length > 0) {
                                this.integrationConfiguration = config;
                            }
                        }
                    });
                }
            },
        );
    }

    @action
    setOrgId = (orgId: string) => {
        this.orgId = orgId;
    };

    @action
    updateUrl = (index: number, value: string) => {
        this.integrationConfiguration[index].url = value;
    };

    @action
    updateApiKey = (index: number, value: string) => {
        this.integrationConfiguration[index].apiKey = value;
    };

    @action
    updateApiSecret = (index: number, value: string) => {
        this.integrationConfiguration[index].apiSecret = value;
    };

    @action
    toggleActive = (index: number) => {
        this.integrationConfiguration[index].isActive =
            !this.integrationConfiguration[index].isActive;
    };

    @action
    updateType = (index: number, value: IntegrationType) => {
        if (value === IntegrationType.NiceIn) {
            this.integrationConfiguration[index].configuration = null;
        } else if (value === IntegrationType.GenesysCloud) {
            this.integrationConfiguration[index].configuration = {
                minimumCallLengthInSeconds: null,
                maximumCallLengthInSeconds: null,
                noCallsPriorToDateTimeUtc: undefined,
                customConfiguration: {
                    queueIds: [],
                    agentGroupingOptions:
                        AgentGroupingOptions["Group Agent Segments By Agent"],
                },
            };
        }
        this.integrationConfiguration[index].type = value;
    };

    @action
    addItem = () => {
        this.integrationConfiguration.push({
            id: uuidv4(),
            url: "",
            apiKey: "",
            apiSecret: "",
            type: IntegrationType.NiceIn,
            isActive: true,
            organizationId: this.orgId,
            configuration: null,
        });
    };

    @action
    deleteItem = (index: number) => {
        this.integrationConfiguration.splice(index, 1);
    };

    @action
    updateCallLength = (type: "max" | "min", index: number, value: string) => {
        if (type === "max") {
            this.integrationConfiguration[
                index
            ].configuration!.maximumCallLengthInSeconds = !value
                ? null
                : Number(value);
        } else if (type === "min") {
            this.integrationConfiguration[
                index
            ].configuration!.minimumCallLengthInSeconds = !value
                ? null
                : Number(value);
        }
    };

    @action
    updatePriorCallDate = (index: number, value: string) => {
        this.integrationConfiguration[
            index
        ].configuration!.noCallsPriorToDateTimeUtc = value;
    };

    @action
    addQueueId = (index: number) => {
        const prevIds =
            this.integrationConfiguration[index].configuration!
                .customConfiguration!.queueIds;
        this.integrationConfiguration[
            index
        ].configuration!.customConfiguration!.queueIds = [...prevIds, ""];
    };

    @action
    handleQueueIdUpdates = (
        index: number,
        queueIdIndex: number,
        value: string,
    ) => {
        this.integrationConfiguration[
            index
        ].configuration!.customConfiguration!.queueIds[queueIdIndex] = value;
    };

    @action
    handleAgentGroupingUpdates = (
        index: number,
        value: AgentGroupingOptions,
    ) => {
        this.integrationConfiguration[
            index
        ].configuration!.customConfiguration!.agentGroupingOptions = value;
    };

    @action
    setHasError = (value: boolean) => {
        this.hasError = value;
    };

    @action
    saveTelephonyConfiguration = async () => {
        this.setupAsyncTask(SET_INTEGRATION, async () => {
            try {
                this.integrationConfiguration =
                    await this.integrationService.updateIntegrationConfiguration(
                        this.integrationConfiguration,
                        this.orgId,
                    );
                this.messageStore.logMessage(
                    `Telephony Configuration update successful.`,
                    "success",
                );
            } catch (err) {
                console.log(err);
                this.messageStore.logMessage(
                    `Telephony Configuration update failed.`,
                    "error",
                );
            }
        });
    };
}
