import { Cancel, ExpandMore, FilterAlt } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    Stack,
    Switch,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EddyIconSvg from "SvgIcons/EddyIconSvg";
import theme from "Theme/AppTheme";
import {
    DateRangeFilter,
    HierarchiesFilter,
    TopicsFilter,
} from "components/ApplicationFilters/Filters";
import { SavedFiltersList } from "components/ApplicationFilters/ManageFiltersButton";
import SaveFiltersButton from "components/ApplicationFilters/SaveFiltersButton";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSlider from "components/UI/AcxSlider";
import AcxToggleButton from "components/UI/AcxToggleButton";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxDrawer from "components/UI/Drawer/AcxDrawer";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectMultiAsync from "components/UI/Select/BaseSelectComponents/AcxSelectMultiAsync";
import _, { startCase } from "lodash";
import { observer } from "mobx-react";
import Agentv2 from "models/Agentv2";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import {
    ApplicationFilters,
    ApplicationFiltersStore,
    LocalSavedFilter,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import { CallDirection } from "stores/ApplicationFilters/Filters/CallDirection";
import { EvaluationType } from "stores/ApplicationFilters/Filters/EvaluationType";
import { HipaaCompliance } from "stores/ApplicationFilters/Filters/HipaaCompliance";
import { MediaType } from "stores/ApplicationFilters/Filters/MediaType";
import { SafetyEvent } from "stores/ApplicationFilters/Filters/SafetyEvent";
import { Sentiment } from "stores/ApplicationFilters/Filters/Sentiment";
import { AuthStore } from "stores/AuthStore";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import { useStore } from "utils/useStore";
import ConversationsStore from "../../Stores/ConversationsStore";
import ConversationFilterManagement from "./components/ConversationFilterManagement";
import RBCFilterInput from "./components/DeepFilter/RBCFilterInput";
import { WordsPhrasesConfirmationModal } from "./components/DeepFilter/WordsPhrasesConfirmationModal";
import WordsPhraseInput from "./components/DeepFilter/WordsPhrasesInput";
import ManageFilterInputDrawer, {
    ConversationAdditionalFilter,
} from "./components/ManageFilterInputDrawer";
import { DateReferenceFilter } from "components/ApplicationFilters/Filters";

const useStyles = makeStyles((theme: Theme) => ({
    drawerContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    itemPadding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    itemContainer: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.lightgray.main,
        borderRadius: "4px",
    },
    labelText: { fontWeight: "bold", paddingBottom: theme.spacing(1) },
    divider: {
        height: "2px",
        marginBottom: theme.spacing(2),
    },
    popperContainer: {
        padding: theme.spacing(2),
        width: "325px",
    },
    popperTextField: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    checkboxContainer: {
        maxHeight: "28px",
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2),
    },
    itemPaddingRight: {
        paddingRight: theme.spacing(2),
    },
    noTextWrap: {
        textWrap: "nowrap",
    },
    maxWidth40: {
        maxWidth: "40%",
    },
    saveFilterFloat: {
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 100,
    },
    applyButtonFloat: {
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
        zIndex: 100,
    },
    noMargin: { margin: 0 },
    hideFilterButton: {
        display: "none",
    },
    editIcon: { fontSize: 14, color: theme.palette.gray.main },
    manageBtn: {
        minWidth: "100%",
    },
    icon: {
        height: "16px",
        width: "16px",
        margin: theme.spacing(0.6, 0, 0, 0.5),
    },
    safetyEventsTooltip: {
        margin: theme.spacing(0),
        padding: theme.spacing(0, 1),
    },
}));

const UnfocusedDrawer: React.FunctionComponent = observer(() => {
    const classes = useStyles();

    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const applicationFilterValuesStore = useStore(ApplicationFilterValuesStore);
    const messageStore = useStore(MessageStore);

    const [searchParams] = useSearchParams();

    const ref = useRef<any>(null);

    const [isUpdateFiltersLoading, setIsUpdateFiltersLoading] = useState(false);

    const rootElement = document.getElementById("root");

    const store = useStore(ConversationsStore);
    const authStore = useStore(AuthStore);

    const organizationId =
        authStore.orgStore.selectedOrganization?.id ??
        authStore._user.profile.OrgId;

    useEffect(() => {
        // a safety check
        if (!applicationFiltersStore || !messageStore) return;

        if (applicationFiltersStore.hasFilterStateChangedSince("Conversations"))
            messageStore.logInfo(
                "Your filters have been updated. Click 'Apply' to refresh the results.",
            );

        return () => {
            if (!!applicationFiltersStore.savedFilterStates["Conversations"])
                return;
            applicationFiltersStore.saveCurrentFilterState("Conversations");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setFilterFromSaved = async (savedFilters?: LocalSavedFilter) => {
        if (!savedFilters) applicationFiltersStore.resetFilters();
        else applicationFiltersStore.applySavedFilters(savedFilters.id);
    };

    const onAgentSelect = (agents?: Agentv2[]) => {
        if (agents) {
            applicationFiltersStore.setAgentIds(
                agents.map((agent) => agent.id),
            );
            applicationFiltersStore.setDetailedAgents(agents);
        }
    };

    const getAgentOptionLabel = (data) => {
        let lbl = "";
        if (_.has(data, "fullName")) {
            if (data?.fullName !== " ") {
                lbl = data.fullName;
            } else {
                lbl = data.agentEmail;
            }
            if (data.isActive) {
                lbl = lbl + " - Active";
            } else {
                lbl = lbl + " - Inactive";
            }
        } else {
            lbl = data.label;
        }
        return lbl;
    };

    const loadAgentOptions = useCallback(
        async (searchValue) => {
            return await store.agentService.searchAgents(
                organizationId,
                searchValue,
            );
        },
        [store.agentService, organizationId],
    );

    const onInitialLoad = async () => {
        applicationFiltersStore.wordsAndPhrasesStore.setWordsPhrasesFtsSyntaxError(
            "",
        );

        const fromAgent = searchParams.has("agent");
        if (fromAgent) {
            await store.defaultLocalStorage
                .getItem<ApplicationFilters>("agentConversationsFilters")
                .then((filters) => {
                    if (!filters || !!filters["conversationIds"]) return;
                    // agent filters handle date reference as an enum, FE expects a string
                    const dateReference = filters["dateReference"] as number;
                    if (dateReference === 0)
                        filters.dateReferenceOption =
                            DateReferenceOption.InteractionDate;
                    if (dateReference === 1)
                        filters.dateReferenceOption =
                            DateReferenceOption.ArrivalDate;

                    applicationFiltersStore.applyExternalFilters(filters, true);
                });
        }

        store.setIsInitialLoad(true, fromAgent);
        store.setPageNumberAndGetConversations(1);
    };

    const renderAdditionalFilter = (
        filterItem: ConversationAdditionalFilter,
    ) => {
        switch (filterItem.storeObjectKey) {
            case "mediaType":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <Typography className={classes.labelText}>
                            Media Type
                        </Typography>
                        <Stack>
                            <FormControlLabel
                                label={"Calls"}
                                control={
                                    <Checkbox
                                        id="conversations-filters-media-type-calls"
                                        onChange={() => {
                                            applicationFiltersStore.toggleMediaType(
                                                MediaType.Audio,
                                            );
                                        }}
                                        checked={applicationFiltersStore.mediaTypes.includes(
                                            MediaType.Audio,
                                        )}
                                    />
                                }
                                className={classes.checkboxContainer}
                            />
                            <FormControlLabel
                                label={"Chats"}
                                control={
                                    <Checkbox
                                        id="conversations-filters-media-type-chats"
                                        onChange={() => {
                                            applicationFiltersStore.toggleMediaType(
                                                MediaType.ProcessedChat,
                                            );
                                        }}
                                        checked={applicationFiltersStore.mediaTypes.includes(
                                            MediaType.ProcessedChat,
                                        )}
                                    />
                                }
                                className={classes.checkboxContainer}
                            />
                        </Stack>
                    </Grid>
                );
            case "callDirection":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <Typography className={classes.labelText}>
                            Call Direction
                        </Typography>
                        <Stack>
                            <FormControlLabel
                                label={"Inbound"}
                                control={
                                    <Checkbox
                                        id="conversations-filters-call-direction-inbound"
                                        onChange={() => {
                                            applicationFiltersStore.toggleCallDirection(
                                                CallDirection.Inbound,
                                            );
                                        }}
                                        checked={applicationFiltersStore.callDirections.includes(
                                            CallDirection.Inbound,
                                        )}
                                    />
                                }
                                className={classes.checkboxContainer}
                            />
                            <FormControlLabel
                                label={"Outbound"}
                                control={
                                    <Checkbox
                                        id="conversations-filters-call-direction-outbound"
                                        onChange={() => {
                                            applicationFiltersStore.toggleCallDirection(
                                                CallDirection.Outbound,
                                            );
                                        }}
                                        checked={applicationFiltersStore.callDirections.includes(
                                            CallDirection.Outbound,
                                        )}
                                    />
                                }
                                className={classes.checkboxContainer}
                            />
                            <FormControlLabel
                                label={"Transfer"}
                                control={
                                    <Checkbox
                                        id="conversations-filters-call-direction-transfer"
                                        onChange={() => {
                                            applicationFiltersStore.toggleCallDirection(
                                                CallDirection.Transfer,
                                            );
                                        }}
                                        checked={applicationFiltersStore.callDirections.includes(
                                            CallDirection.Transfer,
                                        )}
                                    />
                                }
                                className={classes.checkboxContainer}
                            />
                        </Stack>
                    </Grid>
                );
            case "clientCallId":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <AcxMainTextField
                            id="conversations-filters-Client-Call-ID"
                            type="text"
                            labelText={"Client Call Id"}
                            value={applicationFiltersStore.clientCallId}
                            onChange={(e) =>
                                applicationFiltersStore.setClientCallId(
                                    e.target.value,
                                )
                            }
                        />
                    </Grid>
                );
            case "agents":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <Typography className={classes.labelText}>
                            Agents
                        </Typography>
                        <AcxSelectMultiAsync
                            id="conversations-filters-agents"
                            defaultValue={
                                applicationFiltersStore.detailedAgents
                            } // application filter store
                            onChange={onAgentSelect}
                            valueField="id"
                            labelField={getAgentOptionLabel}
                            isClearable={true}
                            value={applicationFiltersStore.detailedAgents}
                            loadOptions={loadAgentOptions}
                            noOptionMessage={
                                "Please enter First, Last, Agent Code, or Email"
                            }
                        />
                    </Grid>
                );
            case "meta1":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <AcxMainTextField
                            type="text"
                            id={`conversations-meta-filter-1`}
                            labelText={
                                filterItem.filterLabel ?? filterItem.filterTitle
                            }
                            value={applicationFiltersStore.meta1}
                            onChange={(e) =>
                                applicationFiltersStore.setMeta1(e.target.value)
                            }
                        />
                    </Grid>
                );
            case "meta2":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <AcxMainTextField
                            type="text"
                            id={`conversations-meta-filter-2`}
                            labelText={
                                filterItem.filterLabel ?? filterItem.filterTitle
                            }
                            value={applicationFiltersStore.meta2}
                            onChange={(e) =>
                                applicationFiltersStore.setMeta2(e.target.value)
                            }
                        />
                    </Grid>
                );
            case "meta3":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <AcxMainTextField
                            type="text"
                            id={`conversations-meta-filter-3`}
                            labelText={
                                filterItem.filterLabel ?? filterItem.filterTitle
                            }
                            value={applicationFiltersStore.meta3}
                            onChange={(e) =>
                                applicationFiltersStore.setMeta3(e.target.value)
                            }
                        />
                    </Grid>
                );
            case "meta4":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <AcxMainTextField
                            type="text"
                            id={`conversations-meta-filter-4`}
                            labelText={
                                filterItem.filterLabel ?? filterItem.filterTitle
                            }
                            value={applicationFiltersStore.meta4}
                            onChange={(e) =>
                                applicationFiltersStore.setMeta4(e.target.value)
                            }
                        />
                    </Grid>
                );
            case "meta5":
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={filterItem.id}
                    >
                        <AcxMainTextField
                            type="text"
                            id={`conversations-meta-filter-5`}
                            labelText={
                                filterItem.filterLabel ?? filterItem.filterTitle
                            }
                            value={applicationFiltersStore.meta5}
                            onChange={(e) =>
                                applicationFiltersStore.setMeta5(e.target.value)
                            }
                        />
                    </Grid>
                );
            case "favoriteConversations":
                return (
                    <Grid container className={classes.itemPadding}>
                        <Typography className={classes.labelText}>
                            Favorite
                        </Typography>
                        <Grid
                            xs={12}
                            container
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                        >
                            <Grid item>
                                <Switch
                                    id="conversations-filters-favorite-conversations"
                                    checked={
                                        applicationFiltersStore.favoriteConversations
                                    }
                                    onChange={() =>
                                        applicationFiltersStore.toggleFavoriteConversationsFilter()
                                    }
                                    // disabled={isLoading}
                                    sx={{ minWidth: "40px", marginLeft: 0 }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Show favorite conversations only
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case "dateReferenceOption":
                return (
                    <Grid container className={classes.itemPadding}>
                        <Typography className={classes.labelText}>
                            Date Type
                        </Typography>
                        <DateReferenceFilter
                            filterKey="dateReferenceOption"
                            store={applicationFiltersStore.quickFiltersStore}
                        />
                    </Grid>
                );
            default:
                return (
                    <Grid
                        item
                        xs={12}
                        className={classes.itemPadding}
                        key={"conversations-extMetaItem" + filterItem.id}
                    >
                        <AcxMainTextField
                            id={
                                "conversations-extMetaItem-input" +
                                filterItem.filterTitle
                            }
                            type="text"
                            labelText={
                                filterItem.filterLabel ?? filterItem.filterTitle
                            }
                            value={
                                applicationFiltersStore.extendedMetadata?.[
                                    filterItem.filterTitle
                                ]
                            }
                            onChange={(e) => {
                                applicationFiltersStore.setExtendedMetadataPair(
                                    filterItem.filterTitle,
                                    e.target.value,
                                );
                            }}
                            endAdornment={
                                <Tooltip title="This field supports comma separated values e.g.: value1,value2,value3 ">
                                    <InfoIcon color="disabled" />
                                </Tooltip>
                            }
                        />
                    </Grid>
                );
        }
    };

    React.useEffect(() => {
        if (!store.estimatedConversationCount) {
            onInitialLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isSavedFiltersOpen, setIsSavedFiltersOpen] = useState(false);
    const anchorEl = useRef<HTMLElement | null>(null);

    return (
        <>
            <Grid
                container
                item
                xs={12}
                className={classes.saveFilterFloat}
                justifyContent="center"
                ref={ref}
            >
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        padding: theme.spacing(2),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Grid item>
                        <Box fontSize={18} fontWeight="bold">
                            Search Filters
                        </Box>
                    </Grid>
                    <Grid
                        container
                        item
                        width="fit-content"
                        alignItems="center"
                        ref={(el) => (anchorEl.current = el)}
                    >
                        <AcxButton
                            id="conversations-filters-button"
                            variant="outlined"
                            color="white"
                            onClick={() =>
                                setIsSavedFiltersOpen((open) => !open)
                            }
                        >
                            <FilterAlt
                                fontSize="small"
                                sx={(theme) => ({
                                    marginRight: theme.spacing(1),
                                    color: theme.palette.gray[600],
                                })}
                            />
                            <Typography
                                sx={(theme) => ({
                                    maxWidth: "164px",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                    marginRight: theme.spacing(1),
                                    color: theme.palette.gray[600],
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    textAlign: "center",
                                })}
                            >
                                {!!applicationFiltersStore.selectedSavedFilter
                                    ? applicationFiltersStore
                                          .selectedSavedFilter.name
                                    : "Filters"}
                            </Typography>
                            {!!applicationFiltersStore.selectedSavedFilter && (
                                <IconButton
                                    size="small"
                                    sx={{
                                        fontSize: "16px",
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        applicationFiltersStore.setSelectedSavedFilters();
                                        applicationFiltersStore.resetFilters();
                                    }}
                                >
                                    <Cancel
                                        fontSize="inherit"
                                        htmlColor={theme.palette.gray[300]}
                                    />
                                </IconButton>
                            )}
                            <ExpandMore
                                fontSize="inherit"
                                sx={(theme) => ({
                                    fontSize: "20px",
                                    color: theme.palette.gray[400],
                                })}
                            />
                        </AcxButton>
                        <Menu
                            open={isSavedFiltersOpen}
                            onClose={() => setIsSavedFiltersOpen(false)}
                            anchorEl={anchorEl.current}
                            anchorReference="anchorEl"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: -8,
                                horizontal: "right",
                            }}
                            slotProps={{
                                paper: {
                                    sx: (theme) => ({
                                        padding: theme.spacing(1),
                                        borderRadius: theme.spacing(1 / 2),
                                        minWidth: "164px",
                                    }),
                                },
                            }}
                        >
                            <SavedFiltersList
                                onSelect={async (filter) => {
                                    setIsSavedFiltersOpen(false);
                                    await setFilterFromSaved(filter);
                                    applicationFiltersStore.saveCurrentFilterState(
                                        "Update",
                                    );
                                }}
                            >
                                <SavedFiltersList.Action
                                    id="conversations-filters-manage-saved-filters"
                                    onClick={() => {
                                        store.setUnsavedFilters([
                                            ...applicationFiltersStore.ownedSavedFilters,
                                        ]);
                                        store.manageSavedFilterDialogStore.open();
                                    }}
                                >
                                    Manage Filters
                                </SavedFiltersList.Action>
                            </SavedFiltersList>
                        </Menu>
                    </Grid>
                </Grid>

                <Grid item xs={11}>
                    <Divider className={classes.divider} />
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    sx={{
                        paddingRight: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                        paddingTop: "10px",
                    }}
                >
                    <Grid
                        item
                        xs={5}
                        sx={{
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                            paddingBottom: theme.spacing(1),
                            paddingTop: theme.spacing(1),
                        }}
                    >
                        <Typography className={classes.noTextWrap}>
                            {applicationFiltersStore.appliedFilterCount}
                            {applicationFiltersStore.appliedFilterCount === 1
                                ? " Filter "
                                : " Filters "}
                            Applied
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={7}
                        direction="row"
                        columnSpacing={1}
                        justifyContent="flex-end"
                        flexWrap="nowrap"
                    >
                        <Grid item className={classes.maxWidth40}>
                            {(applicationFiltersStore.appliedFilterCount > 0 ||
                                applicationFiltersStore.quickFiltersStore
                                    ?.appliedFilterCount > 0 ||
                                !!applicationFiltersStore.selectedSavedFilter) && (
                                <AcxButton
                                    fullWidth
                                    id="conversations-filters-clear"
                                    size="small"
                                    onClick={() => {
                                        applicationFiltersStore.setSelectedSavedFilters();
                                        applicationFiltersStore.resetFilters();
                                        applicationFiltersStore.quickFiltersStore.resetFilters();
                                    }}
                                    color="white"
                                    sx={(theme) => ({
                                        paddingInline: theme.spacing(2),
                                        paddingBlock: theme.spacing(1),
                                    })}
                                >
                                    Clear
                                </AcxButton>
                            )}
                        </Grid>
                        {!!applicationFiltersStore.selectedSavedFilter &&
                            applicationFiltersStore.isSelectedSavedFilterEditable && (
                                <Grid item>
                                    <AcxButton
                                        fullWidth
                                        id="conversations-filters-update"
                                        size="small"
                                        color="white"
                                        sx={(theme) => ({
                                            paddingInline: theme.spacing(2),
                                            paddingBlock: theme.spacing(1),
                                        })}
                                        disabled={
                                            !applicationFiltersStore.hasChangedSinceLastUpdate
                                        }
                                        loading={applicationFiltersStore.getTaskLoading(
                                            ApplicationFiltersStore.Tasks
                                                .UPDATE_FILTER,
                                        )}
                                        onClick={async () => {
                                            const success =
                                                await applicationFiltersStore.updateSelectedSavedFilterWith();

                                            if (!success)
                                                return messageStore.logError(
                                                    "Failed to update filter. Please try again.",
                                                );

                                            messageStore.logInfo(
                                                `'${
                                                    applicationFiltersStore.selectedSavedFilter!
                                                        .name
                                                }' successfully updated.`,
                                            );
                                        }}
                                    >
                                        Update
                                    </AcxButton>
                                </Grid>
                            )}
                        <Grid item>
                            {(applicationFiltersStore.selectedSavedFilter ||
                                applicationFiltersStore.appliedFilterCount >
                                    0) && (
                                <SaveFiltersButton
                                    id={
                                        applicationFiltersStore.selectedSavedFilter
                                            ? "conversations-filters-save-as-new"
                                            : "conversations-filters-save"
                                    }
                                    variant="menu"
                                    tooltip="Save filter as template"
                                    showVisibilityToggle
                                    disabled={
                                        applicationFiltersStore
                                            .wordsAndPhrasesStore.hasError
                                    }
                                    color="white"
                                    sx={(theme) => ({
                                        paddingInline: theme.spacing(2),
                                        paddingBlock: theme.spacing(1),
                                    })}
                                >
                                    {applicationFiltersStore.selectedSavedFilter
                                        ? "Save as New"
                                        : "Save"}
                                </SaveFiltersButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.itemPadding}>
                <Typography className={classes.labelText}>
                    {startCase(
                        applicationFiltersStore.quickFiltersStore
                            .dateReferenceOption,
                    )}{" "}
                    Range
                </Typography>
                <DateRangeFilter
                    store={applicationFiltersStore.quickFiltersStore}
                    filterKey="dateRange"
                    maxWidth="100%"
                    inputStyle={{ width: "100%" }}
                    wrapperStyle={{ maxWidth: "100%" }}
                    onSelect={() =>
                        applicationFiltersStore.alertOfQuickFilterOverride(
                            "dateRange",
                        )
                    }
                    onDateTypeChange={() =>
                        applicationFiltersStore.alertOfQuickFilterOverride(
                            "dateReferenceOption",
                        )
                    }
                />
            </Grid>
            <Grid item xs={12} className={classes.itemPadding}>
                <AcxSlider
                    label="Call Duration"
                    description="minutes"
                    value={applicationFiltersStore.callDuration}
                    onChange={(duration) => {
                        applicationFiltersStore.setCallDuration(duration);
                    }}
                />
            </Grid>
            {/* render mediaType filter if it is active */}
            {store.activeAdditionalFilterItems
                .filter(
                    (item) =>
                        item.storeObjectKey === "mediaType" ||
                        item.storeObjectKey === "callDirection" ||
                        item.storeObjectKey === "clientCallId" ||
                        item.storeObjectKey === "agents" ||
                        item.storeObjectKey === "favoriteConversations" ||
                        item.storeObjectKey === "dateReferenceOption",
                )
                .map((item) => renderAdditionalFilter(item))}
            <Grid item xs={12} className={classes.itemPadding}>
                <Typography className={classes.labelText}>Hierarchy</Typography>
                <HierarchiesFilter
                    store={applicationFiltersStore.quickFiltersStore}
                    filterKey="hierarchyIds"
                    setHierarchyIds={() =>
                        applicationFiltersStore.alertOfQuickFilterOverride(
                            "hierarchyIds",
                        )
                    }
                    displayType="input"
                    width={undefined}
                    popperPlacement={undefined}
                    fixedPopper
                    anchorEl={rootElement}
                />
            </Grid>

            <Grid item xs={12} className={classes.itemPadding}>
                <Typography className={classes.labelText}>
                    ML Model Identification
                </Typography>
                <Grid
                    container
                    alignItems="center"
                    className={classes.paddingBottom}
                >
                    <Typography>Eddy Effect Signal</Typography>
                    <Grid item style={{ paddingLeft: "4px" }}>
                        <EddyIconSvg
                            style={{
                                width: "16px",
                                height: "16px",
                                float: "right",
                            }}
                        />
                    </Grid>
                </Grid>
                <AcxToggleButton
                    id="conversations-filters-eddy-effect"
                    exclusive
                    items={[
                        {
                            id: "conversations-filters-eddy-effect-is-present",
                            element: "Is Present",
                            value: true,
                        },
                        {
                            id: "conversations-filters-eddy-effect-is-not-present",
                            element: "Is Not Present",
                            value: false,
                        },
                    ]}
                    onChange={(value?: boolean) => {
                        applicationFiltersStore.setEddyEffectStatus(value);
                    }}
                    defaultValue={applicationFiltersStore.eddyEffectStatus}
                    justifyContent="center"
                    groupFlexGrow={1}
                />
            </Grid>

            {store.authStore.canUserView("HIPAA Compliance Model") && (
                <Grid item xs={12} className={classes.itemPadding}>
                    <Typography className={classes.paddingBottom}>
                        HIPAA Compliance
                    </Typography>
                    <AcxToggleButton
                        id="conversations-filters-HIPAA-Compliance"
                        exclusive
                        items={[
                            {
                                id: "conversations-filters-HIPAA-Compliance-Not-Compliant",
                                element: "Not Compliant",
                                value: HipaaCompliance.NotCompliant,
                            },
                            {
                                id: "conversations-filters-HIPAA-Compliance-Compliant",
                                element: "Compliant",
                                value: HipaaCompliance.Compliant,
                            },
                            {
                                id: "conversations-filters-HIPAA-Compliance-NA",
                                element: "N/A",
                                value: HipaaCompliance.NotApplicable,
                            },
                        ]}
                        onChange={(value) => {
                            applicationFiltersStore.setHipaaCompliance(value);
                        }}
                        defaultValue={applicationFiltersStore.hipaaCompliance}
                        justifyContent="center"
                        groupFlexGrow={1}
                    />
                </Grid>
            )}
            <Grid item xs={12} className={classes.itemPadding}>
                <Typography className={classes.paddingBottom}>
                    Starting Sentiment
                </Typography>
                <AcxToggleButton
                    id="conversations-filters-starting-sentiment"
                    exclusive
                    items={[
                        {
                            id: "conversations-filters-starting-sentiment-negative",
                            element: "Negative",
                            value: Sentiment.Negative,
                        },
                        {
                            id: "conversations-filters-starting-sentiment-neutral",
                            element: "Neutral",
                            value: Sentiment.Neutral,
                        },
                        {
                            id: "conversations-filters-starting-sentiment-positive",
                            element: "Positive",
                            value: Sentiment.Positive,
                        },
                    ]}
                    onChange={(value) => {
                        applicationFiltersStore.setBeginningSentiment(value);
                    }}
                    defaultValue={applicationFiltersStore.beginningSentiment}
                    justifyContent="center"
                    groupFlexGrow={1}
                />
            </Grid>

            <Grid item xs={12} className={classes.itemPadding}>
                <Typography className={classes.paddingBottom}>
                    Ending Sentiment
                </Typography>
                <AcxToggleButton
                    id="conversations-filters-ending-sentiment"
                    exclusive
                    items={[
                        {
                            id: "conversations-filters-ending-sentiment-negative",
                            element: "Negative",
                            value: Sentiment.Negative,
                        },
                        {
                            id: "conversations-filters-ending-sentiment-neutral",
                            element: "Neutral",
                            value: Sentiment.Neutral,
                        },
                        {
                            id: "conversations-filters-ending-sentiment-positive",
                            element: "Positive",
                            value: Sentiment.Positive,
                        },
                    ]}
                    onChange={(value) => {
                        applicationFiltersStore.setEndingSentiment(value);
                    }}
                    defaultValue={applicationFiltersStore.endingSentiment}
                    justifyContent="center"
                    groupFlexGrow={1}
                />
            </Grid>

            {store.authStore.canUserView("Safety Events Model") && (
                <Grid container>
                    <Grid item xs={12} className={classes.itemPadding}>
                        <Grid
                            container
                            item
                            alignItems="center"
                            className={classes.paddingBottom}
                        >
                            <Typography component="span">
                                Safety Events
                            </Typography>
                            <div>
                                <Tooltip
                                    title={
                                        <ul
                                            style={{
                                                margin: theme.spacing(0),
                                                padding: theme.spacing(0, 1),
                                            }}
                                        >
                                            <li>
                                                Acknowledged: Agent acknowledged
                                                a safety event
                                            </li>
                                            <li>
                                                Not Acknowledged: Agent did not
                                                acknowledge a safety event
                                            </li>
                                            <li>
                                                Not Identified: No safety event
                                                identified in the conversation
                                            </li>
                                        </ul>
                                    }
                                >
                                    <InfoIcon
                                        sx={{
                                            height: "16px",
                                            width: "16px",
                                            margin: theme.spacing(
                                                0.6,
                                                0,
                                                0,
                                                0.5,
                                            ),
                                        }}
                                        color="primary"
                                    />
                                </Tooltip>
                            </div>
                        </Grid>
                        <AcxToggleButton
                            id="conversations-filters-safety-events"
                            style={{ display: "flex" }}
                            exclusive
                            items={[
                                {
                                    id: "conversations-filters-safety-events-acknowledged",
                                    element: "Acknowledged",
                                    value: SafetyEvent.Acknowledged,
                                },
                                {
                                    id: "conversations-filters-safety-events-not-acknowledged",
                                    element: "Not Acknowledged",
                                    value: SafetyEvent.NotAcknowledged,
                                },
                                {
                                    id: "conversations-filters-safety-events-not-identified",
                                    element: "Not Identified",
                                    value: SafetyEvent.NotIdentified,
                                },
                            ]}
                            onChange={(value) => {
                                applicationFiltersStore.setSafetyEvent(value);
                            }}
                            defaultValue={applicationFiltersStore.safetyEvent}
                            justifyContent="center"
                            groupFlexGrow={1}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.itemPadding}>
                        <Typography sx={{ paddingBottom: "16px" }}>
                            Adverse Event
                        </Typography>
                        <AcxToggleButton
                            id="conversations-filters-adverse-event"
                            exclusive
                            items={[
                                {
                                    id: "conversations-filters-adverse-event-is-present",
                                    element: "Is Present",
                                    value: true,
                                },
                                {
                                    id: "conversations-filters-adverse-event-is-not-present",
                                    element: "Is Not Present",
                                    value: false,
                                },
                            ]}
                            onChange={(value) => {
                                applicationFiltersStore.setAdverseEvent(value);
                            }}
                            defaultValue={applicationFiltersStore.adverseEvent}
                            justifyContent="center"
                            groupFlexGrow={1}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid item xs={12} className={classes.itemPadding}>
                <Typography className={classes.labelText}>
                    Contact Type
                </Typography>
                <AcxSelectMulti
                    id="conversations-filters-contact-type"
                    fullWidth
                    options={applicationFilterValuesStore.contactTypeOptions}
                    defaultValue={applicationFiltersStore.contactTypes.map(
                        (i) => ({
                            label: applicationFilterValuesStore.getContactTypeLabel(
                                i,
                            ),
                            value: i,
                        }),
                    )}
                    onChange={(options) => {
                        applicationFiltersStore.setContactTypes(
                            !!options ? options : [],
                        );
                    }}
                    valueField="value"
                    labelField="label"
                    containerHeight="auto"
                    maxContainerHeight="96px"
                    alignControls="flex-start"
                    isClearable={true}
                />
            </Grid>

            {store.authStore.canUserView("Topics") && (
                <Grid container item xs={12} className={classes.itemPadding}>
                    <Typography className={classes.labelText}>
                        Primary Theme
                    </Typography>
                    <Grid
                        container
                        item
                        direction="column"
                        className={classes.itemContainer}
                    >
                        <TopicsFilter
                            filterKey="topics"
                            store={applicationFiltersStore}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid item xs={12} className={classes.itemPadding}>
                <RBCFilterInput
                    store={applicationFiltersStore.rbcFilterStore}
                />
            </Grid>

            <Grid item xs={12} className={classes.itemPadding}>
                <WordsPhraseInput
                    store={applicationFiltersStore.wordsAndPhrasesStore}
                />
            </Grid>

            <Grid item xs={12} className={classes.itemPadding}>
                <Typography className={classes.labelText}>
                    Evaluation Type
                </Typography>
                <Stack>
                    <FormControlLabel
                        label={
                            <Grid container alignItems="center">
                                No Evaluation
                            </Grid>
                        }
                        control={
                            <Checkbox
                                id="conversations-filters-eval-type-no-evaluation"
                                onChange={() => {
                                    applicationFiltersStore.toggleEvaluationType(
                                        EvaluationType.NotEvaluated,
                                    );
                                }}
                                checked={applicationFiltersStore.evaluationTypes.includes(
                                    EvaluationType.NotEvaluated,
                                )}
                            />
                        }
                        className={classes.checkboxContainer}
                    />
                    <FormControlLabel
                        label={
                            <Grid container alignItems="center">
                                AI Evaluated
                                <Grid item style={{ paddingLeft: "4px" }}>
                                    <CheckCircleOutlineIcon
                                        color={"primary"}
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                            float: "right",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }
                        control={
                            <Checkbox
                                id="conversations-filters-eval-type-AI-evaluated"
                                onChange={() => {
                                    applicationFiltersStore.toggleEvaluationType(
                                        EvaluationType.AIEvaluated,
                                    );
                                }}
                                checked={applicationFiltersStore.evaluationTypes.includes(
                                    EvaluationType.AIEvaluated,
                                )}
                            />
                        }
                        className={classes.checkboxContainer}
                        disabled={applicationFiltersStore.evaluationTypes.includes(
                            EvaluationType.NotEvaluated,
                        )}
                    />
                    <FormControlLabel
                        label={
                            <Grid container alignItems="center">
                                Human Evaluated
                                <Grid item style={{ paddingLeft: "4px" }}>
                                    <CheckCircleOutlineIcon
                                        color={"secondary"}
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                            float: "right",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }
                        control={
                            <Checkbox
                                id="conversations-filters-eval-type-human-evaluated"
                                onChange={() => {
                                    applicationFiltersStore.toggleEvaluationType(
                                        EvaluationType.HumanEvaluated,
                                    );
                                }}
                                checked={applicationFiltersStore.evaluationTypes.includes(
                                    EvaluationType.HumanEvaluated,
                                )}
                            />
                        }
                        className={classes.checkboxContainer}
                        disabled={applicationFiltersStore.evaluationTypes.includes(
                            EvaluationType.NotEvaluated,
                        )}
                    />
                </Stack>
            </Grid>

            {/* render metadata/extMetadata filters */}
            {store.activeAdditionalFilterItems
                .filter(
                    (item) =>
                        item.storeObjectKey !== "mediaType" &&
                        item.storeObjectKey !== "callDirection" &&
                        item.storeObjectKey !== "clientCallId" &&
                        item.storeObjectKey !== "agents" &&
                        item.storeObjectKey !== "favoriteConversations" &&
                        item.storeObjectKey !== "dateReferenceOption",
                )
                .map((item) => renderAdditionalFilter(item))}

            <Grid
                item
                container
                xs={12}
                className={classes.applyButtonFloat}
                justifyContent="center"
            >
                <Grid item xs={12} className={classes.paddingBottom}>
                    <Divider />
                </Grid>
                <Grid item xs={5} className={classes.paddingBottom}>
                    <AcxButton
                        fullWidth
                        id="conversations-filter-toggle-manage-filter-button"
                        onClick={() => {
                            store.toggleManageFilterInput();
                        }}
                        loading={store.anyTaskLoading}
                        color="white"
                        className={classes.manageBtn}
                        style={{
                            marginRight: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        + Manage Filters
                    </AcxButton>
                </Grid>
                <Grid item xs={3} className={classes.paddingBottom}>
                    <AcxButton
                        fullWidth
                        id="conversations-filter-apply-filters-button"
                        color="primary"
                        onClick={async () => {
                            applicationFiltersStore.saveCurrentFilterState(
                                "Conversations",
                            );

                            const valid =
                                await applicationFiltersStore.validateWordsAndPhrases();
                            if (!valid)
                                return store.messageStore.logError(
                                    "Invalid Words and Phrases Syntax, please correct the following before continuing: \n" +
                                        applicationFiltersStore
                                            .wordsAndPhrasesStore
                                            .wordsPhrasesFtsSyntaxError,
                                );

                            await store.getSearchConversations();
                        }}
                        loading={store.anyTaskLoading}
                        disabled={
                            applicationFiltersStore.wordsAndPhrasesStore
                                .hasError
                        }
                        style={{
                            marginRight: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        Apply
                    </AcxButton>
                </Grid>
            </Grid>
            <WordsPhrasesConfirmationModal
                store={applicationFiltersStore.wordsAndPhrasesStore}
            />
            <AcxDialog
                title="Manage Saved Filters"
                isOpen={store.manageSavedFilterDialogStore.isOpen}
                onClose={() => {
                    // throw new Error("Come back to me");
                    // store.setUnsavedFilters(store.savedFilters);
                    store.manageSavedFilterDialogStore.close();
                }}
                dialogContentChildren={<ConversationFilterManagement />}
                maxWidth="md"
                contentWidth="800px"
                children={
                    <Tooltip
                        title={
                            store.canSaveFilters
                                ? "Filter names must be unique"
                                : ""
                        }
                    >
                        <Box>
                            <AcxButton
                                fullWidth
                                id="conversations-manage-filter-panel-save-changes-button"
                                onClick={store.manageSavedFilterCDStore.open}
                                color="primary"
                                disabled={store.canSaveFilters}
                            >
                                Save Changes
                            </AcxButton>
                        </Box>
                    </Tooltip>
                }
                alternateActionBackgroundColor
            />
            <AcxDialog
                title="Confirm Save Filters"
                text="Deleting/Editing filters is immediate and permanent. Are you sure you want to save these changes?"
                isOpen={store.manageSavedFilterCDStore.isOpen}
                onClose={store.manageSavedFilterCDStore.close}
                contentWidth="400px"
                children={
                    <Box>
                        <AcxButton
                            fullWidth
                            id="conversations-confirm-save-changes-dialog-confirm-button"
                            color="primary"
                            loading={isUpdateFiltersLoading}
                            onClick={async () => {
                                setIsUpdateFiltersLoading(true);

                                await store.saveUnsavedFilters();

                                store.manageSavedFilterCDStore.close();
                                store.manageSavedFilterDialogStore.close();
                                setIsUpdateFiltersLoading(false);
                            }}
                        >
                            Confirm Save Changes
                        </AcxButton>
                    </Box>
                }
                hideCloseButton
                textAlignCenterContent
                textAlignCenterTitle
                alternateActionBackgroundColor
            />
            <AcxDrawer
                anchor={"left"}
                open={store.filterInputManagerIsOpen}
                collapsible={"full"}
                content={<ManageFilterInputDrawer />}
                offsetPosition="Left"
                offsetPx={ref.current ? ref.current.offsetWidth + 15 : 0}
                customRootStyles={{
                    zIndex: 2,
                    whiteSpace: "pre-wrap",
                }}
                onDrawerClose={store.toggleManageFilterInput}
                disableEnforceFocus
            />
        </>
    );
});

export default UnfocusedDrawer;
