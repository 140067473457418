import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Paper,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "utils/useStore";
import MetadataConfiguration from "./MetadataConfiguration";
import {
    LOAD_CONFIG,
    OrganizationMetadataStore,
    SET_CONFIG,
} from "./Store/OrganizationMetadataStore";

const styles = (theme: Theme) => {
    return createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(16),
            flexBasis: "60%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
        },
        metadataWrapperContainer: { marginTop: "1rem" },
        detailsContainerRoot: {
            paddingLeft: theme.typography.pxToRem(32),
            paddingRight: theme.typography.pxToRem(32),
        },
    });
};

interface MetadataConfigurationWrapperProps {
    orgId: string;
}

type Props = MetadataConfigurationWrapperProps;

const MetadataConfigurationWrapper: React.FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ orgId }) => {
    const classes = useStyles(styles);
    const metadataStore = useStore(OrganizationMetadataStore);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const toggleAccordion = () => {
        setIsOpen((prev) => !prev);
    };

    React.useEffect(() => {
        metadataStore.setOrgId(orgId);
    }, [metadataStore, orgId]);

    const isLoading =
        metadataStore.getTaskLoading(LOAD_CONFIG) ||
        metadataStore.getTaskLoading(SET_CONFIG);

    return (
        <Paper className={classes.metadataWrapperContainer}>
            <Accordion square expanded={isOpen} onChange={toggleAccordion}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                >
                    <Typography className={classes.heading}>
                        {isOpen ? (
                            <KeyboardArrowDownIcon />
                        ) : (
                            <KeyboardArrowRightIcon />
                        )}
                        Metadata Configuration
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.detailsContainerRoot}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            {metadataStore.organizationMetadataConfiguration.map(
                                (config, index) => (
                                    <MetadataConfiguration
                                        key={uuidv4()}
                                        metadataStore={metadataStore}
                                        configIndex={index}
                                    />
                                ),
                            )}
                        </Grid>

                        <Grid item xs={12} container justifyContent="flex-end">
                            <AcxButton
                                onClick={() => metadataStore.handleFileAdd()}
                                color="primary"
                                style={{
                                    margin: "0.5rem 1rem 0.5rem 0.25rem",
                                }}
                            >
                                Add File
                            </AcxButton>
                            <AcxButton
                                onClick={
                                    metadataStore.setOrganizationMetadataConfiguration
                                }
                                color="primary"
                                loading={isLoading}
                                disabled={
                                    isLoading ||
                                    metadataStore.organizationMetadataFields
                                        ?.length === 0
                                }
                                style={{
                                    margin: "0.5rem 0.25rem 0.5rem 0.25rem",
                                }}
                            >
                                Save
                            </AcxButton>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
});

export default MetadataConfigurationWrapper;
