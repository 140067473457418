import { Module } from "components/Admin/Organizations/types/Module.type";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { useCallback } from "react";
import useList from "shared/hooks/useList";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "utils/useStore";
import ModuleService from "../Module.service";

const useOrganizationModules = (orgId: string) => {
    const messageStore = useStore(MessageStore);

    const getData = useCallback(async () => {
        return ModuleService.getModules(orgId);
    }, [orgId]);

    const {
        isLoading,
        data: modules,
        refetch,
    } = useList<Module>({
        get: getData,
    });

    const duplicate = async (module: Module) => {
        const id = module.id;
        try {
            const res: any = await ModuleService.getModule(id);

            const tagMap = new Map();
            res.questions?.forEach((q) => {
                tagMap.set(q.id, q.tags);
            });

            const questions = res.questions?.map((q) => {
                q.tags = tagMap.get(q.id).map((tag) => {
                    delete tag["id"];
                    return tag;
                });

                q["id"] = uuidv4();
                const answerTypeId = q.answerType?.id;
                const newQ = {
                    ...q,
                    answerTypeId,
                };

                delete newQ.answerType;
                return newQ;
            });

            await ModuleService.createModule({
                organizationId: orgId,
                name: res.name,
                lmType: res.lmType,
                organizationStructureMemberIds:
                    res.organizationStructureMemberIds,
                questions,
            });

            messageStore.logMessage(
                `Successfully duplicated module`,
                "success",
            );
            refetch();
        } catch (err) {
            //@ts-ignore
            messageStore.logError(err.message);
        }
    };

    return {
        modules,
        isLoading,
        refetch,
        duplicate,
    };
};

export default useOrganizationModules;
