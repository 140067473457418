import {
    IconButton,
    InputBase,
    Popover,
    SxProps,
    Theme,
    Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WarningIcon from "@mui/icons-material/Warning";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import React, { CSSProperties, ReactNode } from "react";
import theme from "Theme/AppTheme";
import hexToRGB from "utils/hexToRGB";
import AcxInputLabel from "./AcxInputLabel";

const useStyles = makeStyles((props: IAcxTextFieldProps) => ({
    inputText: (props: IAcxTextFieldProps) => ({
        border: "none",
        borderWidth: 0,
        outlineWidth: 0,
        backgroundColor: "transparent",
        flex: "1 1 auto",
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: theme.typography.fontFamily,
        paddingLeft: props.disablePadding ? "0px" : theme.spacing(1),
    }),
    inputTextFocused: {
        caretColor: theme.palette.primary.main,
    },
    inputTextAlignRight: {
        textAlign: "right",
    },
    inputBaseAlignRight: {
        paddingLeft: "0px",
        paddingRight: theme.spacing(1),
    },
    inputTextAlignCenter: {
        textAlign: "center",
    },
    inputBase: {
        paddingTop: "7px",
        paddingBottom: "7px",
    },
    textContainer: {
        border: "1px solid",
        backgroundColor: theme.palette.white.main,
        borderColor: theme.palette.lightgrayBorder.main,
        borderRadius: `${theme.shape.borderRadius}px`,
        display: "flex",
        minHeight: "32px",
        alignItems: "center",
        "&:Focused": {
            borderColor: hexToRGB(theme.palette.primary.main, 0.7),
        },
    },
    textContainerFocused: {
        borderColor: hexToRGB(theme.palette.primary.main, 0.7),
    },
    textLabel: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontSize: "12px",
        lineHeight: "16px",
    },
    required: {
        "&:after": {
            color: theme.palette.red.main,
            content: '" *" !important',
        },
    },
    textLabelFocused: {
        color: theme.palette.primary.main,
    },

    helperText: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontSize: "12px",
        lineHeight: "16px",
    },
    helperTextError: {
        color: `${theme.palette.error.main} !important`,
    },
    labelContainer: {
        opacity: 1,
    },
    warningIcon: {
        color: theme.palette.red.main,
    },
}));

interface IAcxTextFieldProps {
    labelText?: string | undefined;
    error?: boolean;
    errorHelper?: string;
    placeholderText?: string;
    id: string;
    value: string | number;
    helperText?: string | undefined;
    inputProps?: Record<string, string | number>;
    required?: boolean;
    type?:
        | string
        | (
              | "text"
              | "time"
              | "url"
              | "tel"
              | "number"
              | "email"
              | "date"
              | "password"
          );
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (
        evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    containerClass?: string;
    containerStyle?: CSSProperties | undefined;
    focus?: boolean;
    textContainerClass?: string;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    helpPopperText?: string | ReactNode;
    autofocus?: boolean;
    readonly?: boolean;
    shrink?: boolean;
    infoText?: string;
    multiline?: boolean;
    rows?: number;
    rowsMin?: number;
    rowsMax?: number;
    rightAlignInputText?: boolean;
    centerAlignInputText?: boolean;
    showAllErrors?: boolean;
    skeleton?: boolean;
    onBlur?: (val?: string | number) => void;
    onFocus?: (val?: string | number) => void;
    onLeaveRight?: () => void;
    onLeaveLeft?: () => void;
    textareaRootStyles?: CSSProperties;
    fullWidth?: boolean;
    isDisabled?: boolean;
    maxLength?: number;
    /**
     * If set to true, this will prevent a text field of type number from auto-setting itself to 0 if the value is blank.
     */
    insertBlankIfNumber?: boolean;
    disablePadding?: boolean;
    blurOnEnterPress?: boolean;
    autoComplete?: string;
    labelSx?: SxProps<Theme>;
}

export default function AcxMainTextField(props: IAcxTextFieldProps) {
    const classes = useStyles(props);
    const [focused, setFocused] = React.useState(false);
    const [helpAnchorEl, setHelpAnchorEl] =
        React.useState<HTMLButtonElement | null>(null);

    const [value, setValue] = React.useState<string | number>(
        props.type === "number" && !props.insertBlankIfNumber ? 0 : "",
    );
    const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();

    React.useEffect(() => {
        if (props.value !== value) {
            setValue(
                props.type === "number" && !props.insertBlankIfNumber
                    ? props.value || 0
                    : props.value ?? "",
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    React.useEffect(() => {
        if (props.focus === true && inputRef.current) {
            inputRef.current.focus?.();
            inputRef.current.selectionStart = 0;
        }
    }, [props.focus]);

    const onBlur = () => {
        setFocused(false);
        props.onBlur?.(
            props.type === "number" && !props.insertBlankIfNumber
                ? Number(value || 0)
                : value,
        );
    };
    const onFocus = () => {
        setFocused(true);
        props.onFocus?.(value);
    };
    const onKeyPress = (
        event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (event && event.currentTarget.selectionStart) {
            if (event.key === "ArrowRight") {
                if (
                    value?.toString().length -
                        event.currentTarget.selectionStart ===
                    0
                ) {
                    props.onLeaveRight?.();
                }
            }
            if (event.key === "ArrowLeft") {
                // console.log("caret location: " + event.currentTarget.selectionStart);
                if (event.currentTarget.selectionStart === 1) {
                    props.onLeaveLeft?.();
                }
            }
            if (props.blurOnEnterPress && event.key === "Enter") {
                onBlur();
                inputRef.current?.blur();
            }
        }

        if (event) {
            props.onKeyDown?.(event);
        }
    };
    const getHelpPopperContent = () => {
        if (props.helpPopperText) {
            if (React.isValidElement(props.helpPopperText)) {
                return props.helpPopperText;
            } else {
                return <div>{props.helpPopperText}</div>;
            }
        }
        return null;
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setHelpAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setHelpAnchorEl(null);
    };
    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setValue(evt.currentTarget.value);
        props.onChange?.(evt);
    };
    const open = Boolean(helpAnchorEl);

    return (
        <div className={props.containerClass} style={props.containerStyle}>
            <div className={classes.labelContainer}>
                {props.labelText && (
                    <AcxInputLabel
                        htmlFor={props.id}
                        id={props.id}
                        labelText={props.labelText ?? ""}
                        focused={focused}
                        shrink={props.shrink}
                        className={clsx({
                            [classes.textLabelFocused]: focused,
                            [classes.required]:
                                props.required && !inputRef.current?.value,
                            [classes.textLabel]: true,
                        })}
                        infoText={props.infoText}
                        sx={props.labelSx}
                    />
                )}
            </div>
            {props.skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={32}
                    animation="wave"
                    style={{ borderRadius: 4 }}
                />
            ) : (
                <div
                    className={clsx(
                        {
                            [classes.textContainer]: !props.textContainerClass,
                            [classes.textContainerFocused]: focused,
                        },
                        props.textContainerClass,
                    )}
                    style={
                        props.fullWidth
                            ? {}
                            : props.textareaRootStyles ?? {
                                  marginRight: "0.5rem",
                              }
                    }
                >
                    <InputBase
                        disabled={props.isDisabled ?? false}
                        fullWidth={props.fullWidth ?? false}
                        id={props.id}
                        inputProps={{
                            ...{ type: props.type ?? "text" },
                            className: clsx(
                                {
                                    [classes.inputTextAlignRight]:
                                        props.rightAlignInputText,
                                },
                                {
                                    [classes.inputTextAlignCenter]:
                                        props.centerAlignInputText,
                                },
                                classes.inputBase,
                            ),
                            ...props.inputProps,
                            maxLength: props.maxLength,
                        }}
                        placeholder={props.placeholderText}
                        startAdornment={props.startAdornment}
                        className={clsx({
                            [classes.inputText]: true,
                            [classes.inputTextFocused]: focused,
                            [classes.inputBaseAlignRight]:
                                props.rightAlignInputText,
                        })}
                        inputRef={inputRef}
                        type={props.type}
                        autoFocus={props.autofocus}
                        autoComplete={props.autoComplete}
                        value={value}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                        onKeyDown={onKeyPress}
                        readOnly={props.readonly}
                        endAdornment={
                            !!props.endAdornment
                                ? props.endAdornment
                                : props.helpPopperText && (
                                      <IconButton
                                          onClick={handleClick}
                                          size="small"
                                      >
                                          <HelpOutlineIcon fontSize="small" />
                                      </IconButton>
                                  )
                        }
                        multiline={props.multiline}
                        // rows={props.rows}
                        minRows={props.rows ?? props.rowsMin}
                        maxRows={props.rowsMax}
                    />

                    {props.error && !props.rightAlignInputText && (
                        <Tooltip title={props.errorHelper || "Error"}>
                            <WarningIcon className={classes.warningIcon} />
                        </Tooltip>
                    )}
                </div>
            )}
            {props.helperText !== undefined && (
                <AcxInputLabel
                    id={"AcxMainText-BottomLabel"}
                    className={clsx({
                        [classes.helperText]: true,
                        [classes.helperTextError]: props.error,
                    })}
                    showAllErrors={!!props.showAllErrors}
                >
                    {props.helperText}
                </AcxInputLabel>
            )}
            <Popover
                id={"help-popover-" + props.id}
                open={open}
                anchorEl={helpAnchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                {getHelpPopperContent()}
            </Popover>
        </div>
    );
}

AcxMainTextField.defaultProps = {
    error: false,
    labelText: "",
    placeholderText: "",
    value: "",
    required: false,
    type: "text",
    containerClass: "",
    readonly: false,
    shrink: false,
    multiline: false,
    rowsMin: 2,
    rowsMax: 4,
};
