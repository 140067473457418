import { User } from "oidc-client";
import authService from "../components/Auth/api-authorization/AuthorizeService";
export const getTokenHeader = async (): Promise<Record<string, string>> => {
    const token: string | null = await authService.getAccessToken();
    const orgId = await authService.getOrganizationId();

    let head: any;

    if (token) {
        head = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Acx-OrganizationId": orgId!,
        };
    } else {
        head = { "Content-Type": "application/json" };
    }

    return head;
};

export async function populateAuth(): Promise<Auth> {
    const [isAuthenticated, user] = await Promise.all([
        authService.isAuthenticated(),
        authService.getUserObject(),
    ]);

    // @ts-expect-error: user is possibly undefined
    return { isAuthenticated, user };
}

export async function getOrgId(auth?: Auth) {
    const authResult = auth ? await auth : await populateAuth();
    return authResult?.user?.profile?.OrgId;
}

export async function isSuperUser(auth?: Auth): Promise<boolean> {
    const authResult = auth ? await auth : await populateAuth();

    const roles: any[] = authResult?.user?.profile?.role ?? [];
    const sup = roles?.filter?.(
        (val, index) => val === "Superusers" || val === "Ultrausers",
    );
    return sup?.length > 0;
}

export interface Auth {
    isAuthenticated: boolean;
    user: User;
}

export default class AuthUtils {
    public static async getHeaderAsync(): Promise<Record<string, string>> {
        const token: string | null = await authService.getAccessToken();
        const orgId = await authService.getOrganizationId();
        let head: any;
        if (token) {
            head = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Acx-OrganizationId": orgId!,
            };
        } else {
            head = { "Content-Type": "application/json" };
        }
        return head;
    }
}
