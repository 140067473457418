import * as _ from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import { isUndefinedType } from "../utils/TypeGuards";
import { AnswerTag } from "./AnswerTag";
import BaseEntity from "./BaseEntity";
import Evaluation from "./Evaluation";
import EvaluationModule from "./EvaluationModule";
import { PredictedTag } from "./PredictedTag";
import Question from "./Question";
import SoundClip from "./SoundClip";
import { SoundClipAnswer } from "./SoundClipAnswer";
import { Tag } from "./Tag";
import AnswerDispute from "./AnswerDispute";

export enum AnswerAlert {
    PositiveAlert,
    NegativeAlert,
}

export class Answer extends BaseEntity {
    evaluationId?: string;
    evaluation?: Evaluation;
    questionId: string;
    question: Question;
    weightedScore?: number | null;

    evaluationModuleId?: string;
    conversationModuleId?: string;
    evaluationModule?: EvaluationModule;
    fillInAnswerValue?: string | null;

    @observable note?: string;
    @observable answerAlert?: AnswerAlert | null;
    @observable answerTags: AnswerTag[];
    predictedTags: PredictedTag[];
    @observable soundClipAnswers: SoundClipAnswer[];
    @observable isDisputed?: boolean;
    @observable disputeJustification?: string;
    @observable answerDisputes?: AnswerDispute[];

    constructor(
        evaluationId: string | undefined,
        questionId: string,
        question: Question,
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        fillInAnswerValue?: string,
        isDisputed?: boolean,
        disputeJustification?: string,
    ) {
        super(id, createdBy, modifiedBy);
        makeObservable(this);
        this.evaluationId = evaluationId;
        this.questionId = questionId;
        this.question = question;
        this.fillInAnswerValue = fillInAnswerValue;

        this.answerTags = [];
        this.predictedTags = [];
        this.soundClipAnswers = [];
        this.isDisputed = isDisputed ? isDisputed : false;
        this.disputeJustification = disputeJustification
            ? disputeJustification
            : "";
    }

    @action
    setSoundClips(clips: SoundClip[] | undefined) {
        if (isUndefinedType(clips)) {
            clips = [];
        }

        if (isUndefinedType(this.soundClipAnswers)) {
            this.soundClipAnswers = [];
        }

        const existingSoundClips = new Set(
            this.soundClipAnswers
                .map((value) => value.soundClip)
                .map((value) => value!.clipIdentifier),
        );
        const newClips = new Set(clips.map((value) => value.clipIdentifier));

        const newlyAdded = new Set(
            [...newClips].filter((x) => !existingSoundClips.has(x)),
        );
        const removed = new Set(
            [...existingSoundClips].filter((x) => !newClips.has(x)),
        );

        const intersection = new Set(
            [...existingSoundClips].filter((x) => newClips.has(x)),
        );

        for (const intersectionId of intersection) {
            for (const clipAns of this.soundClipAnswers) {
                if (clipAns.soundClip?.clipIdentifier === intersectionId) {
                    clipAns.isActive = true;
                }
            }
        }
        for (const removedId of removed) {
            for (const clipAns of this.soundClipAnswers) {
                if (clipAns.soundClip?.clipIdentifier === removedId) {
                    if (!clipAns.id) {
                        const indx = this.soundClipAnswers.findIndex(
                            (value) =>
                                value.soundClip?.clipIdentifier === removedId,
                        );
                        if ((indx ?? -1) > -1) {
                            this.soundClipAnswers.splice(indx, 1);
                        }
                    }

                    clipAns.isActive = false;
                }
            }
        }

        for (const clip of clips.filter((value) =>
            newlyAdded.has(value.clipIdentifier),
        )) {
            const clipAns = new SoundClipAnswer(
                clip.id,
                this.id,
                undefined,
                this.createdBy,
                this.modifiedBy,
            );
            clipAns.setSoundClip(clip);
            this.soundClipAnswers = [...this.soundClipAnswers, clipAns];
        }
    }

    @computed
    get isExemplary() {
        return this.answerAlert === AnswerAlert.PositiveAlert;
    }

    @computed
    get isNeedsAttention() {
        return this.answerAlert === AnswerAlert.NegativeAlert;
    }

    @action
    setAnswerTags(tags: Tag[]) {
        const scores: number[] = [];

        const existingAnsTags = new Set(
            this.answerTags
                .map((value) => value.tag)
                .map((value) => value?.id!),
        );
        const ansTags = new Set(tags.map((value) => value.id!));

        const newlyAdded = new Set(
            [...ansTags].filter((x) => !existingAnsTags.has(x)),
        );
        const removed = new Set(
            [...existingAnsTags].filter((x) => !ansTags.has(x)),
        );

        const intersection = new Set(
            [...existingAnsTags].filter((x) => ansTags.has(x)),
        );

        for (const intersectionId of intersection) {
            for (const answerTag of this.answerTags) {
                if (answerTag.tagId === intersectionId) {
                    answerTag.isActive = true;

                    const tag = answerTag.tag;
                    if (
                        tag?.scoredValue !== null &&
                        tag?.scoredValue !== undefined
                    ) {
                        scores.push(
                            (this.question.weight ?? 1) * tag.scoredValue,
                        );
                    }
                }
            }
        }
        for (const removedId of removed) {
            for (const answerTag of this.answerTags) {
                if (answerTag.tagId === removedId) {
                    if (!answerTag.id) {
                        const indx = this.answerTags.findIndex(
                            (value) => value.tagId === removedId,
                        );
                        if ((indx ?? -1) > -1) {
                            this.answerTags.splice(indx, 1);
                        }
                    }

                    answerTag.isActive = false;
                }
            }
        }

        for (const tag of tags.filter((value) => newlyAdded.has(value.id))) {
            if (tag?.scoredValue !== null && tag?.scoredValue !== undefined) {
                scores.push((this.question.weight ?? 1) * tag.scoredValue);
            }
            const answerTag = new AnswerTag(
                this.id,
                tag.id,
                undefined,
                this.createdBy,
                this.modifiedBy,
            );
            answerTag.setTag(tag);
            this.answerTags.push(answerTag);
        }

        if (scores.length) {
            this.weightedScore = parseFloat(
                (_.sum(scores) / scores.length).toFixed(2),
            );
        } else {
            this.weightedScore = null;
        }
    }

    @action
    setNote = (note?: string) => {
        this.note = note;
    };

    @action
    setAnwerAlert(answerAlert?: AnswerAlert | null) {
        this.answerAlert = answerAlert;
    }

    @action
    togglePositiveAlert = () => {
        if (this.answerAlert === AnswerAlert.PositiveAlert) {
            this.answerAlert = null;
        } else {
            this.answerAlert = AnswerAlert.PositiveAlert;
        }
    };

    @action
    toggleNegativeAlert = () => {
        if (this.answerAlert === AnswerAlert.NegativeAlert) {
            this.answerAlert = null;
        } else {
            this.answerAlert = AnswerAlert.NegativeAlert;
        }
    };

    @action
    toggleIsDisputed = () => {
        this.isDisputed = !this.isDisputed;
    };

    @computed
    get hasNote() {
        return Boolean(this.note);
    }

    @computed
    get hasClips() {
        return (
            this.soundClipAnswers.filter((value) => value.isActive).length > 0
        );
    }

    @computed
    get activeSoundClips() {
        return this.soundClipAnswers
            .filter((value) => value.isActive)
            .map((value) => value.soundClip)
            .filter((value) => value && value.isActive) as SoundClip[];
    }

    @computed
    get isAnswered() {
        return (
            this.activeAnswerTags.length > 0 ||
            (this.question.answerType.isFillInAnswer && this.fillInAnswerValue)
        );
    }

    @computed
    get activeTags() {
        return this.activeAnswerTags
            .map((value) => value.tag)
            .filter((value) => value) as Tag[];
    }

    @computed
    get hasAccuratePredictions() {
        const at = new Set(this.activeTags.map((value) => value.id));
        const pt = new Set(this.predictedTags.map((value) => value.tagId) ?? []);
        const intersection = new Set([...at].filter((x) => pt.has(x)));

        return intersection.size > 0;
    }

    @computed
    get activeAnswerTags() {
        return this.answerTags.filter((value) => value.isActive);
    }

    setQuestion(question: Question) {
        this.question = question;
    }

    static fromJson(answerJson: Answer) {
        const ansCls = new Answer(
            answerJson.evaluationId,
            answerJson.questionId,
            answerJson.question,
        );
        copyBaseFields(answerJson, ansCls);
        ansCls.answerDisputes = answerJson.answerDisputes;
        ansCls.note = answerJson.note;
        ansCls.answerAlert = answerJson.answerAlert;
        ansCls.fillInAnswerValue = answerJson.fillInAnswerValue;

        ansCls.weightedScore = answerJson.weightedScore;
        ansCls.answerTags =
            answerJson.answerTags?.map((value) => AnswerTag.fromJson(value)) ??
            [];

        ansCls.predictedTags = answerJson.predictedTags ?? [];
        ansCls.soundClipAnswers =
            answerJson.soundClipAnswers?.map((value) =>
                SoundClipAnswer.fromJson(value),
            ) ?? [];

        ansCls.evaluationModuleId = answerJson.evaluationModuleId;
        ansCls.conversationModuleId = answerJson.conversationModuleId;

        ansCls.isDisputed = answerJson.isDisputed;

        return ansCls;
    }
}
