import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ModuleUIModel } from "components/Evaluation/Models/ModuleUIModel";
import AcxWideTooltip from "components/UI/AcxWideTooltip";
import { WorkflowReviewStore } from "components/WorkflowReview/Stores/WorkflowReviewStore";
import _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import { Answer, AnswerAlert } from "../../../../models/Answer";
import {
    AnswerTypeNames,
    AnswerTypeVariations,
} from "../../../../models/AnswerType";
import Question, { QuestionType } from "../../../../models/Question";
import { Tag, TagSentiment } from "../../../../models/Tag";
import InfoSvg from "../../../../SvgIcons/InfoSvg";
import theme from "../../../../Theme/AppTheme";
import { useStore } from "../../../../utils/useStore";
import AcxMainTextField from "../../../UI/AcxMainTextField";
import AcxCalendarPopup from "../../../UI/Calendar/AcxCalendarPopup";
import AcxSelectMulti from "../../../UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingle from "../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import WFRAIExplanationCards from "../WFRAIExplanationCard/WFRAIExplanationCards";
import WFRQuoteNote from "../WFRQuoteNote";
import WFRQuoteNoteButtonContainer from "../WFRQuoteNoteButtonContainer";
import WFRSmartPredictToggle from "../WFRSmartPredictToggle";

interface StyleProps {
    standalone?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {},
    padding3: {
        paddingLeft: theme.spacing(3),
    },
    questionText: (props) => ({
        color: "#1F1F1F",
        fontSize: props.standalone ? "12px" : "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    }),
    fullSize: {
        height: "100%",
        width: "100%",
    },
    questionHeader: (props) => ({
        marginBottom: props.standalone ? theme.spacing(0.25) : theme.spacing(1),
        marginTop: props.standalone ? theme.spacing(0.5) : theme.spacing(2),
        paddingRight: theme.spacing(3.5),
    }),
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    sPContainer: {
        display: "flex",
        flexDirection: "row",
        wrap: "nowrap",
        alignItems: "center",
    },
}));

interface WFRTagOrFillInResponseAnswerProps {
    question: Question;
    moduleUIModel?: ModuleUIModel;
    standalone?: boolean;
    leftBorderColor?: string;
    isDisabled?: boolean;
    isNoteDisabled?: boolean;
}

const selectCustomStyle = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
};

const WFRTagOrFillInResponseAnswer: React.FC<WFRTagOrFillInResponseAnswerProps> =
    observer((props) => {
        const classes = useStyles({ standalone: props.standalone });
        const store = useStore(WorkflowReviewStore);
        const answer = store.getAnswerForQuestion(
            props.question,
            props.moduleUIModel?.licensedModule.evaluationModuleId,
        );

        let activeTags: Tag | Tag[] | undefined = answer?.activeTags;
        const firstActiveTag =
            answer?.activeTags[0] ??
            store.tags.find(
                (tag) => tag.id === answer?.activeAnswerTags[0]?.tagId,
            );

        if (
            props.question.answerType.variation === AnswerTypeVariations.Single
        ) {
            activeTags =
                (activeTags?.length ?? 0) > 0 ? activeTags?.[0] : undefined;
        }

        const isSmartPredicted = answer?.hasAccuratePredictions;

        const [showNote, setShowNote] = useState<boolean>(
            Boolean(answer?.hasNote),
        );
        const [showQuote, setShowQuote] = useState<boolean>(
            Boolean(answer?.hasClips),
        );
        const [flagActive, setFlagActive] = useState<boolean>(
            answer?.answerAlert === AnswerAlert.PositiveAlert ||
                answer?.answerAlert === AnswerAlert.NegativeAlert,
        );

        useEffect(() => {
            setShowNote(!!answer?.hasNote);
            setShowQuote(!!answer?.hasClips);
        }, [answer, answer?.soundClipAnswers]);

        useEffect(() => {
            if (firstActiveTag && answer && flagActive) {
                if (
                    firstActiveTag.sentiment === TagSentiment.Neutral ||
                    firstActiveTag.sentiment === TagSentiment.Positive
                ) {
                    answer.answerAlert = AnswerAlert.PositiveAlert;
                } else {
                    answer.answerAlert = AnswerAlert.NegativeAlert;
                }
            }
        }, [firstActiveTag, answer, flagActive]);

        function toggleQuoteField() {
            setShowQuote((prevState) => !prevState);
        }

        function toggleNote() {
            setShowNote((prevState) => !prevState);
        }

        const toggleAnswerAlert = () => {
            if (flagActive === true) {
                setFlagActive(false);
                if (answer) answer.answerAlert = null;
            } else {
                if (firstActiveTag && answer) {
                    setFlagActive(true);
                    if (
                        firstActiveTag.sentiment === TagSentiment.Neutral ||
                        firstActiveTag.sentiment === TagSentiment.Positive
                    ) {
                        answer.answerAlert = AnswerAlert.PositiveAlert;
                    } else {
                        answer.answerAlert = AnswerAlert.NegativeAlert;
                    }
                }
            }
        };

        const bgColor = showNote || showQuote ? "unset" : "unset";

        let showAIExplanationCard = store.showAIExplanation[props.question.id];

        useEffect(() => {
            if (Array.isArray(activeTags)) {
                props.moduleUIModel?.updateModuleScore(
                    props.question,
                    activeTags,
                );
            } else {
                props.moduleUIModel?.updateModuleScore(
                    props.question,
                    activeTags ? [activeTags] : [],
                );
            }
        }, [props.question, props.moduleUIModel, activeTags]);

        return (
            <Grid
                id={`question-${props.question.id}`}
                item
                xs={12}
                style={{
                    backgroundColor: bgColor,
                    paddingLeft:
                        props.question.parentId || props.leftBorderColor
                            ? "16px"
                            : "0px",
                    borderLeft: props.leftBorderColor
                        ? `2px solid ${props.leftBorderColor}`
                        : "none",
                }}
                container
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems="stretch"
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    wrap={"nowrap"}
                    className={clsx(classes.questionHeader, {
                        [classes.padding3]: !props.standalone,
                    })}
                >
                    <AcxWideTooltip
                        title={
                            sanitizeHtml(props.question.helperText) ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            props.question.helperText,
                                            {
                                                allowedAttributes: {
                                                    table: ["style"],
                                                    th: ["style"],
                                                    td: ["style"],
                                                },
                                                allowedStyles: {
                                                    "*": {
                                                        // Match any number with px, em, or %
                                                        width: [
                                                            /^\d+(?:px|em|%)$/,
                                                        ],
                                                        border: [
                                                            /^1px solid rgba\(0, 0, 0, 1\)$/,
                                                        ],
                                                    },
                                                },
                                            },
                                        ),
                                    }}
                                />
                            ) : (
                                ""
                            )
                        }
                        placement={"top"}
                    >
                        <Grid item className={classes.infoSVG}>
                            <InfoSvg width="24px" height="24px" />
                        </Grid>
                    </AcxWideTooltip>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item>
                                <Typography className={classes.questionText}>
                                    {props.question?.questionText}{" "}
                                    {props.question.required &&
                                        !answer?.isAnswered && (
                                            <span
                                                style={{
                                                    color: theme.palette.error
                                                        .main,
                                                }}
                                            >
                                                *
                                            </span>
                                        )}
                                </Typography>
                            </Grid>

                            <WFRSmartPredictToggle
                                question={props.question}
                                moduleUIModel={props.moduleUIModel}
                            />
                        </Grid>
                    </Grid>

                    {props.question.type === QuestionType.QuestionHeading && (
                        <WFRQuoteNoteButtonContainer
                            toggleNote={toggleNote}
                            showNote={showNote}
                            toggleQuote={toggleQuoteField}
                            showQuote={showQuote}
                            toggleAnswerAlert={toggleAnswerAlert}
                            flagActive={flagActive}
                            restrictMedia={
                                !store.authStore.isUserUltra() &&
                                store.authStore.canUserView("Restrict Media")
                            }
                            hideNoteButton={
                                props.isNoteDisabled && !answer?.note
                            }
                        />
                    )}
                </Grid>
                {props.question.type !== QuestionType.QuestionHeading && (
                    <Grid
                        item
                        xs={12}
                        direction={"row"}
                        wrap={"nowrap"}
                        container
                        justifyContent={
                            props.standalone ? "flex-start" : "space-between"
                        }
                        alignItems={"center"}
                        className={clsx({
                            [classes.padding3]: !props.standalone,
                        })}
                    >
                        <Grid
                            item
                            sm={props.standalone ? 12 : 7}
                            md={props.standalone ? 12 : 8}
                            lg={props.standalone ? 12 : 9}
                            xl={props.standalone ? 12 : 10}
                            style={{ marginBottom: "8px" }}
                        >
                            <AnswerSelector
                                answer={answer}
                                store={store}
                                activeTags={activeTags}
                                question={props.question}
                                standalone={props.standalone}
                                leftBorderColor={props.leftBorderColor}
                                moduleUIModel={props.moduleUIModel}
                                isDisabled={props.isDisabled}
                            />
                        </Grid>
                        {!props.standalone && (
                            <Grid
                                item
                                xs={2}
                                container
                                justifyContent="space-evenly"
                                wrap={"nowrap"}
                                style={{ marginRight: "16px" }}
                            >
                                <WFRQuoteNoteButtonContainer
                                    toggleNote={toggleNote}
                                    showNote={showNote}
                                    toggleQuote={toggleQuoteField}
                                    showQuote={showQuote}
                                    toggleAnswerAlert={toggleAnswerAlert}
                                    flagActive={flagActive}
                                    restrictMedia={
                                        !store.authStore.isUserUltra() &&
                                        store.authStore.canUserView(
                                            "Restrict Media",
                                        )
                                    }
                                    hideNoteButton={
                                        props.isNoteDisabled && !answer?.note
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                {!props.standalone && (
                    <WFRQuoteNote
                        question={props.question}
                        answer={answer}
                        showNote={showNote}
                        showQuote={showQuote}
                        noteXs={12}
                        columnDirection={"column-reverse"}
                        isDisabled={props.isNoteDisabled}
                        evaluationModuleId={
                            props.moduleUIModel?.licensedModule
                                .evaluationModuleId
                        }
                    />
                )}
                {isSmartPredicted && showAIExplanationCard && activeTags && (
                    <WFRAIExplanationCards
                        activeTags={
                            !Array.isArray(activeTags)
                                ? [activeTags]
                                : activeTags
                        }
                        predictedTags={answer.predictedTags}
                        questionId={props.question.id}
                        answer={answer}
                    />
                )}
            </Grid>
        );
    });

type AnswerSelectorProps = WFRTagOrFillInResponseAnswerProps & {
    children?: React.ReactNode;
    store: WorkflowReviewStore;
    answer: Answer | undefined;
    activeTags: Tag | Tag[] | undefined;
};

const AnswerSelector: React.FC<AnswerSelectorProps> = observer((props) => {
    const evalModuleId = props.moduleUIModel?.licensedModule.evaluationModuleId;

    const onFillInAnswerSelect = useCallback(
        (fillInAnswer: string) => {
            props.store.setFillInAnswerForQuestion(
                props.question,
                fillInAnswer,
                props.answer,
                evalModuleId,
            );
        },
        [evalModuleId, props.answer, props.question, props.store],
    );

    const debounceOnChange = _.debounce(onFillInAnswerSelect, 500);

    if (props.question.answerType.variation === AnswerTypeVariations.Single) {
        function onSingleAnswerSelect(arg?: Tag) {
            props.store.setAnswerForQuestion(
                props.question,
                arg ? [arg] : [],
                props.answer,
                evalModuleId,
                props.store.enableAutoBindClips,
            );
        }

        return (
            <>
                <AcxSelectSingle
                    options={
                        props.moduleUIModel?.getTagsFormattedForTagResponse(
                            props.question.id,
                        ) ?? []
                    }
                    containerHeight={"auto"}
                    customStyle={selectCustomStyle}
                    menuPlacement={"auto"}
                    menuPortalTarget={document.body}
                    id={`tag-response-${props.question.id}`}
                    fullWidth
                    colorField={
                        props.moduleUIModel?.moduleTypeName === "Interaction"
                            ? undefined
                            : "sentimentColor"
                    }
                    defaultValue={props.activeTags as any}
                    valueField={"id"}
                    labelField={"value"}
                    isClearable
                    onChange={onSingleAnswerSelect}
                    isDisabled={props.isDisabled}
                />
            </>
        );
    } else if (
        props.question.answerType.answerTypeName ===
        AnswerTypeNames.DateResponse
    ) {
        const currentDate = props.answer?.fillInAnswerValue
            ? moment(props.answer.fillInAnswerValue)
            : undefined;

        return (
            <AcxCalendarPopup
                id={`fill-in-response-${props.question.id}`}
                onSelect={(date) =>
                    onFillInAnswerSelect(date.format("YYYY-MM-DD"))
                }
                currentDate={currentDate}
                disabled={props.isDisabled}
            />
        );
    } else if (
        props.question.answerType.answerTypeName ===
        AnswerTypeNames.TextResponse
    ) {
        const value = props.answer?.fillInAnswerValue
            ? props.answer.fillInAnswerValue
            : undefined;

        return (
            <AcxMainTextField
                id={`fill-in-response-${props.question.id}`}
                fullWidth
                onChange={(evt) => debounceOnChange(evt.target.value)}
                multiline
                rows={3}
                value={value}
                maxLength={props.question.maxLength}
                isDisabled={props.isDisabled}
            />
        );
    } else {
        function onAnswerSelect(arg?: Tag[]) {
            props.store.setAnswerForQuestion(
                props.question,
                arg ?? [],
                props.answer,
                evalModuleId,
                props.store.enableAutoBindClips,
            );
        }

        return (
            <>
                <AcxSelectMulti
                    options={
                        props.moduleUIModel?.getTagsFormattedForTagResponse(
                            props.question.id,
                        ) ?? []
                    }
                    inputLabel={"Add tag(s)"}
                    containerHeight={"auto"}
                    customStyle={selectCustomStyle}
                    menuPlacement={"auto"}
                    menuPortalTarget={document.body}
                    id={`tag-response-${props.question.id}`}
                    fullWidth
                    colorField={"sentimentColor"}
                    defaultValue={props.activeTags as any}
                    valueField={"id"}
                    labelField={"value"}
                    isClearable
                    onChange={onAnswerSelect}
                    isDisabled={props.isDisabled}
                />
            </>
        );
    }
});

export default WFRTagOrFillInResponseAnswer;
