import { Breadcrumbs, Tooltip, Typography } from "@mui/material";
import { GridColTypeDef } from "@mui/x-data-grid-pro";
import AcxChipList from "components/UI/AcxChipList";
import { isObject } from "lodash";
import React from "react";
import { ArrayJoinComparator } from "../Formatters/ArrayJoinFormatter";
import { ChipComparator } from "../Formatters/TagFormatter";

export const breadCrumbColumnType: GridColTypeDef = {
    renderCell: (params) => {
        let values: string[];

        if (isObject(params)) {
            values = params.value;
            if (Array.isArray(values)) {
                // return formatter(values.filter((value, index) => !!value));
                const args = values.filter((value, index) => !!value);
                let toolTip = "";
                if (Array.isArray(args) && args.length > 0) {
                    toolTip = args.join(" > ");
                }

                return (
                    <Tooltip title={toolTip}>
                        <Breadcrumbs
                            style={{ whiteSpace: "nowrap" }}
                            separator="›"
                            aria-label="breadcrumb"
                        >
                            {args?.map((member) => {
                                return (
                                    <Typography key={`breadcrumb_${member}`}>
                                        {member}
                                    </Typography>
                                );
                            })}
                        </Breadcrumbs>
                    </Tooltip>
                );
            }
        }

        return null;
    },
    sortComparator: ArrayJoinComparator,
    display: "flex",
};

export const hierarchyColumnType: GridColTypeDef = {
    renderCell: (params) => {
        let initialHierarchiesFormat: string[][] = [];
        let hierarchies: string[];

        if (isObject(params)) {
            const value: { id: string; levels: string[] }[] = params.value;

            for (let index = 0; index < value.length; index++) {
                if (value[index].levels) {
                    initialHierarchiesFormat.push(
                        value[index].levels.filter((level) => !!level),
                    );
                }
            }

            if (initialHierarchiesFormat.length === 0) {
                initialHierarchiesFormat = [value] as any;
            }

            hierarchies = initialHierarchiesFormat.flatMap((hierarchyGroup) =>
                hierarchyGroup.join(" > "),
            );
        } else {
            hierarchies = params as any;
        }

        if (hierarchies.length === 1 && hierarchies[0] === "") {
            return [];
        }

        const res = (
            <AcxChipList
                size={"small"}
                color={"blue"}
                values={hierarchies}
                height="100%"
            />
        );

        return res;
    },
    sortComparator: ChipComparator,
};
