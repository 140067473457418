import { computed, makeObservable, observable } from "mobx";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import AcxModule from "./AcxModule";
import BaseEntity from "./BaseEntity";
import Question, { QuestionType } from "./Question";
import { Tag } from "./Tag";

export enum LMType {
    Always = 0, //always include this module
    Hierarchy = 1, //include when hierarchy matches
    Workflow = 2, // Boss Module
    "Safety Event" = 3,
}

export enum RenderPlacement {
    Top,
    Left,
    Right,
    Bottom,
    Middle,
}

export default class LicensedModule extends BaseEntity {
    acxModuleId: string;
    acxModule: AcxModule;
    organizationId: string;

    isWorkflowModule: boolean;

    lmType?: LMType;
    name?: string;
    description?: string;

    @observable.ref questions: Question[];
    renderPlacement: RenderPlacement;
    order: number;

    constructor(
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(id, createdBy, modifiedBy, createdOn, modifiedOn);

        makeObservable(this);
    }

    @computed
    get sortedQuestion() {
        const orderQuestionMap: Map<number, Question[]> = new Map<
            number,
            Question[]
        >();

        const headingQuestions =
            this.questions?.filter(
                (value) => value.type === QuestionType.QuestionHeading,
            ) ?? [];
        for (const headingQuestion of headingQuestions) {
            const sortedQuestions: Question[] = [];
            sortedQuestions.push(headingQuestion);

            const headingChildren =
                this.questions?.filter(
                    (value) => value.parentId === headingQuestion.id,
                ) ?? [];
            sortedQuestions.push(...headingChildren);
            orderQuestionMap.set(headingQuestion.order, sortedQuestions);
        }

        const alreadyConsumed: Question[] = [
            ...orderQuestionMap.values(),
        ].flatMap((value) => value);
        for (const question of this.questions.filter(
            (q) => !alreadyConsumed.some((value) => value.id === q.id),
        )) {
            if (orderQuestionMap.has(question.order)) {
                const qs = orderQuestionMap.get(question.order) ?? [];
                orderQuestionMap.set(question.order, qs.concat(question));
            } else {
                orderQuestionMap.set(question.order, [question]);
            }
        }

        const sortedQuestions: Question[] = [];
        for (const entry of [...orderQuestionMap.entries()].sort(
            (a, b) => a[0] - b[0],
        )) {
            sortedQuestions.push(...entry[1]);
        }

        return sortedQuestions;
    }

    static fromJson(moduleJson: LicensedModule) {
        const clz = new LicensedModule(
            moduleJson.id,
            moduleJson.createdBy,
            moduleJson.modifiedBy,
            moduleJson.createdOn,
            moduleJson.modifiedOn,
        );
        copyBaseFields(moduleJson, clz);
        clz.acxModuleId = moduleJson.acxModuleId;
        clz.acxModule = moduleJson.acxModule;
        clz.organizationId = moduleJson.organizationId;
        clz.questions = moduleJson.questions;
        clz.name = moduleJson.name;
        clz.lmType = moduleJson.lmType;
        clz.questions.forEach((ques) => {
            ques.tags = ques.tags.map((t) => Tag.fromJson(t));
        });

        clz.order = moduleJson.order;
        clz.renderPlacement =
            moduleJson.renderPlacement ?? RenderPlacement.Middle;

        clz.isWorkflowModule = moduleJson.isWorkflowModule;

        return clz;
    }
}
