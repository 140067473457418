import { Grid } from "@mui/material";
import AgentChatStore from "components/Agent/Stores/AgentChatStore";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import React, { useState, useEffect } from "react";
import { useStore } from "utils/useStore";
import { Suggestion } from "../Suggestions/Suggestion";
import AcxButton from "components/UI/AcxButton";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react";
import {
    OrganizationCustomerTypeStore
} from "../../Admin/Organizations/OrganizationDetail/OrganizationCustomerTypes/stores/OrganizationCustomerTypeStore";
import {ApplicationFilterValuesStore} from "../../../stores/ApplicationFilters/ApplicationFilterValuesStore";

export const SelectContactTypesAction = observer(({args}) => {
    const store = useStore(AgentChatStore);
    const contactTypesStore = useStore(OrganizationCustomerTypeStore);
    const applicationFilterValuesStore = useStore(ApplicationFilterValuesStore);
    const [contactTypes, setContactTypes] = useState<{ label: string, value: string }[] | undefined>(
        undefined,
    );
    const [dialogOpen, setDialogOpen] = useState(false);
    useEffect(() => {
        if (!dialogOpen) return;
        contactTypesStore.loadCustomerTypes();
    }, [dialogOpen, contactTypesStore]);

    return (
        <React.Fragment>
            <AcxDialog
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title="Contact Types"
                maxWidth="lg"
                dialogContentChildren={
                    <AcxSelectMulti
                        id="ava-contact-types-multi-select"
                        fullWidth
                        defaultValue={contactTypes}
                        isLoading={contactTypesStore.getTaskLoading(
                            OrganizationCustomerTypeStore.Tasks.LOAD_CUSTOMER_TYPES,
                        )}
                        options={applicationFilterValuesStore.contactTypeOptions}
                        onChange={(options) => {
                            setContactTypes(!!options ? options : []);
                        }}
                        valueField="value"
                        labelField="label"
                        containerHeight="auto"
                        maxContainerHeight="96px"
                        alignControls="flex-start"
                        width="24rem"
                        isClearable={true}
                    />
                }
            >
                <AcxButton
                    fullWidth={false}
                    onClick={() => {
                        if (!contactTypes || contactTypes.length === 0) return;
                        store.streamingSubmitInput({
                            userMessage: contactTypes.map((t) => t.label).join(", "),
                            manualInput: `Use the following contact types: [${contactTypes
                                .map((t) => '"' + t.value + '"')
                                .join(", ")}]`,
                        });
                    }}
                    disabled={!contactTypes || contactTypes.length === 0}
                >
                    Use selected contact types
                </AcxButton>
            </AcxDialog>
            <Grid item>
                <Suggestion onClick={() => setDialogOpen((open) => !open)}>
                    {args?.buttonText || "Select Contact Types"}
                </Suggestion>
            </Grid>
        </React.Fragment>
    );
});
