import SoundClip from "models/SoundClip";
import { Moment } from "moment";
import { serializeToUtc } from "utils/DateTimeUtils";
import { StorageAccountUseOptions } from "../models/StorageAccount";
import BaseService from "./BaseService";

export class SoundClipMedia {
    mediaUrl: string;
    soundClipId: string;
}

class CreateSoundClipResponse {
    mediaUrl: string;
    soundClip: SoundClip;
}

export interface UpdateSoundClipRequest {
    id: string;
    startTime: number;
    endTime: number;
    segmentName: string;
    transcriptionText: string;
    note: string;
    evaluationId?: string;
    audioMetadataId: string;
}

export class SoundClipService extends BaseService {
    protected urlBase: string = "api/SoundClips";
    private getAllSoundClipsOutstanding: AbortController;

    async getAllSoundClips(
        beginDate: Moment,
        endDate: Moment,
        qbEvalId: string = "",
    ) {
        if (this.getAllSoundClipsOutstanding) {
            this.getAllSoundClipsOutstanding.abort();
        }
        this.getAllSoundClipsOutstanding = new AbortController();
        const begin = serializeToUtc(beginDate);
        const end = serializeToUtc(endDate);

        const res = await this.get<SoundClip[]>(
            new URLSearchParams({
                beginDate: begin!,
                endDate: end!,
                qbEvalId: qbEvalId,
            }),
            "GetSoundClipsByOrgId",
            this.getAllSoundClipsOutstanding.signal,
        );

        // @ts-ignore
        delete this.getAllSoundClipsOutstanding;

        return res;
    }

    public async createSoundClip(orgId: string, clip: SoundClip) {
        return await this.post<CreateSoundClipResponse>(
            JSON.stringify(clip),
            new URLSearchParams({ orgId }),
            "CreateAudioClip",
        );
    }

    public async createTextClip(orgId: string, clip: SoundClip) {
        return await this.post<SoundClip>(
            JSON.stringify(clip),
            new URLSearchParams(),
            "CreateTextClip",
        );
    }

    async updateSoundClip(orgId: string, clip: SoundClip) {
        return await this.put<SoundClip>(
            new URLSearchParams({ orgId }),
            "UpdateSoundClip",
            JSON.stringify(clip),
        );
    }

    async downloadAudioClip(
        url: string,
        storageUseOption?: StorageAccountUseOptions,
    ) {
        const params: URLSearchParams = new URLSearchParams({
            url,
        });
        if (storageUseOption) {
            params.set("storageUseOption", storageUseOption);
        }
        const res = await this.getArrayBuffer(params, "StreamAudioClip");

        return res;
    }

    async downloadAudioClipAsBlob(
        url: string,
        storageUseOption?: StorageAccountUseOptions,
    ) {
        const params: URLSearchParams = new URLSearchParams({ url });
        if (storageUseOption) {
            params.set("storageUseOption", storageUseOption);
        }
        const res = await this.getBlob(params, "StreamAudioClip");

        return res;
    }

    async downloadAudioClipLegacy(url: string) {
        const res = await this.post<string>(
            null,
            new URLSearchParams({ url }),
            "download",
        );

        return res;
    }

    async getUntranscribedSoundClips() {
        const res = await this.get<UntranscribedSoundClipPayload>(
            new URLSearchParams(),
            "UntranscribedSoundClips",
        );
        return res;
    }

    async getSoundClipsByEvalId(evalId: string) {
        const res = await this.get<SoundClip[]>(
            new URLSearchParams({ evalId }),
            "GetSoundClipsByEvalId",
        );
        return res;
    }

    async getSoundClipsByAudioMetadataId(audioMetadataId: string) {
        const res = await this.get<SoundClip[]>(
            new URLSearchParams({ audioMetadataId }),
            "GetSoundClipsByAudioMetadataId",
        );
        return res;
    }

    async deleteClipById(id: string, isAudio: boolean) {
        const params: URLSearchParams = new URLSearchParams({
            id: id,
            isAudio: JSON.stringify(isAudio),
        });

        const res = await this.delete(params, "DeleteClip");
        return res;
    }

    async semanticSearchOnClip(id: string, entriesToReturn: number) {
        const params: URLSearchParams = new URLSearchParams({
            id: id,
            entriesToReturn: entriesToReturn.toString()
        });

        const res = await this.get(params, "FindRelatedClips");
        return res;
    }

    async updateTextClip(clip: UpdateSoundClipRequest) {
        const params = new URLSearchParams();

        return this.put(params, "UpdateTextClip", JSON.stringify(clip));
    }
}

interface UntranscribedSoundClipPayload {
    [blobContainer: string]: string[];
}
