import { maxBy } from "lodash";
import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import {
    CallDurationStats,
    InteractionDateStats,
} from "models/RecommendedSample";
import moment from "moment";
import Papa from "papaparse";
import TaskStore from "stores/RealTimeMessages/TaskStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { AgentModel } from "../../../../../models/AgentModel";
import AudioMetadataModel from "../../../../../models/AudioMetadataModel";
import DirectoryInfoModel from "../../../../../models/DirectoryInfoModel";
import type {
    FieldFormatter,
    MetadataSpec,
} from "../../../../../models/MetadataSpec";
import Organization from "../../../../../models/Organization";
import { OrganizationStructureMember } from "../../../../../models/OrganizationModels/OrganizationStructureMember";
import RuleSet from "../../../../../models/RuleSet";
import AgentService from "../../../../../services/AgentService";
import { AudioSamplingService } from "../../../../../services/AudioSamplingService";
import { MetadataService } from "../../../../../services/MetadataService";
import { OrganizationService } from "../../../../../services/OrganizationService";
import { RuleSetService } from "../../../../../services/RuleSetService";
import { SourceFilesService } from "../../../../../services/SourceFilesService";
import { BaseStore } from "../../../../../stores/BaseStore";
import { BlobDirectoryStore } from "../../../../../stores/BlobDirectoryStore";
import { OrgSelectorComponentStore } from "../../../../../stores/ComponentStores/OrgSelectorComponentStore";
import { TableComponentStore } from "../../../../../stores/ComponentStores/TableComponentStore";
import { delay } from "../../../../../utils/helpers";
import { isStringType, isUndefinedType } from "../../../../../utils/TypeGuards";
import {
    IReadFileResult,
    readTextFilesAsync,
} from "../../../AudioUploader/UploaderUtils";
import {
    DeleteSpecModelTask,
    GenerateSampleSuggestionOp,
    GetAgentsListOp,
    LoadRuleSetsOp,
    LoadSpecModelsOp,
    ReadMetaFilesOp,
    UploadSpecToServerOp,
} from "../../AudioFileSampler/Stores/AudioFileSamplerStore";
import { buildAgentFormatterFromType } from "../../MetadataTemplate/MetaInput/ColumnMap";
import {
    agentNameFormatters,
    generateFormatterFromAgentField,
} from "../../MetadataTemplate/MetaInput/FormatOptions";
import { TemplateStore } from "../../MetadataTemplate/TemplateStore";
import {
    ExtHandle,
    MetaFieldLabels,
    MetaSource,
} from "../../MetadataTemplate/Types/TemplateModel";
import { RuleBuildStore } from "../../RuleBuilder/RuleBuildStore";
import { RecommendationStepStore } from "./RecommendationStepStore";
import { SourceFilesStepStore } from "./SourceFilesStepStore";
import { StepperStore } from "./StepperStore";
import { v4 as uuidv4 } from "uuid";

const defaultAgentFormatter = (arg: AgentModel) =>
    `${arg.firstName} ${arg.lastName}`;
const defaultSampleSize = 100;

interface ParsedSpecModel extends MetadataSpec.IMetadataSpecModel {
    spec: MetadataSpec.ISpecType;
}

export const LoadFilesFromSelectedDirectoriesTask =
    "Load Files From Selected Directories";

export const LoadRuleSets = "Load Rulesets";

@AcxStore
export class ConfigurationStepStore extends BaseStore {
    private taskStore: TaskStore;
    private readonly ruleSetService = new RuleSetService();
    private readonly audioSamplingService: AudioSamplingService =
        new AudioSamplingService();
    private readonly metadataService: MetadataService = new MetadataService();
    private readonly agentService: AgentService = new AgentService();
    private readonly sourceFilesService: SourceFilesService =
        new SourceFilesService();
    private readonly orgService: OrganizationService =
        new OrganizationService();
    public readonly recommendationStepStore: RecommendationStepStore;
    public readonly sourceFilesStepStore: SourceFilesStepStore;
    readonly tableStore: TableComponentStore<AudioMetadataModel> =
        new TableComponentStore<AudioMetadataModel>(undefined, false);

    private readonly stepperStore: StepperStore;
    readonly orgSelectorStore: OrgSelectorComponentStore;

    @observable newSpecFromBuilder: boolean = false;

    @observable MetaFiles: File[] = [];
    @observable.shallow metaFilesContent: string[] = [];
    @observable uploadReady = false;

    @observable deleteSpecOpen: boolean = false;
    @observable activeSpecModel?: ParsedSpecModel;
    @observable metadataSpecModelList: Map<string, Array<ParsedSpecModel>> =
        observable.map();
    @observable fromFileNameToMetaSpec: Map<
        string,
        Array<{ field: string; value: string }>
    > = observable.map();
    @observable fromCSVToMetaSpec: Map<
        string,
        Array<{ field: string; value: string }>
    > = observable.map();

    @observable deleteRuleSetOpen: boolean = false;
    @observable.shallow ruleSets?: RuleSet[];
    @observable activeRuleSet?: RuleSet;
    @observable.shallow agentsList?: AgentModel[];
    @observable formattedAgentsMap: Map<string, AgentModel> = new Map<
        string,
        AgentModel
    >();
    @observable metaViewDirectory?: string;
    @observable onNavigatingFromRuleBuilder = false;
    @observable buildRecommendationDialogVisibility: boolean = false;

    @observable hierarchySelections: OrganizationStructureMember[] = [];
    @observable sampleSize: string | number = defaultSampleSize;

    @observable hierarchyIdsToSampleFrom: string[] = [];

    @observable.ref files?: AudioMetadataModel[];
    @observable.ref filesMeta?: AudioMetadataModel[];

    @observable
    ruleSetHidden: boolean = false;

    samplingDirectories?: string[];
    samplingDirectoryIds?: string[];
    startDate: string;
    endDate: string;

    private fromCSVToMetaSpecTemp: Map<
        string,
        Array<{ field: string; value: string }>
    > = new Map<string, Array<{ field: string; value: string }>>();
    private fromFileNameToMetaSpecTemp: Map<
        string,
        Array<{ field: string; value: string }>
    > = new Map<string, Array<{ field: string; value: string }>>();
    private lastCsvMetaSpec?: string;
    private lastFileNameMetaSpec?: string;
    private activeRuleSetIdFromBuilder?: string;

    constructor(private rootStore: IRootStore) {
        super("ConfigurationStep Store");
        makeObservable(this);
        this.taskStore = rootStore.getStore(TaskStore);
        this.stepperStore = rootStore.getStore(StepperStore);
        this.sourceFilesStepStore = rootStore.getStore(SourceFilesStepStore);
        this.orgSelectorStore = this.sourceFilesStepStore.orgSelectStore;

        this.recommendationStepStore = rootStore.getStore(
            RecommendationStepStore,
        );

        this.stepperStore.addResetCallback(this.postSamplingReset, 1);

        reaction(
            (r) => this.orgSelectorStore.orgId,
            (orgId) => {
                if (orgId && !this.sourceFilesStepStore.isFromOutsideSampler) {
                    this.setupAsyncTask(LoadRuleSetsOp, () =>
                        this.loadRuleSets(this.activeRuleSetIdFromBuilder),
                    );

                    this.setupAsyncTask(LoadSpecModelsOp, () =>
                        this.loadMetaSpecsForOrg(orgId),
                    );
                }
            },
            { delay: 0, fireImmediately: true },
        );

        reaction(
            (r) => this.orgSelectorStore.organization,
            (organization) => {
                if (
                    organization &&
                    !this.sourceFilesStepStore.isFromOutsideSampler
                ) {
                    this.setupAsyncTask(GetAgentsListOp, () =>
                        this.loadAgentsList(organization),
                    );
                }
            },
            { delay: 50, fireImmediately: true },
        );

        reaction(
            (r) => ({
                stepIndex: this.stepperStore.stepIndex,
                ruleSetHidden: this.ruleSetHidden,
            }),
            (args) => {
                if (args.stepIndex === 1 && !args.ruleSetHidden) {
                    if (!this.ruleSets) {
                        this.loadRuleSets();
                    }
                    this.executeLoadFilesFromSelectedDirs();
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => ({
                agents: this.agentsList,
                specModel: this.activeSpecModel,
            }),
            (obj) => {
                const agentFld = obj.specModel?.spec.fields?.find(
                    (value) =>
                        (value.fieldName as MetaFieldLabels) === "Agent Name",
                );
                const fmtter = agentNameFormatters.find(
                    (a) => a.id === agentFld?.format,
                );

                let formatFunc;
                if (agentFld?.formatFunc) {
                    formatFunc = agentFld.formatFunc;
                } else if (fmtter) {
                    formatFunc = buildAgentFormatterFromType(fmtter);
                } else {
                    formatFunc = defaultAgentFormatter;
                }

                const result = obj.agents?.reduce((map, obj) => {
                    map[formatFunc(obj).replace(/\s+/g, "").toLowerCase()] =
                        obj;
                    return map;
                }, observable.map() as Map<string, AgentModel>);

                this.formattedAgentsMap = result ?? observable.map();
            },
            { fireImmediately: true, delay: 100 },
        );

        reaction(
            (r) => ({
                fromBuilder: this.onNavigatingFromRuleBuilder,
                orgId: this.orgSelectorStore.orgId,
            }),
            (arg) => {
                if (arg.fromBuilder && !!arg.orgId) {
                    this.setupAsyncTask(LoadRuleSetsOp, () =>
                        this.loadRuleSets(this.activeRuleSetIdFromBuilder),
                    );
                }
            },
            { delay: 10, fireImmediately: true },
        );

        reaction(
            (r) => this.activeSpecModel,
            (activeSpec) => {
                if (activeSpec?.spec.parse.type === "filename") {
                    this.fromFileNameToMetaSpecTemp.clear();

                    this.populateMetaRecordsFromTargetFiles(activeSpec.spec);
                    this.lastFileNameMetaSpec = activeSpec.specName;
                }
            },
            { fireImmediately: true, delay: 100 },
        );

        reaction(
            (r) => ({
                fileRows: this.metaFilesContent,
                activeSpec: this.activeSpecModel,
            }),
            ({ fileRows, activeSpec }) => {
                if (
                    fileRows &&
                    fileRows.length > 0 &&
                    activeSpec?.spec.parse.type === "file"
                ) {
                    this.fromCSVToMetaSpecTemp.clear();

                    this.ExtractMetaFromCsvForTargetFiles(fileRows, activeSpec);

                    this.lastCsvMetaSpec = activeSpec.specName;
                }
            },
            { fireImmediately: true, delay: 100 },
        );

        reaction(
            (r) => ({
                metaRecords: this.fromFileNameToMetaSpec,
                files: this.files,
            }),
            (arg) => {
                if (
                    arg.files &&
                    arg.metaRecords &&
                    (this.activeSpecModel?.spec.parse.type as MetaSource) ===
                        "filename"
                ) {
                    const newMetafiles: AudioMetadataModel[] = [];

                    for (let file of arg.files) {
                        const origFileName = file.fileName;
                        const transformedFileName =
                            this.transformFilenameToSpec(origFileName);

                        let [fileName, metaRecord] = [
                            transformedFileName,
                            this.fromFileNameToMetaSpec.get(
                                transformedFileName,
                            ),
                        ] as const;

                        if (!metaRecord) {
                            [fileName, metaRecord] = [
                                origFileName,
                                this.fromFileNameToMetaSpec.get(origFileName),
                            ] as const;
                        }

                        const [
                            fileField,
                            interactionDate,
                            agentName,
                            callDirection,
                            meta1,
                            meta2,
                            meta3,
                            meta4,
                            meta5,
                        ] = this.extractFieldsFromMetaRecord(metaRecord);

                        let displayDate = interactionDate?.value;

                        let metaFileName = fileField?.value || fileName;
                        let metaInteractionDate =
                            displayDate ||
                            moment(file.timestamp).format(
                                moment.HTML5_FMT.DATE,
                            );

                        let metaAgentName = agentName?.value || file.agentName;

                        let metaCallDirection =
                            callDirection?.value || file.callDirection;

                        const m1 = meta1?.value || file.meta1;
                        const m2 = meta2?.value || file.meta2;
                        const m3 = meta3?.value || file.meta3;
                        const m4 = meta4?.value || file.meta4;
                        const m5 = meta5?.value || file.meta5;

                        newMetafiles.push({
                            ...file,
                            fileName: metaFileName,
                            timestamp: metaInteractionDate,
                            agentName: metaAgentName,
                            callDirection: metaCallDirection,
                            meta1: m1,
                            meta2: m2,
                            meta3: m3,
                            meta4: m4,
                            meta5: m5,
                        });
                    }
                    this.filesMeta = newMetafiles;
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => ({ metaRecords: this.fromCSVToMetaSpec, files: this.files }),
            (arg) => {
                if (
                    arg.files &&
                    arg.metaRecords &&
                    (this.activeSpecModel?.spec.parse.type as MetaSource) ===
                        "file"
                ) {
                    const newMetafiles: AudioMetadataModel[] = [];

                    for (let file of arg.files) {
                        const origFileName = file.fileName;
                        const transformedFileName =
                            this.transformFilenameToSpec(origFileName);

                        let [fileName, metaRecord] = [
                            transformedFileName,
                            this.fromCSVToMetaSpec.get(transformedFileName),
                        ] as const;

                        if (!metaRecord) {
                            [fileName, metaRecord] = [
                                origFileName,
                                this.fromCSVToMetaSpec.get(origFileName),
                            ] as const;
                        }

                        const [
                            fileField,
                            interactionDate,
                            agentName,
                            callDirection,
                            meta1,
                            meta2,
                            meta3,
                            meta4,
                            meta5,
                        ] = this.extractFieldsFromMetaRecord(metaRecord);

                        let displayDate = interactionDate?.value;

                        let metaFileName = fileField?.value || fileName;
                        let metaInteractionDate =
                            displayDate ||
                            moment(file.timestamp).format(
                                moment.HTML5_FMT.DATE,
                            );

                        let metaAgentName = agentName?.value || file.agentName;

                        let metaCallDirection =
                            callDirection?.value || file.callDirection;

                        const m1 = meta1?.value || file.meta1;
                        const m2 = meta2?.value || file.meta2;
                        const m3 = meta3?.value || file.meta3;
                        const m4 = meta4?.value || file.meta4;
                        const m5 = meta5?.value || file.meta5;

                        newMetafiles.push({
                            ...file,
                            fileName: metaFileName,
                            timestamp: metaInteractionDate,
                            agentName: metaAgentName,
                            callDirection: metaCallDirection,
                            meta1: m1,
                            meta2: m2,
                            meta3: m3,
                            meta4: m4,
                            meta5: m5,
                        });
                    }
                    this.filesMeta = newMetafiles;
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.filesForViewer,
            (arg) => {
                this.tableStore.setItems(arg ?? []);
            },
            { fireImmediately: true, delay: 0 },
        );
    }

    @action
    setFiles = (files: AudioMetadataModel[]) => {
        this.files = files;
    };

    @action
    executeLoadFilesFromSelectedDirs = () => {
        const sourceFilesStore = this.rootStore.getStore(SourceFilesStepStore);

        const directories = sourceFilesStore.selectedDirectories;
        const orgId = this.orgSelectorStore.orgId;
        this.startDate =
            sourceFilesStore.datePickerStore.beginDate.toISOString();
        this.endDate = sourceFilesStore.datePickerStore.endDate.toISOString();

        if (orgId && directories) {
            this.samplingDirectories = directories.map(
                (value) => value.currDirectorySegment!,
            );

            this.samplingDirectoryIds = directories.map((value) => value.id);

            this.setupAsyncTask(LoadFilesFromSelectedDirectoriesTask, () =>
                this.loadFilesFromSelectedDirectories(
                    directories,
                    this.startDate,
                    this.endDate,
                ),
            );
        }
    };

    @action
    setRulesetHidden = (bool: boolean): void => {
        this.ruleSetHidden = bool;
    };

    @action
    updateFilesMeta = (arg: {
        metaRecords: Map<
            string,
            {
                field: string;
                value: string;
            }[]
        >;
        files: AudioMetadataModel[] | undefined;
        from: "filename" | "csv";
    }) => {
        const newMetafiles: AudioMetadataModel[] = [];
        if (arg.files) {
            for (let file of arg.files) {
                const origFileName = file.fileName;
                const transformedFileName =
                    this.transformFilenameToSpec(origFileName);

                let [fileName, metaRecord] = [
                    transformedFileName,
                    this.fromCSVToMetaSpec.get(transformedFileName),
                ] as const;

                if (!metaRecord) {
                    [fileName, metaRecord] = [
                        origFileName,
                        this.fromCSVToMetaSpec.get(origFileName),
                    ] as const;
                }

                const [
                    fileField,
                    interactionDate,
                    agentName,
                    callDirection,
                    meta1,
                    meta2,
                    meta3,
                    meta4,
                    meta5,
                ] = this.extractFieldsFromMetaRecord(metaRecord);

                let displayDate = interactionDate?.value;
                // if (interactionDate) {
                //     const metaSpecDateTime = this.metadataSpec?.fields.find(
                //         (field) => (field.fieldName as MetaFieldLabels) === "Interaction Date");
                //
                //     const dateTimeFormat = metaSpecDateTime?.format;
                //
                //     const parsedDt = moment(interactionDate?.value, dateTimeFormat);
                //
                //     if (dateTimeFormat && dateTimeFormat?.length < 11) {
                //         displayDate = parsedDt.format("YYYY-MM-DD");
                //     } else {
                //         displayDate = parsedDt.format(moment.HTML5_FMT.DATE);
                //     }
                // }
                let metaFileName = fileField?.value || fileName;
                let metaInteractionDate =
                    displayDate ||
                    moment(file.timestamp).format(moment.HTML5_FMT.DATE);

                let metaAgentName = agentName?.value || file.agentName;

                let metaCallDirection =
                    callDirection?.value || file.callDirection;
                const m1 = meta1?.value || file.meta1;
                const m2 = meta2?.value || file.meta2;
                const m3 = meta3?.value || file.meta3;
                const m4 = meta4?.value || file.meta4;
                const m5 = meta5?.value || file.meta5;

                newMetafiles.push({
                    ...file,
                    fileName: metaFileName,
                    timestamp: metaInteractionDate,
                    agentName: metaAgentName,
                    callDirection: metaCallDirection,
                    meta1: m1,
                    meta2: m2,
                    meta3: m3,
                    meta4: m4,
                    meta5: m5,
                });
            }
        }
        this.filesMeta = newMetafiles;
    };

    @action
    public postSamplingReset = () => {
        this.MetaFiles = [];
        this.metaFilesContent = [];
        this.activeRuleSet = undefined;
        this.activeSpecModel = undefined;
        this.fromFileNameToMetaSpec.clear();
        this.fromCSVToMetaSpec.clear();
        this.filesMeta = undefined;
        this.samplingDirectoryIds = undefined;
        this.samplingDirectories = undefined;
    };

    @action
    public reset = () => {
        this.MetaFiles = [];
        this.metaFilesContent = [];
        this.activeRuleSet = undefined;
        this.activeSpecModel = undefined;
        this.ruleSets = undefined;
        this.agentsList = undefined;
        this.hierarchySelections = [];
        this.fromFileNameToMetaSpec.clear();
        this.fromCSVToMetaSpec.clear();
        this.filesMeta = undefined;
        this.samplingDirectoryIds = undefined;
        this.samplingDirectories = undefined;
    };

    @computed
    get levels() {
        return this.rootStore.getStore(SourceFilesStepStore).levels;
    }

    @computed
    get members() {
        return this.rootStore.getStore(SourceFilesStepStore).members;
    }

    @action
    setHierarchySelection = (
        index: number,
        newValue: OrganizationStructureMember,
    ) => {
        this.hierarchySelections = this.hierarchySelections
            .slice(0, index)
            .concat([newValue])
            .filter((value) => value.name !== "No Selection");
    };

    @computed
    get filesForViewer() {
        const origFiles = this.files;
        const metaFiles = this.filesMeta;
        return metaFiles ?? origFiles;
    }

    @computed
    get formattedArrivedOnDateRange() {
        const blobDirectoryStore = this.rootStore.getStore(BlobDirectoryStore);
        const start = blobDirectoryStore.beginDate.format("MM/DD/YYYY");
        const end = blobDirectoryStore.endDate.format("MM/DD/YYYY");
        return `${start} - ${end}`;
    }

    @computed
    get sampleSizeAsString() {
        if (Number.isNaN(+this.sampleSize)) {
            return defaultSampleSize.toString(10);
        } else {
            return this.sampleSize.toString(10);
        }
    }

    @action
    setSampleSize(sampleSize: string) {
        sampleSize = (sampleSize ?? "").trim();
        if (sampleSize === "") {
            this.sampleSize = sampleSize;
            return;
        }

        try {
            let size = parseInt(sampleSize, 10);
            if (Number.isNaN(size) || size < 0) {
                size = 0;
            }

            this.sampleSize = size;
        } catch (err) {
            this.sampleSize = defaultSampleSize;
            console.error("sample size conversion to int failed: " + err);
        }
    }

    @action
    async loadFilesFromSelectedDirectories(
        directories: DirectoryInfoModel[],
        startDate: string,
        endDate: string,
    ) {
        if (this.ruleSetHidden) {
            return;
        }

        const files: AudioMetadataModel[] = [];
        for (const directory of directories) {
            const res =
                await this.sourceFilesService.getSampleFilesFromDirectory(
                    directory.id,
                    startDate,
                    endDate,
                    100,
                    true,
                );
            files.push(...res);
        }

        this.setFiles(files);
    }

    @action
    prepareRuleBuilderForNavigation() {
        const ruleBuildStore = this.rootStore.getStore(RuleBuildStore);
        if (this.orgSelectorStore.organization) {
            ruleBuildStore.onRuleSetFinished = this.setOnNavFromBuilder;
            ruleBuildStore.initializeStoreFromSampler(
                this.orgSelectorStore.organization,
                this.activeRuleSet?.id,
            );
        }
    }

    @action
    prepareSpecBuilderForNavigation() {
        const templateStore = this.rootStore.getStore(TemplateStore);
        templateStore.reset();
        templateStore.onSpecFinished = this.setMetadataSpecFromBuilder;
        const metadataModel = this.files?.[getRandomInt(this.files?.length)];
        if (metadataModel) {
            if (this.MetaFiles.length === 0) {
                templateStore.updateModelProp(
                    "filename",
                    metadataModel.fileName,
                );
                templateStore.updateModelProp(
                    "sampleString",
                    metadataModel.fileName,
                );
                templateStore.updateModelProp("metaSource", "filename");
                templateStore.initModelFromExisting(this.activeSpecModel?.spec);
            } else {
                const whichRow = Math.abs(
                    Math.floor((this.metaFilesContent?.length ?? 0) * 0.5) +
                        (randomIntFromInterval(
                            -(this.metaFilesContent.length / 2),
                            this.metaFilesContent.length / 2,
                        ) %
                            this.metaFilesContent.length),
                );

                const sampleLine = this.metaFilesContent?.[whichRow];

                templateStore.updateModelProp(
                    "filename",
                    metadataModel.fileName,
                );
                templateStore.updateModelProp("sampleString", sampleLine ?? "");
                templateStore.updateModelProp("metaSource", "file");
                templateStore.initModelFromExisting(this.activeSpecModel?.spec);
            }
        }
    }

    @action
    deleteMetadataSpec = () => {
        this.setupAsyncTask(DeleteSpecModelTask, async () => {
            if (this.activeSpecModel?.id && this.orgSelectorStore.orgId) {
                const specId = this.activeSpecModel.id;
                const orgId = this.orgSelectorStore.orgId;

                await this.metadataService.deleteMetadataSpec(specId);
                runInAction(() => {
                    this.setDeleteSpecClose();
                });

                await delay(50);

                runInAction(() => {
                    this.activeSpecModel = undefined;
                    this.fromFileNameToMetaSpecTemp.clear();
                    this.fromFileNameToMetaSpec.clear();
                    this.fromCSVToMetaSpecTemp.clear();
                    this.fromCSVToMetaSpec.clear();
                    this.filesMeta = undefined;
                });

                await delay(50);

                this.setupAsyncTask(LoadSpecModelsOp, () =>
                    this.loadMetaSpecsForOrg(orgId),
                );
            }
        });
    };
    @action
    deleteRuleSet = () => {
        this.setupAsyncTask("Delete RuleSet", async () => {
            if (this.activeRuleSet?.id && this.orgSelectorStore.orgId) {
                await this.ruleSetService.deleteRuleSet(
                    this.orgSelectorStore.orgId,
                    this.activeRuleSet.id,
                );
                this.activeRuleSet = undefined;

                this.loadRuleSets();
            }
            this.deleteRuleSetOpen = false;
        });
    };

    @action
    showBuildRecommendationDialog = () => {
        if (
            (!this.ruleSetHidden && !this.activeRuleSet) ||
            !this.orgSelectorStore.organization
        ) {
            throw new Error("OrgId, RuleSetId required for sampling");
        }

        const organization = this.orgSelectorStore.organization;

        this.setupAsyncTask(GetAgentsListOp, () =>
            this.loadAgentsList(organization),
        );

        this.buildRecommendationDialogVisibility = true;
    };

    @action
    startBuildingSampleSuggestion = () => {
        this.setupAsyncTask(GenerateSampleSuggestionOp, () =>
            this.startBuildingSampleSuggestionInternal(),
        );
    };

    @action
    async startBuildingSampleSuggestionInternal() {
        if (
            this.activeRuleSet &&
            (this.samplingDirectoryIds?.length ?? 0) > 0 &&
            this.startDate &&
            this.endDate &&
            this.orgSelectorStore?.orgId !== undefined
        ) {
            const orgId = this.orgSelectorStore?.orgId;
            const ruleSetId = this.activeRuleSet.id;
            const specId = this.activeSpecModel?.id;
            const dirIds = this.samplingDirectoryIds;
            const sampleSize = this.sampleSize;
            const startDate = this.startDate;
            const endDate = this.endDate;
            let csvContents;

            if (
                (this.activeSpecModel?.spec.parse.type as MetaSource) === "file"
            ) {
                csvContents = this.metaFilesContent?.join("\n") ?? "";
            }

            this.stepperStore.nextStep();

            const res =
                await this.audioSamplingService.generateSamplingSuggestionV2(
                    orgId,
                    ruleSetId,
                    specId,
                    sampleSize.toString(),
                    startDate,
                    endDate,
                    csvContents,
                    undefined,
                    dirIds,
                    this.hierarchyIdsToSampleFrom,
                );

            runInAction(() => {
                if (!res || res.results.length === 0) {
                    this.recommendationStepStore.showEmptyRecommendationDialog();
                } else {
                    this.recommendationStepStore.closeEmptyRecommendationDialog();
                }

                this.recommendationStepStore.setRecommendedSample(res);
            });
        } else if (this.ruleSetHidden && this.files?.length === 1) {
            this.stepperStore.nextStep();
            this.recommendationStepStore.closeEmptyRecommendationDialog();
            this.recommendationStepStore.setRecommendedSample({
                results: toJS(this.files) as AudioMetadataModel[],
                agentCallDistribution: [],
                callDurationStats: {} as CallDurationStats,
                interactionDateStats: {} as InteractionDateStats,
                extendedMetadataResults: [],
            });
        } else {
            console.error(
                "Unable to start building sampling recommendation because required fields missing",
            );
        }
    }

    @action
    async parseUpdateMetadata() {
        if (this.samplingDirectoryIds && this.startDate && this.endDate) {
            const specId = this.activeSpecModel?.id;
            const dirIds = this.samplingDirectoryIds;
            const startDate = this.startDate;
            const endDate = this.endDate;
            const org = this.orgSelectorStore.orgId;
            let csvContents;
            if (
                (this.activeSpecModel?.spec.parse.type as MetaSource) === "file"
            ) {
                csvContents = this.metaFilesContent?.join("\n") ?? "";
            }
            this.setupAsyncTask("Parse Update Metadata", () =>
                this.metadataService.parseUpdateMetadata(
                    specId,
                    dirIds,
                    startDate,
                    endDate,
                    csvContents,
                    uuidv4(),
                    org,
                ),
            );
        }
    }

    @action
    closeBuildRecommendDialog() {
        this.buildRecommendationDialogVisibility = false;
    }

    @action
    setOnNavFromBuilder = (rulesetId?: string) => {
        this.activeRuleSetIdFromBuilder = rulesetId;
        this.onNavigatingFromRuleBuilder = true;
    };

    ExtractMetaFromCsvForTargetFiles = (
        fileRows: string[],
        activeSpec?: ParsedSpecModel,
        targetArray?: Array<AudioMetadataModel>,
    ) => {
        let Rows = fileRows;
        for (let item of targetArray ?? this.files ?? []) {
            for (let fileRow of Rows) {
                if (
                    this.buildMetaRecordFromFileRow(
                        fileRow,
                        activeSpec?.spec,
                        item.fileName,
                    )
                ) {
                    Rows = Rows.filter((value) => value !== fileRow);
                    break;
                }
            }
        }
        this.fromCSVToMetaSpec = this.fromCSVToMetaSpecTemp;
    };

    async buildMetaRecordForItemAddedToTarget(...items: AudioMetadataModel[]) {
        if (isUndefinedType(this.activeSpecModel)) {
            return;
        }

        if (this.activeSpecModel.spec.parse?.type === "filename") {
            for (let item of items) {
                this.buildMetaRecordFromFileName(
                    item,
                    this.activeSpecModel.spec,
                );
            }

            this.fromFileNameToMetaSpec = this.fromFileNameToMetaSpecTemp;
        } else if (this.activeSpecModel.spec.parse?.type === "file") {
            if (this.metaFilesContent) {
                for (let item of items) {
                    const transformedFileName = this.transformFilenameToSpec(
                        item.fileName,
                    );

                    this.ExtractMetaFromCsvForTargetFiles(
                        this.metaFilesContent ?? [],
                        this.activeSpecModel,
                        [
                            {
                                fileName: transformedFileName,
                            } as AudioMetadataModel,
                        ],
                    );
                }
            }
        }
    }

    //ToDo: appears unused but lint doesn't warn
    @action
    private analyzeMetaSpec(metaSpec: MetadataSpec.ISpecType) {
        if ((metaSpec.parse?.type as MetaSource) === "filename") {
            this.populateMetaRecordsFromTargetFiles(metaSpec);
        } else if ((metaSpec.parse?.type as MetaSource) === "file") {
            this.populateMetaRecordsFromCSV(metaSpec);
        }
    }

    @action
    private async populateMetaRecordsFromTargetFiles(
        metaSpec: MetadataSpec.ISpecType,
    ) {
        let t0 = performance.now();
        for (let value of this.files ?? []) {
            this.buildMetaRecordFromFileName(value, metaSpec);

            const t1 = performance.now();
            if (t1 - t0 > 30) {
                t0 = t1;
                await delay(30);
            }
        }
        this.fromFileNameToMetaSpec = this.fromFileNameToMetaSpecTemp;
    }

    @action
    populateMetaRecordsFromCSV(metaSpec: MetadataSpec.ISpecType) {
        this.metaFilesContent?.forEach((value, index) => {
            this.buildMetaRecordFromFileRow(value, metaSpec);
        });
    }

    @action
    private buildMetaRecordFromFileRow(
        fileRow: string,
        metaSpec?: MetadataSpec.ISpecType,
        fileNameToMatch?: string,
    ) {
        if (!metaSpec) {
            return;
        }
        const parse = Papa.parse(fileRow, {
            quoteChar: '"',
            delimiter: metaSpec.parse?.delimiter ?? ",",
        }).data?.[0];

        if (!parse) {
            return;
        }
        const parsed = parse as [];

        if ((parsed.length ?? 0) === 0) {
            return;
        }

        if (
            !metaSpec.fields?.length ||
            metaSpec.fields.length > parsed.length
        ) {
            return;
        }

        let fileName = "";
        const fields: any[] = [];
        for (let fieldSpec of metaSpec.fields) {
            if ((fieldSpec.fieldName as MetaFieldLabels) === "Filename") {
                fileName = parsed[fieldSpec.columnLocation];

                if (
                    fileNameToMatch &&
                    fileName !==
                        this.transformFilenameToSpec(fileNameToMatch) &&
                    fileName !== fileNameToMatch
                ) {
                    return;
                }
            }

            fields.push({
                field: fieldSpec.fieldName,
                value: parsed[fieldSpec.columnLocation],
            });
        }

        if (
            !fileName ||
            fields.filter((f) => !f.value).length >= metaSpec.fields.length - 1
        ) {
            return;
        }

        this.fromCSVToMetaSpecTemp.set(fileName, fields);

        return fileName;
    }

    @action
    private buildMetaRecordFromFileName(
        value: AudioMetadataModel,
        metaSpec: MetadataSpec.ISpecType,
    ) {
        const transformedFilename = this.transformFilenameToSpec(
            value.fileName,
        );

        const parse = Papa.parse(transformedFilename, {
            quoteChar: '"',
            delimiter: metaSpec.parse?.delimiter,
        }).data?.[0];
        if (!parse) {
            return;
        }
        const parsed = parse as [];

        if ((parsed.length ?? 0) === 0) {
            return;
        }

        if (
            !metaSpec.fields?.length ||
            metaSpec.fields.length > parsed.length
        ) {
            return;
        }

        const fields = metaSpec.fields.map((fieldSpec) => {
            return {
                field: fieldSpec.fieldName,
                value: parsed[fieldSpec.columnLocation],
            };
        });

        this.fromFileNameToMetaSpecTemp.set(value.fileName, fields);
        return value.fileName;
    }

    private extractFieldsFromMetaRecord(
        metaRecord?: Array<{ field: string; value: string }>,
    ) {
        return [
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Filename",
            ),
            metaRecord?.find(
                (value1) =>
                    (value1.field as MetaFieldLabels) === "Interaction Date",
            ),
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Agent Name",
            ),
            metaRecord?.find(
                (value1) =>
                    (value1.field as MetaFieldLabels) === "Call Direction",
            ),
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Meta 1",
            ),
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Meta 2",
            ),
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Meta 3",
            ),
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Meta 4",
            ),
            metaRecord?.find(
                (value1) => (value1.field as MetaFieldLabels) === "Meta 5",
            ),
        ];
    }

    // ToDo: seems not used but lint doesn't warn?
    // getMetaRecordForFileNameV2 = computedFn(
    //     function (this: ConfigurationStepStore, audioMetadataModel: AudioMetadataUiModel) {
    //         const origFileName = audioMetadataModel.fileName;
    //         const transformedFileName = this.transformFilenameToSpec(origFileName);

    //         if (this.activeSpecModel?.spec.parse.type as MetaSource === "file") {

    //             let [fileName, metaRecord] = [transformedFileName,
    //                 this.fromCSVToMetaSpec.get(transformedFileName)] as const;

    //             if (!metaRecord) {
    //                 [fileName, metaRecord] = [origFileName, this.fromCSVToMetaSpec.get(origFileName)] as const;
    //             }

    //             return { fileName, metaRecord, audioMetadataModel };

    //         } else {

    //             let [fileName, metaRecord] = [transformedFileName,
    //                 this.fromFileNameToMetaSpec.get(transformedFileName)] as const;

    //             if (!metaRecord) {
    //                 [fileName, metaRecord] = [origFileName, this.fromFileNameToMetaSpec.get(origFileName)] as const;
    //             }

    //             return { fileName, metaRecord, audioMetadataModel };
    //         }
    //     }, { name: "GetMetaRecordsForFileName" });

    loadAgentsList = flow(function* (
        this: ConfigurationStepStore,
        org: Organization,
    ) {
        yield delay(80);
        let agentsList;
        if (org.qbAppId) {
            agentsList = yield this.metadataService.agentsList(org);
        } else {
            agentsList = yield this.agentService.getAgentViewModels(org.id);
        }
        yield delay(15);
        this.agentsList = observable.array(agentsList);
    });

    @action
    loadRuleSets = async (ruleSetId?: string) => {
        this.setupAsyncTask(LoadRuleSets, async () => {
            const rulesets = await this.ruleSetService.getRuleSets();
            await delay(8);

            let selectedRs: RuleSet | undefined;
            if (ruleSetId) {
                selectedRs = rulesets.find((r) => r.id === ruleSetId);
            }

            runInAction(() => {
                this.ruleSets = rulesets;
                if (selectedRs) {
                    this.setActiveRuleSet(selectedRs);
                }
            });
        });
    };

    loadMetaSpecsForOrg = flow(function* (
        this: ConfigurationStepStore,
        orgId: string,
    ) {
        const metaSpeclist = yield this.metadataService.getMetadataSpecList(
            orgId,
        );

        const parsedSpecModels = observable.array(
            metaSpeclist
                ?.filter((value) => value.spec)
                .map((value) => this.specToObject(value)),
        );

        yield delay(0);

        if (parsedSpecModels.length > 0) {
            this.metadataSpecModelList.set(orgId, parsedSpecModels);

            const latestSpec = parsedSpecModels[0];

            this.setMetaSpecFromServer(latestSpec);
        } else {
            this.metadataSpecModelList.set(orgId, observable.array());
            this.activeSpecModel = undefined;
        }
    });

    @action
    cancelSpecSaveDialog = () => {
        this.activeSpecModel = undefined;
        this.newSpecFromBuilder = false;

        this.fromFileNameToMetaSpec.clear();
        this.fromCSVToMetaSpec.clear();
    };

    @action
    fromSpecConfirmDialog = (
        specName: string,
        levels: OrganizationStructureMember[],
    ) => {
        if (isUndefinedType(this.activeSpecModel)) {
            throw new Error("Metadata Spec model undefined");
        }

        let osm: OrganizationStructureMember | undefined = undefined;
        if (levels.length > 0) {
            osm = levels?.[levels.length - 1];
        }

        this.activeSpecModel.organizationStructureMemberId = osm?.id;
        this.activeSpecModel.specName = specName;
        this.newSpecFromBuilder = false;

        this.setupAsyncTask(UploadSpecToServerOp, () => this.uploadSpec()).then(
            (value) => {
                this.setActiveSpec(
                    value as MetadataSpec.IMetadataSpecModel & {
                        Symbol?: boolean;
                    },
                );
                let orgId: string | undefined;
                if (
                    this.activeSpecModel &&
                    (orgId = this.orgSelectorStore.orgId)
                ) {
                    let currentSpecList = this.metadataSpecModelList.get(orgId);
                    if (isUndefinedType(currentSpecList)) {
                        currentSpecList = observable.array([
                            this.activeSpecModel,
                        ]) as ParsedSpecModel[];
                    } else {
                        currentSpecList.push(this.activeSpecModel);
                    }
                    this.metadataSpecModelList.set(orgId, currentSpecList);
                }
            },
        );
    };

    uploadSpec = flow(function* (this: ConfigurationStepStore) {
        const theActiveSpec = toJS(this.activeSpecModel);
        if (theActiveSpec) {
            theActiveSpec.spec.fields.forEach((value) => {
                delete value.formatFunc;
            });
            if (this.orgSelectorStore.orgId) {
                return yield this.metadataService.createMetadataSpec(
                    this.orgSelectorStore.orgId,
                    theActiveSpec.specName!,
                    theActiveSpec.spec!,
                    theActiveSpec.organizationStructureMemberId!,
                );
            }
        }
    });

    private transformFilenameToSpec = (origFileName: string) => {
        let transformed = origFileName;
        if (!this.metadataSpec) {
            return transformed;
        }

        if (
            (this.metadataSpec?.fileHandle.action as ExtHandle) ===
                "removestring" &&
            this.metadataSpec?.fileHandle.value
        ) {
            transformed = origFileName.slice(
                0,
                origFileName.lastIndexOf(this.metadataSpec.fileHandle.value),
            );
        } else if (
            (this.metadataSpec?.fileHandle.action as ExtHandle) === "removedot"
        ) {
            transformed = origFileName.slice(0, origFileName.lastIndexOf("."));
        }

        return transformed;
    };

    @action
    private specToObject(
        specModel: MetadataSpec.IMetadataSpecModel,
    ): ParsedSpecModel {
        let parsedSpecModel: ParsedSpecModel = specModel as ParsedSpecModel;
        if (isStringType(specModel.spec)) {
            parsedSpecModel = {
                ...specModel,
                spec: JSON.parse(specModel.spec),
            };
        }

        const agentField = this.getAgentFieldFromSpec(parsedSpecModel);

        const res = generateFormatterFromAgentField(agentField);

        if (agentField) {
            agentField.formatFunc = res[0];
        }
        return parsedSpecModel as ParsedSpecModel;
    }

    @computed
    get specHasAgentFormatter() {
        return Boolean(
            this.activeSpecModel?.spec.fields.find(
                (fld) => (fld.fieldName as MetaFieldLabels) === "Agent Name",
            )?.formatFunc,
        );
    }

    private getAgentFieldFromSpec(
        parsedSpecModel: ParsedSpecModel,
    ): (MetadataSpec.FieldType & FieldFormatter<any>) | undefined {
        return parsedSpecModel.spec.fields.find(
            (fld) => (fld.fieldName as MetaFieldLabels) === "Agent Name",
        );
    }

    @action
    setActiveSpec = (
        specModel: MetadataSpec.IMetadataSpecModel & { Symbol?: boolean },
    ) => {
        if (
            specModel?.specName !== this.activeSpecModel?.specName ||
            specModel.id !== this.activeSpecModel?.id
        ) {
            if (specModel[Symbol.for("UnSelect")]) {
                this.activeSpecModel = undefined;
                this.fromFileNameToMetaSpecTemp.clear();
                this.fromFileNameToMetaSpec.clear();
                this.fromCSVToMetaSpecTemp.clear();
                this.fromCSVToMetaSpec.clear();
                this.filesMeta = undefined;
            } else {
                delay(0).then((value) => {
                    runInAction(() => {
                        this.activeSpecModel = this.specToObject(specModel);
                        this.getAgentFieldFromSpec(this.activeSpecModel);
                    });
                });
            }
        }
    };

    @action
    setActiveRuleSet = (ruleSet?: RuleSet) => {
        this.activeRuleSet = ruleSet;

        this.activeRuleSetIdFromBuilder = undefined;
        this.onNavigatingFromRuleBuilder = false;
    };

    @action
    setMetaSpecFromServer(value: ParsedSpecModel) {}

    get metadataSpec(): MetadataSpec.ISpecType | undefined {
        return this.activeSpecModel?.spec;
    }

    @action
    setMetadataSpecFromBuilder = (
        value: MetadataSpec.ISpecType | undefined,
    ) => {
        if (!isUndefinedType(value)) {
            const targetFiles = this.files ?? [];
            const { containerName } = targetFiles[0];
            let newSpec = {
                spec: value,
                organizationId: this.orgSelectorStore.orgId,
                specName: `${containerName}-latest`,
                callCenter: "",
                brand: "",
                vendor: "",
            } as ParsedSpecModel;

            if ((newSpec.spec.fileHandle.action as ExtHandle) === "removedot") {
                newSpec.spec.fileHandle.value = ".";
            }

            if ((newSpec.spec.fileHandle.action as ExtHandle) === "") {
                newSpec.spec.fileHandle.value = "";
            }

            this.setActiveSpec(newSpec);

            this.uploadReady = true;
            this.newSpecFromBuilder = true;
        } else {
            this.uploadReady = false;
            this.newSpecFromBuilder = false;
        }
    };

    readMetaFiles = flow(function* (this: ConfigurationStepStore) {
        const metaPromises = readTextFilesAsync(this.MetaFiles);

        let metaObjects: IReadFileResult[] = yield Promise.all(metaPromises);

        metaObjects = metaObjects
            .filter((obj) => obj.status === "ok")
            .filter((value) => !!value.contents)
            .sort(
                (a, b) =>
                    ((b.contents as string)?.length ?? 0) -
                    ((a.contents as string)?.length ?? 0),
            );

        if (!metaObjects || metaObjects.length === 0) {
            this.setMetaFileContents(undefined);
            return;
        }

        yield delay(0);
        const macOsSep = /\r[^\n]/g;
        const windowsSep = /\r\n/g;
        const unixSep = /[^\r]\n/g;

        const separatorResults = [
            {
                count: (
                    ((metaObjects[0].contents ?? "") as string).match(
                        macOsSep,
                    ) || []
                ).length,
                sep: macOsSep,
            },
            {
                count: (
                    ((metaObjects[0].contents ?? "") as string).match(
                        windowsSep,
                    ) || []
                ).length,
                sep: windowsSep,
            },
            {
                count: (
                    ((metaObjects[0].contents ?? "") as string).match(
                        unixSep,
                    ) || []
                ).length,
                sep: unixSep,
            },
        ];

        yield delay(0);
        const lineSep =
            maxBy(separatorResults, (obj) => obj.count)?.sep ?? windowsSep;

        const metaLines = ((metaObjects[0].contents ?? "") as string).split(
            lineSep,
        );
        this.setMetaFileContents(metaLines);
    });

    @action
    setMetaFiles = (value: File[]) => {
        this.fromCSVToMetaSpec.clear();
        this.MetaFiles.splice(0, this.MetaFiles.length, ...value);
        this.setupAsyncTask(ReadMetaFilesOp, () => this.readMetaFiles());
    };

    @action
    setMetaFileContents(value: string[] | undefined) {
        this.metaFilesContent = value ?? [];
    }

    @action
    setDeleteSpecOpen = () => {
        this.deleteSpecOpen = Boolean(this.activeSpecModel);
    };
    @action
    setDeleteSpecClose = () => {
        this.deleteSpecOpen = false;
    };

    @action
    setMetadataViewerDirectory(directory?: string) {
        this.metaViewDirectory = directory;
    }
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
