import { Theme, Tooltip, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import EditIcon from "@mui/icons-material/Edit";
import clsx from "clsx";
import AcxInputLabel from "components/UI/AcxInputLabel";
import debounce from "debounce-promise";
import _, { isFunction } from "lodash";
import React, { CSSProperties, ReactElement, ReactNode, useState } from "react";
import {
    ActionMeta,
    components,
    GroupTypeBase,
    OptionTypeBase,
} from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Option } from "react-select/src/filters";
import useStyles from "Styles/Styles";
import { v4 as uuidv4 } from "uuid";
import hexToRGB from "utils/hexToRGB";
import { isArrayType } from "utils/TypeGuards";
import theme from "../../../../Theme/AppTheme";
import DownChevronSvg from "./DownChevronSvg";

const styles = (theme: Theme) =>
    createStyles({
        field: {
            width: "100%",
            backgroundColor: theme.palette.white.main,
        },
        editOver: {
            cursor: "pointer",
            "&:hover": {
                color: hexToRGB(theme.palette.black.main, 0.6),
            },
        },
        textLabel: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        groupStyles: {
            color: "white",
            background: hexToRGB(theme.palette.primary.main, 0.3),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(0.5),
        },
        collapsed: {
            display: "none",
        },
        helperText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        helperTextError: {
            color: `${theme.palette.error.main} !important`,
        },
        required: {
            "&:after": {
                color: theme.palette.red.main,
                content: '" *" !important',
            },
        },
    });

export interface IAcxSelectSingleAsync<S extends OptionTypeBase> {
    id: string;
    placeholder?: string;
    defaultValue?;
    defaultOptions?: any[];
    valueField: string;
    labelField: string | ((option: Option) => string);
    inputLabel?: string;
    enableNoSelection?: boolean;
    editIcon?: boolean;
    onEditClick?: () => void;
    isClearable?: boolean;
    isLoading?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    shrink?: boolean;
    infoText?: React.ReactNode;
    fullWidth?: boolean;
    width?: string;
    isDisabled?: boolean;
    menuPlacement?: "top" | "bottom" | "auto";
    customStyle?: Record<string, (provided, state) => CSSProperties>;
    maxMenuHeightPx?: number;
    required?: boolean;
    onChange?: (arg0: any | undefined) => void;
    onMultiChange?: (arg0: any[] | undefined) => void;
    customFilter?: (option, rawInput: string) => boolean;
    groupCollapse?: boolean;
    containerHeight?: string | "auto";
    colorField?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    customRootStyles?: React.CSSProperties;
    loadOptions: (
        inputValue: string,
    ) => Promise<readonly (S | GroupTypeBase<S>)[]>;
    cacheOptions?: boolean;
    loadOptionsDelay?: number;
    // defaults to 2
    minCharsToSearch?: number;
    noOptionMessage?: string;
    showAllErrors?: boolean;
    optionComponent?: (props) => ReactNode;
    singleValueComponent?: (props) => ReactNode;
    onCreate?: (arg0: any) => void;
}

export default function AcxSelectSingleAsync<S extends OptionTypeBase>(
    props: IAcxSelectSingleAsync<S>,
) {
    const [labelFocus, setLabelFocus] = useState<boolean | undefined>();
    const [currentValue, setCurrentValue] = useState<any>(props.defaultValue);
    const [shouldCollapse] = useState<boolean>(props.groupCollapse ?? false);
    const [thisId, setThisId] = useState<string>();
    const classes = useStyles(styles);
    const appTheme = useTheme();

    const loadOptions = debounce(
        props.loadOptions,
        props.loadOptionsDelay ?? 400,
    );

    const loadOptionsDebounced = async (
        value: string,
        callback: (options: readonly (S | GroupTypeBase<S>)[]) => void,
    ) => {
        if (!value && props.defaultValue) {
            return [props.defaultValue] as readonly (S | GroupTypeBase<S>)[];
        } else {
            if (value?.length >= (props.minCharsToSearch ?? 2)) {
                const res = await loadOptions(value);
                return res;
            }
            return [];
        }
    };

    const onFilter = (option, rawInput: string) => {
        return true;
    };

    const onChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (
            value?.[props.valueField] === "-1" ||
            actionMeta.action === "clear"
        ) {
            setCurrentValue(null);
            value = undefined;
        } else {
            setCurrentValue(value);
        }
        if (value) {
            if (isArrayType(value)) {
                if (props.onMultiChange) {
                    props.onMultiChange(value);
                }
            } else {
                if (props.onChange) {
                    props.onChange(value);
                }
            }
        } else {
            if (props.onChange) {
                props.onChange(undefined);
            }
            if (props.onMultiChange) {
                props.onMultiChange(undefined);
            }
        }
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected
                ? theme.palette.primary[500]
                : theme.palette.text.primary,
            backgroundColor: state.isSelected
                ? theme.palette.primary[50]
                : theme.palette.white.main,
            "&:hover": {
                backgroundColor: theme.palette.primary[50],
                color: theme.palette.neutral[600],
            },
            padding: appTheme.spacing(1),
            borderRadius: "2px",
        }),
        groupHeading: (provided, state) => ({
            // ...provided,
            color: hexToRGB(appTheme.palette.black.main, 0.7),
            font: appTheme.typography.fontFamily,
            fontSize: "13px",
            fontWeight: appTheme.typography.fontWeightBold,
            cursor: "pointer",
        }),
        menu: (provided, state) => ({
            ...provided,
            // width: "300px",
            zIndex: 10,
            padding: "8px",
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        control: () => ({
            display: "flex",
            cursor: "pointer",
        }),
        singleValue: (provided, state) => {
            if (props.colorField) {
                const fontWeight = "bold";
                const fontSize = "12px";
                const lineHeight = "15px";
                const paddingLeft = "6px";
                const paddingRight = "6px";
                const paddingTop = "3px";
                const paddingBottom = "3px";
                const backgroundColor = hexToRGB(
                    state.data[props.colorField ?? ""] ??
                        appTheme.palette.info.main,
                    0.18,
                );
                const color =
                    state.data[props.colorField ?? ""] ??
                    appTheme.palette.info.main;
                return {
                    ...provided,
                    backgroundColor,
                    color,
                    fontWeight,
                    paddingTop,
                    paddingRight,
                    paddingBottom,
                    paddingLeft,
                    fontSize,
                    lineHeight,
                };
            }
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            return { ...provided, opacity, transition };
        },
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: appTheme.palette.text.primary,
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: "6px",
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: hexToRGB(
                data[props.colorField ?? ""] ?? appTheme.palette.info.main,
                0.18,
            ),
            // border: `2px dotted ${appTheme.palette.secondary.main}`,
            fontSize: "13px",
            color: data[props.colorField ?? ""] ?? appTheme.palette.info.main,
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data[props.colorField ?? ""] ?? appTheme.palette.info.main,
            fontWeight: "bold",
        }),
        container: (provided, state) => ({
            ...provided,
            fontSize: "13px",
            minWidth: "100px",
            width: props.width ?? "100%",
            maxWidth: props.fullWidth ? "100%" : "336px",
            border: props.isDisabled ? "none" : "1px solid",
            borderColor: state.isFocused
                ? hexToRGB(theme.palette.primary.main, 0.7)
                : appTheme.palette.black.light,
            borderRadius: appTheme.shape.borderRadius,
            minHeight: props.containerHeight ?? "32px",
            height: props.containerHeight ?? "32px",
            backgroundColor: props.isDisabled
                ? hexToRGB(appTheme.palette.black.main, 0.1)
                : appTheme.palette.white.main,
        }),
    };
    _.assign(customStyles, props.customStyle ?? {});

    const onEditClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (props.onEditClick) {
            props.onEditClick();
        }
    };

    const multiValueOption = (p) => {
        const tooltipTitle = p.selectProps.getOptionLabel(p.data) ?? "";

        return (
            <Tooltip title={tooltipTitle}>
                <div>
                    <components.MultiValueLabel {...p} />
                </div>
            </Tooltip>
        );
    };

    const singleValueOption = (p) => {
        if (props.singleValueComponent) {
            return props.singleValueComponent(p) as ReactElement;
        }

        const tooltipTitle = p.selectProps.getOptionLabel(p.data) ?? "";

        return (
            <Tooltip title={tooltipTitle}>
                <div>
                    <components.SingleValue {...p} />
                </div>
            </Tooltip>
        );
    };

    const Option = (p) => {
        if (props.optionComponent) {
            return props.optionComponent(p) as ReactElement;
        }

        return (
            <div className={shouldCollapse ? classes.collapsed : ""}>
                <Tooltip title="">
                    <div>
                        <components.Option {...p} />
                    </div>
                </Tooltip>
            </div>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => {
        if (props.editIcon) {
            return (
                <div
                    onMouseDown={onEditClick}
                    className={classes.editOver}
                    style={{ padding: "6px" }}
                >
                    <EditIcon viewBox={"0 0 20 20"} style={{ fontSize: 14 }} />
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    const onHeaderClick = (id) => {
        const node =
            document.getElementById(id)?.parentElement?.nextElementSibling;
        if (node) {
            for (let i = 0; i < node.children.length; i++) {
                const element = node.children[i];
                const cls = element.classList;
                if (cls.contains(classes.collapsed)) {
                    element.classList.remove(classes.collapsed);
                } else {
                    element.classList.add(classes.collapsed);
                }
            }
        }
    };

    const GroupHeading = (p) => {
        return (
            <div
                className={clsx([classes.groupStyles, `grp-${thisId}`])}
                onClick={() => onHeaderClick(p.id)}
            >
                <components.GroupHeading {...p} />
            </div>
        );
    };

    const DropDownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <DownChevronSvg />
            </components.DropdownIndicator>
        );
    };

    function getCustomComponents() {
        return {
            IndicatorSeparator: IndicatorSeparator,
            GroupHeading: GroupHeading,
            Option: Option,
            MultiValueLabel: multiValueOption,
            SingleValue: singleValueOption,
            DropdownIndicator: DropDownIndicator,
        };
    }

    const getOptionLabel = (option) => {
        if (isFunction(props.labelField)) {
            return props.labelField(option);
        } else {
            return option[props.labelField];
        }
    };

    React.useEffect(() => {
        setThisId(uuidv4());
    }, []);

    React.useEffect(() => {
        setCurrentValue(props.defaultValue);
    }, [props.defaultValue]);

    return (
        <>
            {props.inputLabel && (
                <AcxInputLabel
                    shrink={props.shrink}
                    focused={labelFocus}
                    id={props.id + "-label"}
                    htmlFor={props.id}
                    className={clsx({
                        [classes.required]: props.required && !currentValue,
                        [classes.textLabel]: true,
                    })}
                    infoText={props.infoText}
                >
                    {props.inputLabel}
                </AcxInputLabel>
            )}
            {props.onCreate && (
                <AsyncCreatableSelect
                    allowCreateWhileLoading={false}
                    onCreateOption={props.onCreate}
                    defaultOptions={props.defaultOptions}
                    loadOptions={loadOptionsDebounced}
                    cacheOptions={props.cacheOptions}
                    isLoading={props.isLoading}
                    isClearable={props.isClearable}
                    maxMenuHeight={props.maxMenuHeightPx}
                    width={"100%"}
                    aria-labelledby={props.id + "-label"}
                    onChange={onChange}
                    onBlur={() => setLabelFocus(false)}
                    onFocus={() => setLabelFocus(true)}
                    placeholder={props.placeholder ?? "Search..."}
                    defaultValue={currentValue}
                    value={currentValue}
                    styles={{ ...customStyles }}
                    menuShouldScrollIntoView
                    // isMulti={props.isMulti}
                    menuPortalTarget={
                        props.menuPortalTarget === undefined
                            ? document.body
                            : props.menuPortalTarget
                    } // null is significant
                    menuPlacement={props.menuPlacement ?? "auto"}
                    inputId={props.id}
                    className={classes.field}
                    getOptionValue={(option) => option[props.valueField!]}
                    getOptionLabel={getOptionLabel}
                    components={getCustomComponents()}
                    isDisabled={props.isDisabled}
                    noOptionsMessage={(inputval) => {
                        return props.noOptionMessage ?? "No options";
                    }}
                    filterOption={props.customFilter ?? onFilter ?? undefined}
                    // theme={(theme) => ({
                    //     ...theme,
                    //     borderRadius: appTheme.shape.borderRadius,
                    //     spacing: {
                    //         baseUnit: 3,
                    //         controlHeight: 32,
                    //         menuGutter: appTheme.spacing(1),
                    //     },
                    //     colors: {
                    //         ...theme.colors,
                    //         primary25: hexToRGB(
                    //             appTheme.palette.secondary.main,
                    //             0.25,
                    //         ),
                    //         primary: appTheme.palette.secondary.main,
                    //     },
                    // })}
                />
            )}
            {!props.onCreate && (
                <AsyncSelect
                    allowCreateWhileLoading={false}
                    defaultOptions={props.defaultOptions}
                    loadOptions={loadOptionsDebounced}
                    cacheOptions={props.cacheOptions}
                    isLoading={props.isLoading}
                    isClearable={props.isClearable}
                    maxMenuHeight={props.maxMenuHeightPx}
                    width={"100%"}
                    aria-labelledby={props.id + "-label"}
                    onChange={onChange}
                    onBlur={() => setLabelFocus(false)}
                    onFocus={() => setLabelFocus(true)}
                    placeholder={props.placeholder ?? "Search..."}
                    defaultValue={currentValue}
                    value={currentValue}
                    styles={{ ...customStyles }}
                    menuShouldScrollIntoView
                    menuPortalTarget={
                        props.menuPortalTarget === undefined
                            ? document.body
                            : props.menuPortalTarget
                    } // null is significant
                    menuPlacement={props.menuPlacement ?? "auto"}
                    inputId={props.id}
                    className={classes.field}
                    getOptionValue={(option) => option[props.valueField!]}
                    getOptionLabel={getOptionLabel}
                    components={getCustomComponents()}
                    isDisabled={props.isDisabled}
                    filterOption={props.customFilter ?? onFilter ?? undefined}
                    noOptionsMessage={(inputval) => {
                        return props.noOptionMessage ?? "No options";
                    }}
                    // theme={(theme) => ({
                    //     ...theme,
                    //     borderRadius: appTheme.shape.borderRadius,
                    //     spacing: {
                    //         baseUnit: 3,
                    //         controlHeight: 32,
                    //         menuGutter: appTheme.spacing(1),
                    //     },
                    //     colors: {
                    //         ...theme.colors,
                    //         primary25: hexToRGB(
                    //             appTheme.palette.secondary.main,
                    //             0.25,
                    //         ),
                    //         primary: appTheme.palette.secondary.main,
                    //     },
                    // })}
                />
            )}

            {props.helperText !== undefined && (
                <AcxInputLabel
                    id={"AcxMainText-BottomLabel"}
                    className={clsx({
                        [classes.helperText]: true,
                        [classes.helperTextError]: props.error,
                    })}
                    showAllErrors={!!props.showAllErrors}
                >
                    {props.helperText}
                </AcxInputLabel>
            )}
        </>
    );
}
