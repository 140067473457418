import { ThreadActionsMap } from "components/Agent/Models/ThreadAction";
import BaseService from "./BaseService";
import {ServerAction, ThreadServerSuggestion} from "components/Agent/Models/ThreadSuggestion";
import { MessageAuthor } from "components/Agent/Models/MessageAuthor";
import { ApplicationFilters } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { FeedbackType } from "components/Signals/Store/AISummariesStore";

type NewThreadPayload = {
    id: string;
};

export interface StreamingTokenResult {
    text: string;
    actions?: ThreadActionsMap;
    suggestions?: ThreadServerSuggestion[];
}

interface GenerateCoachingNotesSuccessResponse {
    successful: true;
    doesWellNotes: string;
    improvementNotes: string;
}

interface GenerateCoachingNotesFailureResponse {
    successful: false;
    message: string;
}

type GenerateCoachingNotesResponse =
    | GenerateCoachingNotesSuccessResponse
    | GenerateCoachingNotesFailureResponse;

export class AcxAgentService extends BaseService {
    protected urlBase = "api/aiagent";

    public async streamingStartChatThread(
        abortSignal?: AbortSignal,
    ): Promise<NewThreadPayload> {
        const urlParams = new URLSearchParams();
        const apiPath = "chat/start-stream";

        const response = await this.post<NewThreadPayload>(
            "",
            urlParams,
            apiPath,
            undefined,
            undefined,
            abortSignal,
        );

        return response;
    }

    public async persistMessage(
        threadId: string,
        message: {
            author: MessageAuthor;
            content: string;
            additionalInformation?: Record<string, unknown>;
        },
    ) {
        const urlParams = new URLSearchParams();
        const apiPath = `chat/${threadId}/add`;

        return this.post(JSON.stringify(message), urlParams, apiPath);
    }

    public async submitMessage(request: { threadId: string; body: string }) {
        const urlParams = new URLSearchParams();
        const apiPath = `chat/${request.threadId}/ask`;

        const response = await this.post<StreamingTokenResult>(
            JSON.stringify({ input: request.body }),
            urlParams,
            apiPath,
        );

        return response;
    }

    private async *createStreamedResponseAsyncGenerator(response: Response) {
        const reader = response.body?.getReader();
        if (!reader) {
            return console.error("No response reader.");
        }

        const decoder = new TextDecoder();

        async function readCurrentValue(): Promise<
            StreamingTokenResult | undefined
        > {
            let currentRawValue: string = "";
            try {
                if (!reader) return;
                const { done, value } = await reader.read();
                if (done) return;

                const rawValue = decoder.decode(value);
                currentRawValue = rawValue;

                return JSON.parse(currentRawValue);
            } catch (error) {
                if (!currentRawValue) return;
                // we likely received more than one json chunk in this message,
                // we will attempt to recover here
                const jsonParts = currentRawValue.match(/{[^}]*}/g);
                if (!jsonParts) return;

                const result: StreamingTokenResult = { text: "" };
                try {
                    for (const json of jsonParts) {
                        const chunk = JSON.parse(json) as StreamingTokenResult;
                        result.text += chunk.text;
                    }
                } finally {
                    return result;
                }
            }
        }

        while (true) {
            const value = await readCurrentValue();
            if (!value) break;
            yield value;
        }

        reader.releaseLock();
    }

    public async *streamingSubmitMessage(
        request: {
            threadId: string;
            body: string;
            availableActions: { suggestionText: string, llmInput?: string, serverAction?: ServerAction }[] | undefined,
            serverAction?: ServerAction
        },
        additionalInformation?: Record<string, unknown>,
        abortSignal?: AbortSignal,
    ): AsyncIterable<StreamingTokenResult> {
        const urlParams = new URLSearchParams();
        const apiPath = `chat/${request.threadId}/ask-stream`;

        const response: Response = await this.post(
            JSON.stringify({
                input: request.body,
                additionalInformation,
                availableChoices: request.availableActions,
                serverAction: request.serverAction,
                removeStreamDelay: (window as any).__ava_removeStreamDelay
            }),
            urlParams,
            apiPath,
            undefined,
            undefined,
            abortSignal,
            true,
        );

        for await (const result of this.createStreamedResponseAsyncGenerator(
            response,
        )) {
            yield result;
        }
    }

    public async generateCoachingNotes(
        agentId: string,
        evaluationId: string,
        abortSignal?: AbortSignal,
    ) {
        const apiPath = `generate-coaching-notes`;
        const result = await this.post<GenerateCoachingNotesResponse>(
            JSON.stringify({ agentId, evaluationId }),
            new URLSearchParams(),
            apiPath,
            undefined,
            undefined,
            abortSignal,
        );
        return result;
    }

    public async *analyzeConversations(
        threadId: string,
        filters: ApplicationFilters,
        abortSignal?: AbortSignal,
    ): AsyncIterable<StreamingTokenResult> {
        const apiPath = `chat/${threadId}/analyze-conversations`;
        const response = await this.post<Response>(
            JSON.stringify({ filters }),
            new URLSearchParams(),
            apiPath,
            undefined,
            undefined,
            abortSignal,
            true,
        );

        for await (const result of this.createStreamedResponseAsyncGenerator(
            response,
        )) {
            yield result;
        }
    }

    public async downloadChats(startDate?: string, endDate?: string) {
        const apiPath = `chat/download`;

        const response = await this.post<Response>(
            JSON.stringify({ startDate, endDate }),
            new URLSearchParams(),
            apiPath,
            undefined,
            undefined,
            undefined,
            true,
        );

        return response;
    }

    /**
     * Sets the feedback on the most recent agent sent message
     * @param threadId
     */
    public async provideFeedback(
        threadId: string,
        feedbackType: FeedbackType | null,
        feedback: string | null,
    ): Promise<void> {
        const apiPath = `chat/${threadId}/feedback`;

        return this.post<void>(
            JSON.stringify({ feedbackType, feedback }),
            new URLSearchParams(),
            apiPath,
        );
    }
}
