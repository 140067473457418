import { ClickAwayListener, Paper, Popover } from "@mui/material";
import { useTheme } from "@mui/styles";
import { observer } from "mobx-react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import AcxDataGridStore from "../AcxDataGridStore";
import HideColumnPanel from "./HideColumnPanel";

interface IHideColumn {
    store: AcxDataGridStore;
    id?: string;
}

const HideColumn = observer((props: IHideColumn) => {
    const theme = useTheme();

    const handleClickAway = React.useCallback(() => {
        props.store.hideColumnsIsOpen = false;
    }, [props.store]);

    const anchorEl = props.store.gridApi?.current.rootElementRef?.current;

    return (
        <Popover
            id={`data-grid-hide-column-menu-${props.id ?? uuidv4()}`}
            anchorEl={anchorEl}
            open={props.store.hideColumnsIsOpen}
            style={{ zIndex: 9999 }}
        >
            <ClickAwayListener onClickAway={handleClickAway}>
                <Paper
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        padding: "8px",
                        borderRadius: "8px",
                    }}
                    elevation={8}
                >
                    <HideColumnPanel store={props.store} />
                </Paper>
            </ClickAwayListener>
        </Popover>
    );
});

export default HideColumn;
